// @ts-nocheck

import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import type { Feed, Action } from '../opoint/flow'

export const initialState = {
  list: [],
  activeFeed: null,
  activeFeedInitialOptions: null,
  activeFeedInitialContent: null,
  deleteInProgress: false,
}

const feedsReducer = (state: any = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.FEEDS_FETCH_SUCCESS:
      return R.assoc('list', payload, state)

    case Actions.FEED_SET_ACTIVE_SUCCESS: {
      const { feed, searchLine }: { feed: Feed; searchLine: Array<number> } = payload

      return R.compose(
        R.assoc('activeFeed', R.compose(R.assoc('searchterm', ''), R.merge({ mifluzIdLists: searchLine }))(feed)),
        R.assoc('activeFeedInitialOptions', feed?.feedSettings),
        R.assoc('activeFeedInitialContent', feed?.mifluzIdLists),
      )(state)
    }

    case Actions.FEED_REMOVE_ACTIVE:
      return R.assoc('activeFeed', null, state)

    case Actions.FEED_SEARCHTERM_CHANGED: {
      const searchterm = payload

      return R.assocPath(['activeFeed', 'searchterm'], searchterm)(state)
    }

    case Actions.FEED_ADD_FILTER:
      return R.evolve({
        activeFeed: {
          mifluzIdLists: R.append(payload.id),
          searchterm: R.always(''),
        },
      })(state)

    case Actions.FEED_TOGGLE_FILTER: {
      const toggleOff = R.contains(payload.id, R.path(['activeFeed', 'mifluzIdLists'], state))

      return R.evolve({
        activeFeed: {
          mifluzIdLists: toggleOff ? R.without([payload.id]) : R.append(payload.id),
          searchterm: toggleOff ? /* eslint-disable-next-line no-underscore-dangle */ R.identity(R.__) : R.always(''),
        },
      })(state)
    }

    case Actions.FEED_REMOVE_FILTER: {
      const feed = payload

      return R.evolve({
        activeFeed: { mifluzIdLists: R.without([feed.id]) },
      })(state)
    }

    case Actions.FEED_CLEAR_ALL:
      return R.evolve({
        activeFeed: {
          mifluzIdLists: R.always([]),
          searchterm: R.always(''),
        },
      })(state)

    case Actions.FEED_SAVE_SUCCESS:
      return R.evolve({
        list: R.reduce((acc, feed) => {
          if (R.equals(feed.id, payload.id)) {
            return R.append(payload, acc)
          }
          return R.append(feed, acc)
        }, []),
        /* eslint-disable-next-line no-underscore-dangle */
        activeFeed: R.merge(R.__, payload),
        activeFeedInitialOptions: R.always(payload.feedSettings),
        activeFeedInitialContent: R.always(payload.mifluzIdLists),
      })(state)

    case Actions.FEED_TOGGLE_SETTING /* eslint-disable-line no-case-declarations */:
      const { setting } = payload
      const pathToSetting = ['activeFeed', 'feedSettings', setting]
      return R.assocPath(pathToSetting, !R.path(pathToSetting)(state))(state)

    case Actions.FEED_NEW_SUCCESS:
      return R.evolve({
        list: R.append(payload),
        activeFeed: R.always(payload),
      })(state)

    case Actions.FEED_DELETE_SUCCESS:
      return R.evolve({
        list: R.reject(R.propEq('id', payload)),
        activeFeed: R.always(null),
        deleteInProgress: R.always(false),
      })(state)

    case Actions.FEED_DELETE_ERROR:
      return R.assoc('deleteInProgress', false, state)

    case Actions.FEED_DELETE:
      return R.assoc('deleteInProgress', true, state)

    case Actions.FEED_DELETE_CANCEL:
      return R.assoc('deleteInProgress', false, state)

    case Actions.FEED_EDITOR_CANCEL_CHANGES:
      return R.evolve({
        activeFeed: {
          mifluzIdLists: R.always(state.activeFeedInitialContent),
          searchterm: R.always(''),
          feedSettings: R.always(state.activeFeedInitialOptions),
        },
      })(state)

    default:
      return state
  }
}

export default feedsReducer
