import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const articlesErrorTypes: ErrorEpic = {
  [ActionTypes.EDIT_ARTICLE_SUCCESS]: {
    isSuccess: true,
    message: t('Article was successfully updated'),
    toastId: 'EDIT_ARTICLE_SUCCESS',
  },
  [ActionTypes.SHARE_ARTICLES_SUCCESS]: {
    isSuccess: true,
    message: t('Article was successfully shared'),
    toastId: 'SHARE_ARTICLES_SUCCESS',
  },
  [ActionTypes.EDIT_ARTICLE_FAILURE]: {
    isSuccess: false,
    message: t("Article couldn't be updated, please contact support"),
    sentryMessage: "Article couldn't be updated, please contact support",
    toastId: 'EDIT_ARTICLE_FAILURE',
  },
  [ActionTypes.EDIT_ECB_ARTICLE_FAILURE]: {
    isSuccess: false,
    message: t("Article couldn't be updated, please contact support"),
    sentryMessage: "Article couldn't be updated, please contact support",
    toastId: 'EDIT_ECB_ARTICLE_FAILURE',
  },
  [ActionTypes.ADD_ARTICLE_SUGGESTIONS_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to get suggestions'),
    sentryMessage: 'We were unable to get suggestions',
    toastId: 'ADD_ARTICLE_SUGGESTIONS_FAILURE',
  },
  [ActionTypes.ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to get suggestions'),
    sentryMessage: 'We were unable to get suggestions',
    toastId: 'ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE',
  },
  [ActionTypes.ADD_ARTICLE_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to add this article'),
    sentryMessage: 'We were unable to add this article',
    toastId: 'ADD_ARTICLE_FAILURE',
  },
  [ActionTypes.ADD_ARTICLE_FILE_UPLOAD_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to upload this file'),
    sentryMessage: 'We were unable to upload this file',
    toastId: 'ADD_ARTICLE_FILE_UPLOAD_FAILURE',
  },
  [ActionTypes.SHARE_ARTICLES_FAILURE]: {
    isSuccess: false,
    message: t('Error while sharing an article'),
    sentryMessage: 'Error while sharing an article',
    toastId: 'SHARE_ARTICLES_FAILURE',
  },
  [ActionTypes.ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR]: {
    isSuccess: false,
    message: t("Files this kind of type can't be uploaded"),
    sentryMessage: "Files this kind of type can't be uploaded",
    toastId: 'ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR',
  },
  [ActionTypes.REPORT_ISSUE_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to report your issue'),
    sentryMessage: 'We were unable to report your issue',
    toastId: 'REPORT_ISSUE_FAILURE',
  },
  [ActionTypes.REQUEST_ACTION_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to send your request'),
    sentryMessage: 'We were unable to send your request',
    toastId: 'REQUEST_ACTION_FAILURE',
  },
}

const articlesErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(articlesErrorTypes, type))

export default articlesErrorEpic
