import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'

export const REPORTSHISTORY_PAGE_LENGTH = 50

export async function getReportsHistory(id: number) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/reports/?page=${id}`),
    method: 'GET',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
