import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { isCustomerViewOpen } from '../helpers/common'
import { clearScope, setupScope } from '../helpers/sentry'
import {
  authenticate,
  authenticateByIp,
  impersonate,
  getUserInfo,
  setToken,
  setImpersonatorToken,
  removeToken,
} from '../opoint/auth/index'
import config from '../opoint/common/config'
import { isProfileSearch } from '../opoint/search'
import { getIsImpersonating } from '../selectors/impersonationSelectors'
import { getMainSearchLine } from '../selectors/searchSelectors'
import { getDefaultHome } from '../selectors/settingsSelectors'
import { history } from '../store'

import { pushLocation } from './epicsHelper'

export const checkIfLoggedInOnBootstrap = (action$: any) =>
  action$.ofType(ActionTypes.BOOTSTRAP).switchMap(() => {
    if (config.auth.state) {
      const userInfo = getUserInfo()
      const { email, user_id: id, username } = userInfo

      setupScope({ email, id: id.toString(), username })

      return Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, userInfo))
    }

    return Rx.Observable.of()
  })

export const redirectToDefaultPageAfterLoginEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.GO_TO_DEFAULT_PROFILE),
  ).switchMap(() => {
    const state = getState()
    const defaultHome = getDefaultHome(state)
    const searchline = getMainSearchLine(state)
    // @ts-ignore
    const chosenProfile = isProfileSearch([{ searchline, linemode: 'R' }])

    if (defaultHome && defaultHome.type === 'search') {
      return pushLocation('/search/')
    }

    const profileType = chosenProfile ? 'profile' : defaultHome.type
    const profileId = chosenProfile ? chosenProfile : defaultHome.id

    if (profileType && profileId) {
      return pushLocation(`/search/?filters=${profileType}:${profileId}`)
    }

    return Rx.Observable.of(buildAction(ActionTypes.NO_DEFAULT_HOME_PAGE))
  })

export const redirectToDefaultMobilePageAfterLoginEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.GO_TO_DEFAULT_PROFILE_MOBILE),
  ).switchMap(() => {
    const routeToArticleView =
      history.location.pathname.includes('/article/') || history.location.pathname.includes('/article')

    if (history.location.search.includes('?expression=article:')) {
      const articleID = history.location.search.split(':')[1]
      const [id_site, id_article] = articleID.split('-')

      let finalArticleID = `?id=${id_site}_${id_article.split('&')[0]}`

      if (history.location.search.includes('&filters=')) {
        finalArticleID = `${finalArticleID}&filters=${history.location.search.split('&filters=')[1]}`
      }

      return pushLocation('/mobile/article/' + finalArticleID)
    }

    if (routeToArticleView) {
      return pushLocation('/mobile/article/' + history.location.search)
    }
    const defaultHome = getDefaultHome(getState())
    if (defaultHome && defaultHome.type === 'search') {
      return pushLocation('/mobile/list/?search=') // Add some querystring param to avoid constant redirects
    }
    if (defaultHome && defaultHome.type && defaultHome.id) {
      return pushLocation(`/mobile/list/?filters=${defaultHome.type}:${defaultHome.id}`)
    }

    return Rx.Observable.of(buildAction(ActionTypes.NO_DEFAULT_HOME_PAGE))
  })

export const redirectToFirstProfileIfNoDefaultHomePage = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.NO_DEFAULT_HOME_PAGE),
  )
    .take(1)
    // @ts-ignore
    .switchMap(([{ payload: profiles }]) => {
      if (profiles && profiles.length) {
        const firstProfile = profiles[0]

        return pushLocation(`/search/?filters=profile:${firstProfile.id}`)
      }

      return pushLocation(`/search`)
    })
// TODO - add take until sth like DEFAULT_HOME_PAGE_FOUND

export const redirectAfterImpersonationEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS),
    action$.ofType(ActionTypes.IMPERSONATE_SUCCESS).take(1),
  ).switchMap(() => {
    const isImpersonating = getIsImpersonating(getState())
    if (isImpersonating) {
      if (!isCustomerViewOpen()) {
        return Rx.Observable.of(buildAction(ActionTypes.GO_TO_DEFAULT_PROFILE))
      }
    }

    return Rx.Observable.of(buildAction(ActionTypes.GO_TO_CUSTOMER_VIEW)).delay(1000)
  })

export const goBackToCustomerViewAfterImpersonationEpic = (action$: any) =>
  Rx.Observable.combineLatest(action$.ofType(ActionTypes.END_IMPERSONATION)).switchMap(() => {
    return Rx.Observable.of(buildAction(ActionTypes.GO_TO_CUSTOMER_VIEW))
  })

export const loginEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN).switchMap(({ payload: { username, password, rememberMe } }) =>
    Rx.Observable.fromPromise(authenticate(username, password, rememberMe))
      //@ts-ignore
      .switchMap(({ token }) => {
        setToken(token)
        const userInfo = getUserInfo()
        const { email, user_id: id, username } = userInfo

        setupScope({ email, id: id.toString(), username })

        return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, userInfo)))
      })
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOG_IN_ERROR))),
  )

export const signInEpic = (action$: any) =>
  action$.ofType(ActionTypes.SIGN_IN).switchMap(({ payload: { user, token } }) => {
    setToken(token)

    return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, user)))
  })

export const loginByIpEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_BY_IP).switchMap(({ payload: { username } }) =>
    Rx.Observable.fromPromise(authenticateByIp(username))
      // @ts-ignore
      .switchMap(({ token }) => {
        setToken(token)
        const userInfo = getUserInfo()
        const { email, user_id: id, username } = userInfo

        setupScope({ email, id: id.toString(), username })

        return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, userInfo)))
      })
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOG_IN_ERROR))),
  )

export const impersonateEpic = (action$: any) =>
  action$.ofType(ActionTypes.IMPERSONATE).switchMap(({ payload: { userId, privilege_index } }) =>
    Rx.Observable.fromPromise(impersonate(userId, privilege_index))
      .switchMap((token) => {
        setImpersonatorToken(token)
        setToken(token)
        const userInfo = getUserInfo()

        return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.IMPERSONATE_SUCCESS, userInfo)))
      })
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.IMPERSONATE_ERROR))),
  )

export const endImpersonationEpic = (action$: any) =>
  action$.ofType(ActionTypes.END_IMPERSONATION).switchMap(() =>
    //@ts-ignore
    Rx.Observable.fromPromise(impersonate()).switchMap((token) => {
      setToken(token)
      const userInfo = getUserInfo()

      return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, userInfo)))
    }),
  )

export const setIsImpersonation = (action$: any) =>
  action$
    .ofType(
      ActionTypes.USERS_LOGIN_INFO_SUCCESS,
      ActionTypes.END_IMPERSONATION,
      ActionTypes.LOG_IN_SUCCESS,
      ActionTypes.IMPERSONATE_SUCCESS,
    )
    .switchMap(() => {
      const impersonating = !!config.auth.impersonating

      return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.IS_IMPERSONATING, impersonating)))
    })

export const clearLoginError = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_ERROR).delay(5000)?.mapTo(buildAction(ActionTypes.LOG_IN_ERROR_TIMEOUT))

export const logoutEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOGOUT).switchMap(async () => {
    clearScope()
    removeToken()

    return pushLocation('/logged-out')
  })

export default [
  checkIfLoggedInOnBootstrap,
  clearLoginError,
  endImpersonationEpic,
  goBackToCustomerViewAfterImpersonationEpic,
  impersonateEpic,
  loginEpic,
  signInEpic,
  loginByIpEpic,
  logoutEpic,
  redirectToDefaultPageAfterLoginEpic,
  redirectToDefaultMobilePageAfterLoginEpic,
  redirectToFirstProfileIfNoDefaultHomePage,
  redirectAfterImpersonationEpic,
  setIsImpersonation,
]
