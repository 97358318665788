import assoc from 'ramda/src/assoc'

import * as Actions from '../constants/actionTypes'
import type { ImpersonationTarget } from '../new-components/types/user'
import type { Action } from '../opoint/flow'

type ImpersonationState = {
  organizations: ImpersonationTarget[]
  users: ImpersonationTarget[]
  userId: number
  userSearch: string
  isImpersonating: boolean
  forUsersList: ImpersonationTarget[]
  lastId: number
  initialLoading: boolean
  loading: boolean
}

export const initialState: ImpersonationState = {
  organizations: [],
  users: [],
  userId: null,
  userSearch: '',
  isImpersonating: false,
  forUsersList: [],
  lastId: null,
  initialLoading: true,
  loading: false,
}

const impersonationReducer = (state: ImpersonationState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.IMPERSONATION_ORGANIZATIONS_FETCH_SUCCESS: {
      return assoc('organizations', payload, state)
    }

    case Actions.IMPERSONATION_USERS_FETCH: {
      return {
        ...state,
        ...payload,
        loading: true,
      }
    }

    case Actions.IMPERSONATION_USERS_FETCH_SUCCESS: {
      return {
        ...state,
        users: payload,
        loading: false,
      }
    }

    case Actions.IMPERSONATE: {
      return assoc('userId', payload, state)
    }

    case Actions.IS_IMPERSONATING: {
      return assoc('isImpersonating', payload, state)
    }

    case Actions.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        forUsersList: payload,
        initialLoading: false,
      }
    }

    default:
      return state
  }
}

export default impersonationReducer
