import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import type { Alert } from '../opoint/flow'

type AlertsState = {
  list: Array<Alert>
}

export const initialState: AlertsState = {
  list: [],
}

const analyticsReducer = (state: AlertsState = initialState, { type, payload }): AlertsState => {
  switch (type) {
    case Actions.ANALYSIS_TAGS_FETCH_SUCCESS: {
      const analyticsList: Array<Alert> = payload

      return R.evolve({
        list: R.always(analyticsList),
      })(state)
    }

    default:
      return state
  }
}

export default analyticsReducer
