// @ts-nocheck
import dayjs from 'dayjs'
import R from 'ramda'
import { formValueSelector } from 'redux-form'
import Rx from 'rxjs'

import { timeLine } from '../components/statistics/charts/chartHelpers'
import {
  getWidgetSelection,
  setWidgetSelection,
  getSwitchableMainGraph,
} from '../components/statistics/statisticsControllers'
import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { multipleTagsHandling, arrayToCsv, downloadBlob } from '../helpers/common'
import { getExportArticleData } from '../helpers/statistics'
import { sameDateLastYear } from '../new-components/statistics/statisticsComparison/PreviousPeriodDatePicker/datehelpers'
import { widgetIds } from '../opoint/common/constants'
import type { SearchItem } from '../opoint/flow'
import {
  defaultStatParams,
  parseTimeFilterToTimeStamps,
  searchStatistics,
  search as searchArticles,
  searchDataToExpression,
} from '../opoint/search'
import { SPECIAL_ASPECTS_IDS, getSelectedTagLikeEntities } from '../opoint/statistics/aspects'
import {
  deleteStatisticsView,
  getStatisticViewData,
  getStatisticViews,
  reduceSumName,
  saveStatisticView,
  exportPDF,
  exportPPTX,
  exportXLSX,
  exportXLS,
} from '../opoint/statistics/index'
import { getAnalyticsTags } from '../selectors/analytics'
import { getProfiles } from '../selectors/profilesSelectors'
import { getMainSearchLine, getSearchTimePeriod, isProfileSelected, isTagSelected } from '../selectors/searchSelectors'
import { getOpointLocale } from '../selectors/settingsSelectors'
import {
  getActiveViewId,
  getAspectById,
  getAspectCombo,
  getBaskets as getAspectsBaskets,
  getChangedAspectsCountBy,
  getChangedAspectsType,
  getComputedAspectGroup,
  getCountBy,
  getEditName,
  getSelectedAspects,
  getSubQueries,
  getAspectsRequested,
  getActiveStatView,
  getComparisonModeState,
  getPreviousPeriodDates,
  getComparePeriod,
  getCorrectStatisticsDates,
  getStatisticsViewArticles,
  getAspects,
  getStatDocuments,
  getStatFilters,
} from '../selectors/statisticsSelectors'
import { getBaskets, getTags } from '../selectors/tagsSelectors'

import { logOutOnExpiredToken, serverIsDown, pushLocation } from './epicsHelper'

const article_export_request_parameters = {
  main: {
    header: 1,
    summary: 1,
    text: 1,
    matches: false,
    othersources: true,
    all: true,
  },
  readership: true,
  allmeta: true,
  include_equalgroup_id: 1,
}

const TIMED_REQUESTED_COUNT = 60000
const dayInSeconds = 86400
const DEFAULT_PERIOD = dayInSeconds * 1

const statisticsSearchEpic: (action$, { getState }) => void = (action$, { getState }) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FETCH_STATISTICS),
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
  )
    .debounceTime(1000) // Only allowed to be triggered once withing this second.
    .delay(50)
    .switchMap(([{ payload }]) => {
      const { params: customParams, preserveAspects, subqueries } = payload || {}
      const state = getState()
      let searchline = getMainSearchLine(state)
      const compareMode = getComparisonModeState(state)

      if (searchline.filters.length === 0 && searchline.searchterm === '') {
        return Rx.Observable.empty()
      }

      // --- Multiple Tags Handling ---
      if (searchline.filters.filter((filter) => filter.type === 'tag').length > 1) {
        searchline = multipleTagsHandling(searchline)
      }
      // --- Multiple Tags Handling ---

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }
      // search params
      // TODO: move to separate function for easy reuse (same as in searchEpics)
      let baskets = { baskets: getBaskets(state) }
      const timeFilter = getSearchTimePeriod(state)
      const counts = {}
      const aspectsRequested = {}
      let timePeriod = {}

      if (timeFilter) {
        // if time filter is specified, request more articles than usual 500
        counts.requestedarticles = TIMED_REQUESTED_COUNT
        timePeriod = parseTimeFilterToTimeStamps(timeFilter.id)
      } else if (!timeFilter && compareMode) {
        counts.requestedarticles = TIMED_REQUESTED_COUNT
        timePeriod.newest = dayjs().unix()
        timePeriod.oldest = dayjs().unix() - DEFAULT_PERIOD
      }

      const aspectsRequestedFromState = getAspectsRequested(state)

      // preserve previously selected aspects if any
      if (!R.isEmpty(aspectsRequestedFromState)) {
        aspectsRequested.aspect_requested = aspectsRequestedFromState
      } else {
        aspectsRequested.aspect_requested = []
      }

      let subqueriesFinal = []
      if (aspectsRequested.aspect_requested.includes(SPECIAL_ASPECTS_IDS.PROFILE)) {
        subqueriesFinal = state.search.profileTagIds?.map((id) => ({ id }))
      }

      subqueriesFinal = subqueries || subqueriesFinal

      let newComputeGroups
      if (subqueriesFinal.length === 0) {
        const widgetSelection = getWidgetSelection() || '{}'
        const { selectedAspectIds, computeGroups, baskets: tagBaskets } = JSON.parse(widgetSelection)

        newComputeGroups = computeGroups

        if (!!tagBaskets) {
          baskets = { baskets: tagBaskets }
        }

        if (selectedAspectIds && selectedAspectIds.length > aspectsRequestedFromState.length) {
          aspectsRequested.aspect_requested = selectedAspectIds
        }
      }

      aspectsRequested.aspect_requested = R.uniq(aspectsRequested.aspect_requested)

      const normalizedCustomParams = R.when(R.propEq('baskets', ''), R.dissoc('baskets'))(customParams || {})

      const finalSearchParams = {
        aspect_info_limit: 1,
        aspect_sep_limit: 0,
        compute_aspects: newComputeGroups
          ? newComputeGroups
          : aspectsRequestedFromState.length === 0
          ? 1
          : getComputedAspectGroup(state),
        subqueries: subqueriesFinal,
        ...aspectsRequested,
        ...timePeriod,
        ...counts,
        ...baskets,
        ...normalizedCustomParams,
      }

      const search$ = Rx.Observable.fromPromise(
        searchStatistics([requiredSearchItem], finalSearchParams, {}, getOpointLocale(state)),
      )
        ?.map((response) => {
          if (response?.searchresult?.errors === 'Solr could not handle the query') {
            return buildAction(ActionTypes.FETCH_STATISTICS_FAILURE, response)
          } else {
            return buildAction(ActionTypes.FETCH_STATISTICS_SUCCESS, {
              response,
              preserveAspects,
              subqueries,
              compareMode,
              aspectsRequestedFromState,
              timePeriod,
              regularStatsFetch: true,
            })
          }
        })
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch((error) => Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS_FAILURE, error)))

      const searchTakingTooLong$ = Rx.Observable.of(buildAction(ActionTypes.STATISTICS_LOADING_IS_TAKING_TOO_LONG))
        .delay(5000) // After 5 seconds, show search is taking too long message
        .takeUntil(search$)
        // for filtered articles & returning to statistics
        .takeUntil(action$.ofType(ActionTypes.FETCH_STATISTICS))

      return search$.merge(searchTakingTooLong$).takeUntil(action$.ofType(ActionTypes.FETCH_STATISTICS))
    })

const compareStatisticsSearchEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.FETCH_STATISTICS, ActionTypes.FETCH_STATISTICS_COMPARE)
    .debounceTime(1000) // Only allowed to be triggered once withing this second.
    .delay(1000)
    .switchMap((payload) => {
      const { params: customParams, preserveAspects, subqueries } = payload.payload || {}
      const state = getState()
      let searchline = getMainSearchLine(state)
      const compareMode = getComparisonModeState(state)
      const compareDates = getPreviousPeriodDates(state)
      const period = getComparePeriod(state)

      if (searchline.filters.length === 0 && searchline.searchterm === '') {
        return Rx.Observable.empty()
      }

      if (!compareMode) {
        return Rx.Observable.empty()
      }

      // --- Multiple Tags Handling ---
      if (searchline.filters.filter((filter) => filter.type === 'tag').length > 1) {
        searchline = multipleTagsHandling(searchline)
      }
      // --- Multiple Tags Handling ---

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }
      // search params
      // TODO: move to separate function for easy reuse (same as in searchEpics)
      const baskets = { baskets: getBaskets(state) }
      const timeFilter = getSearchTimePeriod(state)
      const counts = {}
      const aspectsRequested = {}
      let timePeriod = {}

      const containsLetters = timeFilter?.id.match(/[a-z]/i)

      // This is for handling previous period, when timeFilter is used
      if (timeFilter) {
        const splittedTimeFilter = timeFilter.id.split('-')

        // This is for handling filter Intervals
        const parsedTimeFilter = parseTimeFilterToTimeStamps(timeFilter.id)

        const currentPeriodOldest = containsLetters ? parsedTimeFilter.oldest : parseInt(splittedTimeFilter[0])
        const currentPeriodNewest = containsLetters
          ? parsedTimeFilter.newest || dayjs().unix()
          : parseInt(splittedTimeFilter[1])

        const lastDay = currentPeriodOldest
        const firstDay = currentPeriodNewest || dayjs().unix()
        const daysDiff = firstDay - lastDay

        counts.requestedarticles = TIMED_REQUESTED_COUNT

        let secondPeriodDiff = null
        let periodsDiffs = null

        if (period === 'Custom') {
          secondPeriodDiff = +dayjs(compareDates.endDate) / 1000 - +dayjs(compareDates.startDate) / 1000
          periodsDiffs = secondPeriodDiff - daysDiff
        }

        if (period === 'Custom' && periodsDiffs > -300 && period === 'Custom' && periodsDiffs < 300) {
          timePeriod.newest = +dayjs(compareDates.endDate) / 1000
          timePeriod.oldest = +dayjs(compareDates.startDate) / 1000
          const period = `${+dayjs(compareDates.startDate) / 1000}-${+dayjs(compareDates.endDate) / 1000}`
          timePeriod = parseTimeFilterToTimeStamps(period)
        } else if (period === 'Same period last year') {
          const sameDateLastYearStart = sameDateLastYear(dayjs(lastDay * 1000).toISOString())
          const sameDateLastYearEnd = sameDateLastYear(dayjs(firstDay * 1000).toISOString())
          const period = `${+dayjs(sameDateLastYearStart) / 1000}-${+dayjs(sameDateLastYearEnd) / 1000}`
          timePeriod = parseTimeFilterToTimeStamps(period)
        } else if ((period === 'Custom' && periodsDiffs < -300) || (period === 'Custom' && periodsDiffs > 300)) {
          const period = `${+dayjs(compareDates.endDate) / 1000 - daysDiff}-${+dayjs(compareDates.endDate) / 1000}`
          timePeriod = parseTimeFilterToTimeStamps(period)
        } else if (period === 'Previous period') {
          const previousPeriod = `${currentPeriodOldest - daysDiff}-${currentPeriodOldest}`
          timePeriod = parseTimeFilterToTimeStamps(previousPeriod)
        }
      } else if (!timeFilter && compareMode) {
        counts.requestedarticles = TIMED_REQUESTED_COUNT

        if (period === 'Custom') {
          timePeriod.newest = +dayjs(compareDates.endDate) / 1000
          timePeriod.oldest = +dayjs(compareDates.startDate) / 1000
        } else if (period === 'Same period last year') {
          const oldDate = dayjs().unix() - DEFAULT_PERIOD

          const sameDateLastYearStart = sameDateLastYear(dayjs(oldDate * 1000).toISOString())
          const sameDateLastYearEnd = sameDateLastYear(dayjs().toISOString())

          const oldest = +dayjs(sameDateLastYearStart) / 1000
          const newest = +dayjs(sameDateLastYearEnd) / 1000

          timePeriod.newest = parseInt(newest.toFixed(0))
          timePeriod.oldest = parseInt(oldest.toFixed(0))
        } else {
          timePeriod.newest = dayjs().unix() - DEFAULT_PERIOD
          timePeriod.oldest = dayjs().unix() - DEFAULT_PERIOD * 2
        }
      }

      const aspectsRequestedFromState = getAspectsRequested(state)

      // preserve previously selected aspects if any
      if (!R.isEmpty(aspectsRequestedFromState)) {
        aspectsRequested.aspect_requested = aspectsRequestedFromState
      } else {
        aspectsRequested.aspect_requested = []
      }

      let subqueriesFinal = []
      if (aspectsRequested.aspect_requested.includes(SPECIAL_ASPECTS_IDS.PROFILE)) {
        subqueriesFinal = state.search.profileTagIds?.map((id) => ({ id }))
      }

      subqueriesFinal = subqueries || subqueriesFinal

      let newComputeGroups
      if (subqueriesFinal.length === 0) {
        const widgetSelection = getWidgetSelection() || '{}'
        const { selectedAspectIds, computeGroups } = JSON.parse(widgetSelection)
        newComputeGroups = computeGroups
        if (selectedAspectIds && selectedAspectIds.length > aspectsRequestedFromState.length) {
          aspectsRequested.aspect_requested = selectedAspectIds
        }
      }

      aspectsRequested.aspect_requested = R.uniq(aspectsRequested.aspect_requested)

      /* data from payload */
      const normalizedCustomParams = R.when(R.propEq('baskets', ''), R.dissoc('baskets'))(customParams || {})

      const finalSearchParams = {
        aspect_info_limit: 1,
        aspect_sep_limit: 0,
        compute_aspects: newComputeGroups
          ? newComputeGroups
          : aspectsRequestedFromState.length === 0
          ? 1
          : getComputedAspectGroup(state),
        subqueriesFinal,
        ...aspectsRequested,
        ...timePeriod,
        ...counts,
        ...baskets,
        ...normalizedCustomParams,
      }

      const search$ = Rx.Observable.concat(
        Rx.Observable.fromPromise(searchStatistics([requiredSearchItem], finalSearchParams, {}, getOpointLocale(state)))
          ?.map((response) =>
            buildAction(ActionTypes.FETCH_STATISTICS_COMPARE_SUCCESS, {
              response,
              preserveAspects,
              compareMode,
              aspectsRequestedFromState,
              timePeriod,
            }),
          )
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch((error) => Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS_FAILURE, error))),
      )

      return search$
    })

const statisticsAspectsResendEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_ASPECT_RESEND).switchMap(({ payload }) => {
    const { aspect } = payload
    if (aspect.id === widgetIds.tag) {
      const tagLikeEntities = aspect.tagLikeEntities
      const selectedBaskets = tagLikeEntities.filter(({ selected }) => selected).map(({ id }) => id)
      const uniqueBaskets = R.uniq(selectedBaskets)
      const stringifiedBaskets = uniqueBaskets.join(',')

      const widgetSelection = getWidgetSelection() || '{}'
      const parsedWidgetSelection = JSON.parse(widgetSelection)
      setWidgetSelection(
        JSON.stringify({
          ...parsedWidgetSelection,
          baskets: stringifiedBaskets,
        }),
      )
    }
    const state = getState()

    const params = {}

    const specialAspectsIds = R.values(SPECIAL_ASPECTS_IDS)

    params.aspect_combo = getAspectCombo(state)

    params.aspect_requested = R.uniq([
      // add previously requested aspects
      ...getAspectsRequested(state),
      // always add profiles, tags, analytics and sentiment
      ...specialAspectsIds,
      // add aspects requested explicitly
      aspect.id,
    ])

    // request to compute this aspect
    // binary OR to also include all preceding aspects
    /* eslint-disable-next-line no-bitwise */
    params.compute_aspects = getComputedAspectGroup(state) | aspect.group

    // add selected profiles to sub-queries
    params.subqueries = getSubQueries(state)
    // and other selected tagLikeEntities (normal, sentiments, analytics, but not profiles)
    // to baskets:
    params.baskets = getAspectsBaskets(state)
    // current aspect is not yet toggled on, so previous params does
    // not contain tagLikeEntities of current aspect, have to add it now:
    switch (aspect.id) {
      case SPECIAL_ASPECTS_IDS.PROFILE:
        params.subqueries = params.subqueries.concat(getSelectedTagLikeEntities(aspect)?.map(({ id }) => ({ id })))
        break
      case SPECIAL_ASPECTS_IDS.TAG:
      case SPECIAL_ASPECTS_IDS.SENTIMENT:
      case SPECIAL_ASPECTS_IDS.ANALYSIS:
        params.baskets = R.uniq([
          ...(params.baskets ? params.baskets.split(',') : [])?.map((id) => +id),
          ...getSelectedTagLikeEntities(aspect)?.map(({ id }) => +id),
        ]).join(',')
        break
      default:
        break
    }

    return Rx.Observable.of(
      buildAction(ActionTypes.STATISTICS_ASPECT_TOGGLE, payload),
      buildAction(ActionTypes.FETCH_STATISTICS, { params, preserveAspects: true }),
    )
  })

const statisticsAspectToggleEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_ASPECT_TRY_TOGGLE).switchMap(({ payload }) => {
    const { aspectId, selected } = payload
    const aspect = getAspectById(aspectId)(getState())

    switch (true) {
      case aspect.comp_part === -1: // need to compute aspect data
      case Object.values(aspect.aspectpart)[0] && Object.values(aspect.aspectpart)[0].names[0] === 'No profile':
        payload.aspect = aspect

        return Rx.Observable.of(buildAction(ActionTypes.STATISTICS_ASPECT_RESEND, payload))

      case aspect.selected: // aspect turning off
      case aspect.comp_part > -1: // turning on but no need to compute additional data
        return Rx.Observable.of(buildAction(ActionTypes.STATISTICS_ASPECT_TOGGLE, payload))

      case !aspect:
      case aspect.selected === selected: // no change
      default:
        // this should not ever happen
        return Rx.Observable.of()
    }
  })

/*
 * Combines multiple action to new action so tags (including special "tags"
 * like profiles analytics etc.) can all be stored to aspects in statistics
 * in one step after all of them are available
 */
const statisticsTagsEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FETCH_STATISTICS_SUCCESS).switchMap(({ payload: { subqueries, savedBaskets } }) => {
    const state = getState()

    const profiles = getProfiles(state)
    const allTags = getTags(state)
    const analysis = getAnalyticsTags(state)

    return Rx.Observable.of(
      buildAction(ActionTypes.STATISTICS_TAG_LISTS, {
        allTags: R.map((t) => R.assoc('selected', isTagSelected(t.id)(state))(t))(allTags),
        analysis: R.map((t) => R.assoc('selected', isTagSelected(t.id)(state))(t))(analysis),
        profiles: R.map((t) => R.assoc('selected', isProfileSelected(t.id)(state))(t))(profiles),
        subqueries,
        savedBaskets,
      }),
    )
  })

const fetchStatisticViewsEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS)
    .switchMap(() =>
      Rx.Observable.from(getStatisticViews())?.map((data) =>
        buildAction(ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS, data),
      ),
    )

// because sometimes we want to show active statistic view and not fetch new statistics
/* deprecated for some time @dmytro */
/* const showStatisticsEpic = (action$: any, { getState }: any) =>
    action$.ofType(ActionTypes.STATISTICS_SHOW)
      .switchMap(() => {
        const viewId = getState().statistics.activeStatView
        return viewId !== null
          ? Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEWS_OPEN, { id: viewId }))
          : Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS))
      }) */

const fetchStatViewsDetailsEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEWS_OPEN).switchMap(({ payload: { id } }) => {
    const state = getState()
    const timeFilter = getSearchTimePeriod(state)
    const compareMode = getComparisonModeState(state)

    const searchLine$ = Rx.Observable.fromPromise(getStatisticViewData(id))?.map((data) => data)

    return searchLine$
      .switchMap(({ dashboard: { search, chartCollection } }) => {
        const tagWidgetFilters = chartCollection?.find(({ id }) => id === 13)?.filters

        const baskets = {
          baskets: !!tagWidgetFilters ? tagWidgetFilters : getBaskets(state),
        }
        const searchline = {
          filters: search.filters.filter((filter) => filter.type !== 'timePeriod'),
          searchterm: search.expression,
        }
        const requiredSearchItem: SearchItem = {
          searchline,
          linemode: 'R',
        }

        const counts = {}
        let timePeriod = {}

        if (timeFilter) {
          // if time filter is specified, request more articles than usual 500
          counts.requestedarticles = TIMED_REQUESTED_COUNT
          timePeriod = parseTimeFilterToTimeStamps(timeFilter.id)
        } else if (!timeFilter && compareMode) {
          counts.requestedarticles = TIMED_REQUESTED_COUNT
          timePeriod.newest = dayjs().unix()
          timePeriod.oldest = dayjs().unix() - DEFAULT_PERIOD
        }

        const params =
          search.params.subqueries?.length > 0
            ? { ...search.params, ...baskets }
            : R.merge(search.params, {
                ...baskets,
                subqueries: R.compose(
                  R.map((filter) => ({ id: +filter.id })),
                  R.filter(R.propEq('type', 'profile')),
                )(search.filters),
              })

        const parsedFilters = search.filters
        const expression = search.expression || ''

        return Rx.Observable.fromPromise(searchStatistics([requiredSearchItem], params, {}, getOpointLocale(state)))

          .switchMap((response) => {
            let changedAspectsType
            let changedAspectsCountBy
            const correctAspectsResponse = R.evolve({
              searchresult: {
                aspectset: {
                  aspect: R.map((aspect) => {
                    let isSelected = false
                    chartCollection.forEach((chart) => {
                      if (chart.id === aspect.id) {
                        isSelected = true

                        // Prefer to use aspect name over chart name as aspect name represents
                        // the correct language
                        if (chart.type !== 'default') {
                          changedAspectsType = {
                            ...changedAspectsType,
                            [aspect.name]: chart.type,
                          }
                        }
                        if (chart.reduceSum && chart.reduceSum.key !== 'default') {
                          changedAspectsCountBy = {
                            ...changedAspectsCountBy,
                            [aspect.name]: chart.reduceSum.name,
                          }
                        } else if (chart.reduceSum && chart.reduceSum.name !== 'n. of articles') {
                          changedAspectsCountBy = {
                            ...changedAspectsCountBy,
                            [aspect.name]: chart.reduceSum.name,
                          }
                        }
                      }
                    })

                    return isSelected
                      ? {
                          ...aspect,
                          selected: true,
                        }
                      : {
                          ...aspect,
                          selected: false,
                        }
                  }),
                },
              },
            })(response)

            const mainGraphChartCollection = chartCollection.find((chart) => chart.name === timeLine)

            if (compareMode) {
              return Rx.Observable.of(
                buildAction(ActionTypes.ROUTER_SEARCH_DATA_CHANGE, { parsedFilters, expression }),
                buildAction(ActionTypes.STATISTICS_COUNT_BY_CHANGED, {
                  by: mainGraphChartCollection?.reduceSum?.name ?? 'count',
                }),
                buildAction(ActionTypes.FETCH_STATISTICS_SUCCESS, {
                  response: correctAspectsResponse,
                  isStatView: true,
                  changedAspectsType,
                  changedAspectsCountBy,
                  subqueries: search.params.subqueries,
                  compareMode,
                  timePeriod,
                  savedBaskets: tagWidgetFilters,
                }),
              )
            } else {
              return Rx.Observable.concat(
                Rx.Observable.of(
                  buildAction(ActionTypes.ROUTER_SEARCH_DATA_CHANGE, { parsedFilters, expression }),
                  buildAction(ActionTypes.STATISTICS_COUNT_BY_CHANGED, {
                    by: mainGraphChartCollection?.reduceSum?.name ?? 'count',
                  }),
                  buildAction(ActionTypes.FETCH_STATISTICS_SUCCESS, {
                    response: correctAspectsResponse,
                    isStatView: true,
                    changedAspectsType,
                    changedAspectsCountBy,
                    subqueries: search.params.subqueries,
                    compareMode,
                    timePeriod,
                    savedBaskets: tagWidgetFilters,
                  }),
                ),
              )
            }
          })
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch((error) => Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS_FAILURE, error)))
      })
      .catch((error) => Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS_FAILURE, error)))
  })

export const deleteStatViewEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEW_DELETE).switchMap(() => {
    const deletedView = getActiveViewId(getState())

    const deleteView$ = Rx.Observable.fromPromise(deleteStatisticsView(deletedView))
      ?.map(() => buildAction(ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS, { id: deletedView }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_DELETE_FAILURE)))
    const goToHomepage$ = Rx.Observable.defer(getStatisticViews).switchMap(() =>
      Rx.Observable.of(buildAction(ActionTypes.GO_TO_DEFAULT_PROFILE)),
    )

    return deleteView$.switchMap((deleteAlertAction) =>
      Rx.Observable.concat(Rx.Observable.of(deleteAlertAction), goToHomepage$),
    )
  })

export const saveStatViewEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEW_SAVE).switchMap(() => {
    const state = getState()
    const aspects = getAspects(state)
    const id = getActiveViewId(state)
    const name = getEditName(state)
    const color = 'blue'
    const selectedAspects = getSelectedAspects(state)
    const countBy = getCountBy(state)
    const changedAspectsType = getChangedAspectsType(state)
    const changedAspectsCountBy = getChangedAspectsCountBy(state)
    const reduceSumDefault = {
      key: 'default',
      name: reduceSumName[countBy],
    }
    const timeFilter = getSearchTimePeriod(state)
    const counts = {}
    let timePeriod = {}
    const subqueries = getSubQueries(state)
    const compareMode = getComparisonModeState(state)

    if (timeFilter) {
      // if time filter is specified, request more articles than usual 30
      counts.requestedarticles = TIMED_REQUESTED_COUNT
      timePeriod = parseTimeFilterToTimeStamps(timeFilter.id)
    }

    const tagWidgetAspect = aspects?.find(({ id }) => id === widgetIds.tag)
    const selectedTags = tagWidgetAspect?.tagLikeEntities?.filter(({ selected }) => selected)
    const stringifiedSelectedTags = selectedTags?.map(({ id }) => id).join(',')

    const params = {
      ...defaultStatParams,
      acceptedcacheage: -1,
      aspect_info_limit: 1,
      aspect_sep_limit: 0,
      compute_aspects: getComputedAspectGroup(state),
      aspect_requested: getAspectsRequested(state),
      baskets: getBaskets(state),
      watch_id: 0,
      subqueries,
      ...timePeriod,
      ...counts,
    }
    const searchline = getMainSearchLine(state)

    const mainGraph = {
      id: 99,
      name: timeLine,
      reduceSum: {
        key: reduceSumName[countBy],
        name: reduceSumName[countBy],
      },
      isArrayAspect: false,
      filters: null,
      type: 'default',
    }

    const correctPayload = {
      color,
      name,
      dashboard: {
        chartCollection: [
          ...R.map((aspect) => {
            const aspectData = R.pick(['id', 'name'], aspect)
            const customCountBy = changedAspectsCountBy[aspect.name]
            const filters = aspect.id === widgetIds.tag && !!stringifiedSelectedTags ? stringifiedSelectedTags : null
            const reduceSum =
              customCountBy && customCountBy !== 'count'
                ? {
                    key: reduceSumName[customCountBy],
                    name: reduceSumName[customCountBy],
                  }
                : reduceSumDefault
            const chartData = {
              reduceSum,
              ...aspectData,
              isArrayAspect: false,
              type: changedAspectsType[aspect.name] || 'default',
              filters,
            }

            return chartData
          }, selectedAspects),
          mainGraph,
        ],
        chartLanguage: getOpointLocale(state),
        search: {
          expression: searchline.searchterm,
          filters: searchline.filters,
          params,
        },
      },
    }

    const saveView$ = Rx.Observable.fromPromise(saveStatisticView(correctPayload, id))
      .switchMap(({ id }) =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_SAVE_SUCCESS)),
          pushLocation(`/statistics/${compareMode ? 'compare' : 'search'}/?filters=chart:${id}`),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_SAVE_FAILURE)))

    return saveView$.switchMap((saveViewAction) =>
      Rx.Observable.concat(
        Rx.Observable.of(saveViewAction),
        Rx.Observable.from(getStatisticViews())?.map((data) =>
          buildAction(ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS, data),
        ),
      ),
    )
  })

const exportStatisticsAsPDF = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.STATISTICS_VIEW_EXPORT_PDF)
    .switchMap(({ payload: { SVGs, metaString, type, periodChosen } }) => {
      const state = getState()

      const exportAsPdfModalFormValues = formValueSelector('statisticsExport')(
        state,
        'description',
        'title',
        'fileName',
        'placeholderText',
      )

      // If exporting statistics, without filling "fileName" or "title", their placeholder will be filled.
      if (!exportAsPdfModalFormValues.fileName) {
        exportAsPdfModalFormValues.fileName = `${exportAsPdfModalFormValues.placeholderText.fileName}.pdf`
      }
      if (!exportAsPdfModalFormValues.title) {
        exportAsPdfModalFormValues.title = exportAsPdfModalFormValues.placeholderText.title
      }

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      return Rx.Observable.fromPromise(
        exportPDF({
          activeStatisticsViewName,
          metaString,
          SVGs,
          type,
          periodChosen,
          ...exportAsPdfModalFormValues,
        }),
      )
        ?.map((activeStatisticsViewName) =>
          buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS, { activeStatisticsViewName }),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE)))
    })

const exportStatisticsAsPPTX = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.STATISTICS_VIEW_EXPORT_PPTX)
    .switchMap(({ payload: { SVGs, metaString, type, periodChosen } }) => {
      const state = getState()

      const exportAsPptxModalFormValues = formValueSelector('statisticsExport')(
        state,
        'description',
        'title',
        'fileName',
        'placeholderText',
      )

      // If exporting statistics, without filling "fileName" or "title", their placeholder will be filled.
      if (!exportAsPptxModalFormValues.fileName) {
        exportAsPptxModalFormValues.fileName = `${exportAsPptxModalFormValues.placeholderText.fileName}.pptx`
      }
      if (!exportAsPptxModalFormValues.title) {
        exportAsPptxModalFormValues.title = exportAsPptxModalFormValues.placeholderText.title
      }

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      return Rx.Observable.fromPromise(
        exportPPTX({
          activeStatisticsViewName,
          metaString,
          SVGs,
          type,
          periodChosen,
          ...exportAsPptxModalFormValues,
        }),
      )
        .map((activeStatisticsViewName) =>
          buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_PPTX_SUCCESS, { activeStatisticsViewName }),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_PPTX_FAILURE)))
    })

const exportStatisticsAsXLSX = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.STATISTICS_VIEW_EXPORT_XLSX)
    .switchMap(({ payload: { metaString, type, periodChosen } }) => {
      const state = getState()

      const exportAsXlsxModalFormValues = formValueSelector('statisticsExport')(
        state,
        'export_type',
        'title',
        'fileName',
        'placeholderText',
        'template_id',
      )

      // If exporting statistics, without filling "fileName" or "title", their placeholder will be filled.
      if (!exportAsXlsxModalFormValues.fileName) {
        exportAsXlsxModalFormValues.fileName = `${exportAsXlsxModalFormValues.placeholderText.fileName}.xlsx`
      }
      if (!exportAsXlsxModalFormValues.title) {
        exportAsXlsxModalFormValues.title = exportAsXlsxModalFormValues.placeholderText.title
      }

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      const selectedAspects = getSelectedAspects(state)
      const changedAspectsType = getChangedAspectsType(state)
      const changedAspectsCountBy = getChangedAspectsCountBy(state)
      const countBy = getCountBy(state)
      const topChartType = getSwitchableMainGraph()

      // Clean aspects type so not repeated
      const charts = selectedAspects.map((aspect) => ({
        name: aspect.name,
        id: aspect.id,
        type: changedAspectsType[aspect.name],
        countBy: changedAspectsCountBy[aspect.name],
      }))

      const aspectSet = getAspects(state)
      const documents = getStatDocuments(state)

      const { correctStartDate: startTime, correctEndDate: endTime } = getCorrectStatisticsDates(state)

      const filters = getStatFilters(state)
      const timeFilter = filters.time

      const [selectedStartTime, selectedEndTime] = timeFilter
        ?.find((f) => f.filterType === 'RangedFilter')
        ?.reduce((acc, red) => [...acc, red[0]]) ?? [startTime, endTime]

      const timeZone = new Date().getTimezoneOffset()

      const expressions = R.compose(searchDataToExpression, getMainSearchLine)(state)

      return Rx.Observable.fromPromise(
        exportXLSX({
          activeStatisticsViewName,
          metaString,
          charts,
          aspectSet,
          documents,
          countBy,
          topChartType,
          type,
          periodChosen,
          startTime,
          endTime,
          selectedStartTime,
          selectedEndTime,
          timeZone,
          expressions,
          ...exportAsXlsxModalFormValues,
        }),
      )
        ?.map((activeStatisticsViewName) =>
          buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_XLSX_SUCCESS, { activeStatisticsViewName }),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_XLSX_FAILURE)))
    })

const exportStatisticsAsCSV = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEW_EXPORT_CSV).switchMap(() => {
    const state = getState()
    const articles = getStatisticsViewArticles(state)
    const tags = getTags(state)
    const aspects = getAspects(state)
    const articlesWithAspects = getStatDocuments(state)

    const articleData = getExportArticleData(articles, tags, aspects, articlesWithAspects)
    const csvContent = arrayToCsv(articleData)
    downloadBlob(csvContent, 'export.csv', 'text/csv;charset=utf-8;')

    return Rx.Observable.empty()
  })

const exportStatisticsAsXLS = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEW_EXPORT_XLS).switchMap(() => {
    const state = getState()
    const articles = getStatisticsViewArticles(state)
    const tags = getTags(state)
    const aspects = getAspects(state)
    const articlesWithAspects = getStatDocuments(state)

    const articleData = getExportArticleData(articles, tags, aspects, articlesWithAspects)

    return Rx.Observable.fromPromise(exportXLS(articleData))
      ?.map((activeStatisticsViewName) =>
        buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_XLS_SUCCESS, { activeStatisticsViewName }),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_XLS_FAILURE)))
  })

export default [
  // showStatisticsEpic,
  compareStatisticsSearchEpic,
  deleteStatViewEpic,
  exportStatisticsAsPDF,
  exportStatisticsAsPPTX,
  exportStatisticsAsCSV,
  exportStatisticsAsXLS,
  exportStatisticsAsXLSX,
  fetchStatisticViewsEpic,
  fetchStatViewsDetailsEpic,
  saveStatViewEpic,
  statisticsAspectsResendEpic,
  statisticsAspectToggleEpic,
  statisticsSearchEpic,
  statisticsTagsEpic,
]
