import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const statisticsErrorTypes: ErrorEpic = {
  [ActionTypes.FETCH_STATISTICS_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to fetch statistics for this search'),
    sentryMessage: 'We were not able to fetch statistics for this search',
    toastId: 'FETCH_STATISTICS_FAILURE',
  },
  [ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS]: {
    isSuccess: true,
    message: t('Statistic view was successfully deleted'),
    toastId: 'STATISTICS_VIEW_DELETE_SUCCESS',
  },
  [ActionTypes.STATISTICS_VIEW_DELETE_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to delete selected statistic view'),
    sentryMessage: 'We were unable to delete selected statistic view',
    toastId: 'STATISTICS_VIEW_DELETE_FAILURE',
  },
  [ActionTypes.STATISTICS_VIEW_SAVE_SUCCESS]: {
    isSuccess: true,
    message: t('Statistic view was successfully saved'),
    toastId: 'STATISTICS_VIEW_SAVE_SUCCESS',
  },
  [ActionTypes.STATISTICS_VIEW_SAVE_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to save selected statistic view'),
    sentryMessage: 'We were unable to save selected statistic view',
    toastId: 'STATISTICS_VIEW_SAVE_FAILURE',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS]: {
    isSuccess: true,
    message: t('Statistics view PDF was successfully generated and downloaded'),
    toastId: 'STATISTICS_VIEW_EXPORT_PDF_SUCCESS',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE]: {
    isSuccess: false,
    message: t('Export statistics as PDF failed'),
    sentryMessage: 'Export statistics as PDF failed',
    toastId: 'STATISTICS_VIEW_EXPORT_PDF_FAILURE',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_PPTX_SUCCESS]: {
    isSuccess: true,
    message: t('Statistics view PPTX was successfully generated and downloaded'),
    toastId: 'STATISTICS_VIEW_EXPORT_PPTX_SUCCESS',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_PPTX_FAILURE]: {
    isSuccess: false,
    message: t('Export statistics as PPTX failed'),
    sentryMessage: 'Export statistics as PPTX failed',
    toastId: 'STATISTICS_VIEW_EXPORT_PPTX_FAILURE',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_XLSX_SUCCESS]: {
    isSuccess: true,
    message: t('Statistics view XLSX was successfully generated and downloaded'),
    toastId: 'STATISTICS_VIEW_EXPORT_XLSX_SUCCESS',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_XLSX_FAILURE]: {
    isSuccess: false,
    message: t('Export statistics as XLSX failed'),
    sentryMessage: 'Export statistics as XLSX failed',
    toastId: 'STATISTICS_VIEW_EXPORT_XLSX_FAILURE',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_XLS_SUCCESS]: {
    isSuccess: true,
    message: t('Statistics view XLS was successfully generated and downloaded'),
    toastId: 'STATISTICS_VIEW_EXPORT_XLS_SUCCESS',
  },
  [ActionTypes.STATISTICS_VIEW_EXPORT_XLS_FAILURE]: {
    isSuccess: false,
    message: t('Export statistics as XLS failed'),
    sentryMessage: 'Export statistics as XLS failed',
    toastId: 'STATISTICS_VIEW_EXPORT_XLS_FAILURE',
  },
  [ActionTypes.GO_TO_COMPARISON_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to compare periods'),
    sentryMessage: 'We were not able to compare periods',
    toastId: 'GO_TO_COMPARISON_FAILURE',
  },
}

const statisticsErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(statisticsErrorTypes, type))

export default statisticsErrorEpic
