const booleanValues = ['true', 'false']

const snakeCase = (string: string) => string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEnvironmentVariable = (variableName: string): any => {
  const constantName = snakeCase(variableName).toUpperCase()

  const value = process.env[`REACT_APP_${constantName}`] || process.env[constantName]

  if (booleanValues.includes(value)) {
    return JSON.parse(value)
  }

  if (isFinite(Number(value))) {
    return Number(value)
  }

  return value
}
