import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getEnvironmentVariable } from './helpers/environment'

const sentryDsn = getEnvironmentVariable('sentryDsn')
const environment = getEnvironmentVariable('customSentryEnv') || getEnvironmentVariable('env') || process.env.NODE_ENV
const debug = environment !== 'production' || environment !== 'ecb-production'
const commitRef = getEnvironmentVariable('vercelGitCommitSha') || getEnvironmentVariable('githubSha')

Sentry.init({
  dsn: sentryDsn,
  environment,
  release: commitRef,
  debug,
  integrations: [new Integrations.BrowserTracing(), new ExtraErrorData()],
  tracesSampleRate: 0.2,
  attachStacktrace: true,
  ignoreErrors: [
    'ChunkLoadError',
    'Non-Error exception captured',
    'Bad control character in string literal in JSON at position',
    'ResizeObserver loop',
  ],
  beforeSend(event) {
    if (event?.level === 'info') {
      return {
        ...event,
        exception: null,
      }
    }

    return event
  },
})
