import { useT } from '@transifex/react'
import classNames from 'classnames'

import Icon from '../../../common/Icon'
import Tooltip from '../../../common/Tooltip'

import styles from './index.module.scss'

type Props = {
  name: string
  id: number
  withIcon?: boolean
}

const sentimentValues = {
  459072: 'positive',
  459074: 'negative',
  459075: 'balanced',
  459076: 'neutral',
}

const SentimentTooltip = ({ name, id, withIcon = false }: Props): JSX.Element => {
  const t = useT()
  const sentiment = sentimentValues[id]

  return (
    <span>
      {withIcon && (
        <Tooltip content={t('Sentiment')}>
          <span>
            <Icon verticalCenter name="thermometer_small" />
          </span>
        </Tooltip>
      )}
      <Tooltip content={name}>
        <div className={classNames(styles.sentimentMarker, styles[withIcon ? 'small' : 'big'])}>
          <div className={classNames(styles.sentimentMarkerFilling, styles[sentiment])}></div>
        </div>
      </Tooltip>
    </span>
  )
}

export default SentimentTooltip
