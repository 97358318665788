// @ts-nocheck

import _classCallCheck from 'babel-runtime/helpers/classCallCheck'
import _extends from 'babel-runtime/helpers/extends'
import _inherits from 'babel-runtime/helpers/inherits'
import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties'
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Transition, { ENTERED, ENTERING } from 'react-transition-group/Transition'

let _fadeStyles

const propTypes = {
  /**
   * Show the component; triggers the fade in or fade out animation
   */
  in: PropTypes.bool,

  /**
   * Wait until the first "enter" transition to mount the component (add it to the DOM)
   */
  mountOnEnter: PropTypes.bool,

  /**
   * Unmount the component (remove it from the DOM) when it is faded out
   */
  unmountOnExit: PropTypes.bool,

  /**
   * Run the fade in animation when the component mounts, if it is initially
   * shown
   */
  appear: PropTypes.bool,

  /**
   * Duration of the fade animation in milliseconds, to ensure that finishing
   * callbacks are fired even if the original browser transition end events are
   * canceled
   */
  timeout: PropTypes.number,

  /**
   * Callback fired before the component fades in
   */
  onEnter: PropTypes.func,
  /**
   * Callback fired after the component starts to fade in
   */
  onEntering: PropTypes.func,
  /**
   * Callback fired after the has component faded in
   */
  onEntered: PropTypes.func,
  /**
   * Callback fired before the component fades out
   */
  onExit: PropTypes.func,
  /**
   * Callback fired after the component starts to fade out
   */
  onExiting: PropTypes.func,
  /**
   * Callback fired after the component has faded out
   */
  onExited: PropTypes.func,
}

const defaultProps = {
  in: false,
  timeout: 300,
  mountOnEnter: false,
  unmountOnExit: false,
  appear: false,
}

const fadeStyles = ((_fadeStyles = {}), (_fadeStyles[ENTERING] = 'in'), (_fadeStyles[ENTERED] = 'in'), _fadeStyles)

const Fade = (function (_React$Component) {
  _inherits(Fade, _React$Component)

  function Fade() {
    _classCallCheck(this, Fade)

    return _possibleConstructorReturn(this, _React$Component.apply(this, arguments))
  }

  Fade.prototype.render = function render() {
    const _props = this.props,
      className = _props.className,
      children = _props.children,
      props = _objectWithoutProperties(_props, ['className', 'children'])

    return React.createElement(Transition, props, function (status, innerProps) {
      return React.cloneElement(
        children,
        _extends({}, innerProps, {
          className: classNames('fade', className, children.props.className, fadeStyles[status]),
        }),
      )
    })
  }

  return Fade
})(React.Component)

Fade.propTypes = propTypes
Fade.defaultProps = defaultProps

export default Fade
