import R from 'ramda'
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { Folder, Profile, StatisticsView } from '../opoint/flow'
import { createNewFolder, deleteFolder, getFolders, reorderFolderPosition } from '../opoint/folders'
import { getIsECBUser } from '../selectors/authSelectors'
import { getSortedFolders } from '../selectors/foldersSelectors'
import { getProfiles } from '../selectors/profilesSelectors'
import { getStatisticsList } from '../selectors/statisticsSelectors'
import { getTags } from '../selectors/tagsSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const foldersTree: (
  folders: Array<Folder>,
  profiles: Array<Profile>,
  tags,
  statistics: Array<StatisticsView>,
) => Array<Folder> = (folders: Array<Folder>, profiles: Array<Profile>, tags, statistics: Array<StatisticsView>) => {
  const foldersWithChildren = folders?.map((folder) => {
    let items
    switch (folder.type) {
      case 0:
        items = profiles
        break
      case 1:
      case 2:
        items = tags
        break
      case 6:
        items = statistics
        break
    }

    const filteredItems = items.filter((item) => item.folder === folder.id && item.type === folder.type)

    const parentTree = R.groupBy(R.prop('parent'), filteredItems)
    const rootProfiles = parentTree[0]
    const setChildrenForProfile = (profile) =>
      R.assoc('children', (parentTree[profile.id] || [])?.map(setChildrenForProfile), profile)

    return {
      ...folder,
      children: rootProfiles ? R.map(setChildrenForProfile)(rootProfiles) : filteredItems,
    }
  })

  return foldersWithChildren
}

const fetchFoldersOnLogIn = (action$: any) =>
  action$
    .ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS)
    ?.mapTo(buildAction(ActionTypes.FETCH_FOLDERS))

const fetchFoldersOnEditAndCreationEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.CREATE_FOLDER_SUCCESS, ActionTypes.REORDER_FOLDER_SUCCESS, ActionTypes.DELETE_FOLDER_SUCCESS)
    ?.mapTo(buildAction(ActionTypes.FETCH_FOLDERS))

const foldersFetchEpic: (action$, { getState }) => void = (action$, { getState }) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FETCH_FOLDERS),
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
  )
    .delay(50)
    .switchMap(() => {
      const state = getState()
      const isECBUser = getIsECBUser(state)

      return Rx.Observable.fromPromise(getFolders())?.map((folders) =>
        buildAction(ActionTypes.FETCH_FOLDERS_SUCCESS, { isECBUser, folders }),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_FOLDERS_FAILURE)))

const createFoldersTreeEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$
    .ofType(
      ActionTypes.TAGS_FETCH_SUCCESS,
      ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS,
      ActionTypes.PROFILES_FETCH_SUCCESS,
      ActionTypes.ADD_TAG_SUCCESS,
      ActionTypes.DELETE_TAG_SUCCESS,
      ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS,
      ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS,
      ActionTypes.FETCH_FOLDERS_SUCCESS,
    )
    .delay(1000)
    .switchMap(() => {
      const state = getState()
      const tagsList = getTags(state)
      const profilesList = getProfiles(state)
      const foldersList = getSortedFolders(state)
      const statisticsList = getStatisticsList(state)

      const groupsTreeWithChildren = foldersTree(foldersList, profilesList, tagsList, statisticsList)

      return Rx.Observable.of(buildAction(ActionTypes.CREATE_FOLDERS_TREE_SUCCESS, groupsTreeWithChildren))
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)

const createFolderEpic = (action$) =>
  action$
    .ofType(ActionTypes.CREATE_FOLDER)
    .switchMap(({ payload }) => {
      const { body } = payload

      return Rx.Observable.fromPromise(createNewFolder(body))?.map(() => buildAction(ActionTypes.CREATE_FOLDER_SUCCESS))
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.CREATE_FOLDER_FAILURE)))

const reorderFolderEpic = (action$) =>
  action$
    .ofType(ActionTypes.REORDER_FOLDER)
    .switchMap(({ payload }) =>
      Rx.Observable.fromPromise(reorderFolderPosition(payload))?.map(() =>
        buildAction(ActionTypes.REORDER_FOLDER_SUCCESS),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.REORDER_FOLDER_FAILURE)))

const deleteFolderEpic = (action$) =>
  action$
    .ofType(ActionTypes.DELETE_FOLDER)
    .switchMap(({ payload }) =>
      Rx.Observable.fromPromise(deleteFolder(payload))?.map(() => buildAction(ActionTypes.DELETE_FOLDER_SUCCESS)),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.DELETE_FOLDER_FAILURE)))

export default [
  fetchFoldersOnLogIn,
  foldersFetchEpic,
  createFoldersTreeEpic,
  createFolderEpic,
  reorderFolderEpic,
  deleteFolderEpic,
  fetchFoldersOnEditAndCreationEpic,
]
