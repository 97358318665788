import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ToastProps } from 'react-toastify/dist/types'

import Icon from '../Icon'
import './infomedia_toastify.css'

export function success(message: string, props?: Partial<ToastProps>) {
  return toast.success(message, {
    ...props,
    toastId: props.toastId,
    icon: <Icon isDecorative={true} verticalCenter={true} name="checkmark_small" />,
    closeButton: <Icon verticalCenter={true} name="close_alt" />,
  })
}

export function error(message: string, props?: Partial<ToastProps>) {
  return toast.error(message, {
    ...props,
    toastId: props.toastId,
    icon: <Icon isDecorative={true} verticalCenter={true} name="cancel_small" />,
    closeButton: <Icon verticalCenter={true} name="close_alt" />,
  })
}

const Toasts = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={4}
      role={'alert'}
    ></ToastContainer>
  )
}

export default Toasts
