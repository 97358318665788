import { useT } from '@transifex/react'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { default as ReactModal } from 'react-modal'

import Button from '../Button'
import Icon from '../Icon'

import styles from './index.module.scss'

type DialogButton = {
  action: () => void
  text: string
}

export interface Props {
  accept?: DialogButton
  children?: ReactNode
  className?: string
  containerOverflow?: boolean
  dismiss?: DialogButton
  footer?: ReactNode
  isOpen: boolean
  mode?: 'normal' | 'small' | 'big'
  noPadding?: boolean
  onAfterClose?: () => void
  onHide?: (e?: any) => void
  overlayStopPropagation?: boolean
  title?: string | ReactNode
  subtitle?: string | ReactNode
  type?: 'modal' | 'dialog'
  center?: boolean
  contentClassName?: string
}

const Modal = ({
  accept,
  children,
  className,
  containerOverflow = true,
  dismiss,
  footer,
  isOpen,
  mode,
  noPadding,
  onAfterClose,
  onHide,
  overlayStopPropagation = true,
  title,
  type,
  center,
  subtitle,
  contentClassName,
}: Props): JSX.Element => {
  const t = useT()

  return (
    <ReactModal
      onAfterClose={onAfterClose || (() => {})}
      isOpen={isOpen}
      onRequestClose={onHide || (() => {})}
      contentLabel={title}
      className={classNames(
        styles.modal,
        {
          [styles.small]: mode === 'small',
          [styles.big]: mode === 'big',
          [styles.dialog]: type === 'dialog',
          [styles.nofooter]: !footer,
          [styles.overflow]: containerOverflow,
        },
        className,
      )}
      overlayClassName={classNames(styles.overlay, { [styles.center]: !!center })}
      closeTimeoutMS={100}
      overlayElement={(props, contentElement) => (
        <div onClick={(e) => overlayStopPropagation && e.stopPropagation()}>
          <div {...props}>{contentElement}</div>
        </div>
      )}
      shouldReturnFocusAfterClose={false}
    >
      {title && (
        <div className={classNames(styles.header, { [styles.hasSubtitle]: subtitle })}>
          <div>
            <h2>{title}</h2>
            {subtitle && <p>{subtitle}</p>}
          </div>
          {type !== 'dialog' && (
            <button type="button" aria-label={t('Close')} onClick={onHide}>
              <Icon name="close" />
            </button>
          )}
        </div>
      )}

      <div className={classNames(styles.content, { [styles.noPadding]: noPadding }, contentClassName)}>
        {type !== 'dialog' ? (
          children
        ) : (
          <>
            {children}
            {!!accept && (
              <Button name="solid" onClick={accept.action}>
                {accept.text}
              </Button>
            )}
            {!!dismiss && (
              <Button name="default" onClick={dismiss.action}>
                {dismiss.text}
              </Button>
            )}
          </>
        )}
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </ReactModal>
  )
}

ReactModal.setAppElement(document.getElementById('root'))

export default Modal
