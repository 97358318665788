import { saveAs } from 'file-saver'
import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'
import { StatisticsView } from '../flow'

export const apiToLocaleTypes = {
  pie: { type: 'pieChart', apiType: 'pie' },
  row: { type: 'rowChart', apiType: 'row' },
  widepie: { type: 'pieChart', apiType: 'widepie', isWide: true },
  freqtable: { type: 'customDataTable', apiType: 'freqtable' },
  seriesline: {
    type: 'lineChart',
    apiType: 'seriesline',
    series: 'overlapped',
    isWide: true,
  },
  seriesarea: {
    type: 'lineChart',
    apiType: 'seriesarea',
    series: 'stacked',
    isWide: true,
  },
  seriesbar: {
    type: 'barChart',
    apiType: 'seriesbar',
    series: 'stacked',
    isWide: true,
  },
  seriesbubble: {
    type: 'customBubbleChart',
    apiType: 'seriesbubble',
    series: 'overlapped',
    isWide: true,
  },
  timetable: {
    type: 'customDataTable',
    apiType: 'timetable',
    series: 'overlapped',
    isWide: true,
  },
}

export const defaultWidgetTypes = (aspectId: number) => {
  switch (aspectId) {
    // Speaker
    case 4:
    // Site rank
    case 15:
    // Day of week
    case 38:
    // Person name
    case 44:
      return 'row'
    // Site name
    case 10:
    // Time
    case 39:
    // County
    case 3:
    // Story
    case 40:
      return 'freqtable'
    default:
      return 'pie'
  }
}

export const reduceSumName = {
  count: 'n. of articles',
  circulation: 'circulation',
  reach: 'reach',
  eac: 'eac',
}

export async function getStatisticViews(): Promise<Array<StatisticsView>> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/statistics/'),
    method: 'GET',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getStatisticViewData(id: number | string) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/statistics/${id}/`),
    method: 'GET',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteStatisticsView(statViewId: string) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/statistics/${statViewId}/`),
    method: 'DELETE',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function saveStatisticView(statView, id) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: id ? config.url.api(`/statistics/${id}/`) : config.url.api('/statistics/'),
    method: id ? 'PATCH' : 'POST',
    body: JSON.stringify(statView),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const exportPDF = async ({
  activeStatisticsViewName,
  metaString,
  description,
  title,
  fileName,
  SVGs,
  type,
  periodChosen,
}) => {
  //TODO: Changed to '/export' in the pdf microservice

  // dev url:
  // const url = 'http://localhost:3442/export/'

  // Creates request headers for sending to api
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    // @ts-ignore
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      type,
      SVGsArray: SVGs,
      docTitle: title,
      docDescription: description,
      exportInfo: {
        metaString,
        statisticsViewName: activeStatisticsViewName || '',
        periodChosen,
        // TODO
        // statisticsProfiles: 'USA + Trump',
      },
    }),
  })

  const url = config.url.api('/export-statistics/')
  const request = new Request(url, requestHeaders)

  // Request blob data using fetch api instead as Rx.DOM.ajax seems to ignore blob responseType
  return fetch(request)
    .then((res) => {
      if (!res.ok) {
        throw 'PDF export failed'
      }

      return res.blob()
    })
    .then((PDFdocument) => {
      saveAs(PDFdocument, fileName || 'statistics-export.pdf')

      return activeStatisticsViewName
    })
}

export const exportPPTX = async ({
  activeStatisticsViewName,
  metaString,
  description,
  title,
  fileName,
  SVGs,
  type,
  periodChosen,
}) => {
  // dev url:
  // const url = 'http://localhost:3442/export/?format=pptx'

  // Creates request headers for sending to api
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    // @ts-ignore
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      type,
      SVGsArray: SVGs,
      docTitle: title,
      docDescription: description,
      exportInfo: {
        metaString,
        statisticsViewName: activeStatisticsViewName || '',
        periodChosen,
      },
    }),
  })

  const url = config.url.api('/export-statistics/?to=pptx')
  const request = new Request(url, requestHeaders)

  // Request blob data using fetch api instead as Rx.DOM.ajax seems to ignore blob responseType
  return fetch(request)
    .then((res) => {
      if (!res.ok) {
        throw 'PPTX export failed'
      }

      return res.blob()
    })
    .then((PPTXdocument) => {
      saveAs(PPTXdocument, fileName || 'statistics-export.pptx')

      return activeStatisticsViewName
    })
}

export const exportXLSX = async ({
  activeStatisticsViewName,
  metaString,
  export_type,
  title,
  fileName,
  type,
  periodChosen,
  charts,
  aspectSet,
  documents,
  countBy,
  topChartType,
  timeZone,
  startTime,
  endTime,
  selectedStartTime,
  selectedEndTime,
  expressions,
  template_id,
}) => {
  // Creates request headers for sending to api
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    // @ts-ignore
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      type,
      docTitle: title,
      templateId: template_id,
      expressions,
      dashboard: {
        originalPeriod: {
          start: startTime,
          end: endTime,
        },
        selectedPeriod: {
          start: selectedStartTime,
          end: selectedEndTime,
        },
        documents,
        aspectSet,
        countBy,
        topChartType,
        charts,
      },
      exportInfo: {
        timeZone,
        metaString,
        statisticsViewName: activeStatisticsViewName || '',
        periodChosen,
        exportType: export_type,
      },
    }),
  })

  const url = config.url.api('/export-statistics/?to=xlsx')
  const request = new Request(url, requestHeaders)

  // Request blob data using fetch api instead as Rx.DOM.ajax seems to ignore blob responseType
  return fetch(request)
    .then((res) => {
      if (!res.ok) {
        throw 'XLSX export failed'
      }

      return res.blob()
    })
    .then((XLSXdocument) => {
      saveAs(XLSXdocument, fileName || 'statistics-export.xlsx')

      return activeStatisticsViewName
    })
}

export const exportXLS = (articleData) => {
  const url = config.url.app('/export-statistics/?format=xls')

  // dev url:
  // const url = 'http://localhost:3442/export/?format=xls'

  return fetch(url, {
    headers: {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
      'accept-charset': 'utf-8',
    },
    // @ts-ignore
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      articleData,
    }),
  }).then((res) => {
    if (res.ok) {
      res.blob().then((blob) => {
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // If IE, you must uses a different method.
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(blob, 'export.xlsx')
        } else {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          a.download = 'export.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }
      })
    }
  })
}
