// @ts-nocheck
import { t } from '@transifex/native'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import objectSupport from 'dayjs/plugin/objectSupport'
import relativeTime from 'dayjs/plugin/relativeTime'
import toObject from 'dayjs/plugin/toObject'

dayjs.extend(objectSupport)
dayjs.extend(toObject)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

export const TYPE = {
  VERY_SHORT: 'VERY_SHORT',
  SHORT: 'SHORT',
  LONG: 'LONG',
  VERY_LONG: 'VERY_LONG',
  HUMAN: 'HUMAN',
  TIME_SHORT: 'TIME_SHORT',
  TIME_LONG: 'TIME_LONG',
  DATE_SHORT: 'DATE_SHORT',
  DATE: 'DATE',
  DATE_LONG: 'DATE_LONG',
  FULL_DATE: 'FULL_DATE',
  CUSTOM: 'CUSTOM',
}

/**
 * Intimacy unixtimestamp to regular unix timestamp convertor
 * @param intimacyUnixtimestamp
 * @returns unix timestamp {number}
 * @constructor
 */
export function OpointTimestampToTimestamp(intimacyUnixtimestamp = Math.round(+new Date() / 1000)) {
  const INT_MAX = 2147483647

  return INT_MAX - intimacyUnixtimestamp
}

export function IsoToUnixTimestamp(date: string): number {
  return dayjs(date).unix()
}

/**
 * Create number with leading zero
 * @private
 * @param num
 * @returns {string}
 */
function zeroPad(num) {
  return (num < 10 ? '0' : '') + num
}

/**
 * Nice custom and powerful function, that create human-readable seconds
 * Example:
 *  976 -> 16:16
 *  724 -> 12:04
 *  6   -> 00:06
 * @param seconds
 * @returns {string}
 */
export function getReadableSeconds(seconds) {
  const secondsInYear = 31536000
  const secondsInDay = 86400
  const secondsInHour = 3600
  const secondsInMinutes = 60

  const numYears = Math.floor(seconds / secondsInYear)
  const numDays = Math.floor((seconds % secondsInYear) / secondsInDay)
  const numHours = Math.floor((seconds % secondsInDay) / secondsInHour)
  const numMinutes = Math.floor((seconds % secondsInHour) / secondsInMinutes)
  const numSeconds = seconds % secondsInMinutes

  let concat = ''
  if (numYears) {
    concat += `${zeroPad(numYears)}:`
  }
  if (numDays) {
    concat += `${zeroPad(numDays)}:`
  }
  if (numHours) {
    concat += `${zeroPad(numHours)}:`
  }

  concat += `${zeroPad(numMinutes)}:${zeroPad(numSeconds)}`

  return concat
}

/**
 * Converts date to ISOFormat with local timezone
 * @param date
 * @returns {string}
 */
export const dateToISOWithLocalTimezone = (date) => {
  const dateObject = dayjs(date).toDate()
  const tzoffset = dateObject.getTimezoneOffset() * 60000 //offset in milliseconds
  const localISOTime = new Date(dateObject.valueOf() - tzoffset).toISOString()

  return localISOTime
}

/** *
 * Class for formatting date. Used as singleton, but has isolated locale setting from
 * the moment.
 */
class OpointDate {
  constructor() {
    this.moment = dayjs()
  }

  setLanguage(locale = 'en') {
    this.moment = this.moment.locale(locale)
  }

  disableRelative() {
    this.ageLimitSeconds = 0
  }

  isRelativeDisabled() {
    return this.ageLimitSeconds === 0
  }

  setCustomRelativeTimeOffset(limit) {
    this.ageLimitSeconds = limit
  }

  isRelative(date, relative) {
    return relative && Math.abs(dayjs().diff(dayjs(date), 'seconds')) <= this.ageLimitSeconds
  }

  /* eslint-disable-next-line consistent-return */
  format(date: Date | number = new Date(), type: any, shouldTranslate?: boolean, format?: any) {
    /*
     isolate moment translation inside OpointDate singleton service,
     return only this.moment object
     */
    this.moment = dayjs(date)

    // has to be relative?
    if (Math.abs(dayjs().diff(dayjs(date), 'seconds')) <= this.ageLimitSeconds) {
      return this.moment.fromNow()
    }

    // Spec: https://trello.com/c/1hdoj9PW/1883-always-use-absolute-dates-in-sorting-modal

    // is start/end of day
    const isMidnight =
      dayjs(date).endOf('day').diff(dayjs(date)) / 60000 < 6 || dayjs(date).diff(dayjs(date).startOf('day')) / 60000 < 6
    // for today or less than 6 hours old
    const isToday = dayjs(date).isSame(new Date(), 'day') || dayjs().diff(dayjs(date), 'hours') < 6
    // for days no longer than one week
    const isWeek = dayjs().diff(dayjs(date), 'days') < 6 && dayjs().diff(dayjs(date), 'days') > -6
    // this year and more than 6 months old
    const isYear =
      dayjs(date).isSame(new Date(), 'year') ||
      (dayjs().diff(dayjs(date), 'months') < 6 && dayjs().diff(dayjs(date), 'months') > -6)

    switch (type) {
      /**
       * Returned time formats: when - example - moment format
       * today      -  12:31AM    -  LT
       * yesterday  -  yesterday  -
       * lt 7 days  -  sunday     -  dddd
       * gt 7 days  -  6th Jan  - Do MMM
       * lt 6 months - Oct 2016 - MMM Y
       */
      case TYPE.VERY_SHORT: {
        // for today or less than 6 hours old
        if (isToday) {
          return this.moment.format('LT')
          // is yesterday?
        }
        if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
          // TODO hackish way to get translated "Yesterday" fragile. :(
          const string = this.moment.calendar().split(' ')
          if (string[0].length <= 2) {
            return `${string[0]} ${string[1]}`
          }

          return string[0]
          // is the diff is max 7 days
        }

        if (dayjs().diff(dayjs(date), 'days') <= 7) {
          return this.moment.format('dddd')
        }

        if (isYear) {
          return this.moment.format('Do MMM')
        }

        return this.moment.format('MMM YYYY')
      }

      /**
       * Returned time formats: when - example - moment format
       * today      -  12:31AM    -  LT
       * yesterday  -  yesterday  -
       * lt 7 days  -  sunday     -  dddd
       * gt 7 days  -  03/2/2017  -  L
       */
      case TYPE.SHORT:
        // for today or less than 6 hours old
        if (isToday) {
          return this.moment.format('LT')
          // is yesterday?
        }
        if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
          // TODO hackish way to get translated "Yesterday" fragile. :(
          const string = this.moment.calendar().split(' ')
          if (string[0].length <= 2) {
            return `${string[0]} ${string[1]}`
          }

          return string[0]
          // is the diff is max 7 days
        }
        if (dayjs().diff(dayjs(date), 'days') <= 7) {
          return this.moment.format('dddd')
        }

        return this.moment.format('L')

      /**
       * Returned time formats: when - example - moment format
       * today                           -  12:31AM           -  LT
       * same year or older lt 6 months  -  10th Apr 1:33 PM  -  Do MMM LT
       * others                          -  10th Apr 2015     -  Do MMM YYYY
       */
      case TYPE.LONG:
        if (isToday) {
          return this.moment.format('LT')
        }
        if (isYear) {
          return this.moment.format('Do MMM LT')
        }

        return this.moment.format('Do MMM YYYY')

      /**
       * Returned time formats: when - example - moment format
       * today                           -  12:31AM                -  LT
       * same year or older lt 6 months  -  10th Apr 1:33 PM       -  Do MMM LT
       * others                          -  10th Apr 1:33 PM 2015  -  Do MMM LT YYYY
       */
      case TYPE.VERY_LONG:
        if (isToday) {
          return this.moment.format('LT')
        }
        if (isYear) {
          return this.moment.format('Do MMM LT')
        }

        return this.moment.format('Do MMM LT YYYY')

      /**
       * Returned time formats: when - example - moment format
       * all  -  2017-05-30 15:42  -  YYYY-MM-DD HH:mm
       */
      case TYPE.FULL_DATE:
        return this.moment.format('YYYY-MM-DD HH:mm')

      /**
       * This format is used in Toolbar header for search result information
       * Returned time formats: when - example - moment format
       * now +- 5min               -  Now               -
       * same day                  -  Today at 7:32 AM  -  formated via .calendar()
       * same week or lt 6 days    -  Monday, 12:29AM   -  dddd, LT
       * same year or lt 6 months  -  Apr 11th          -  MMM Do
       * others                    -  Apr 11th, 2015    -  MMM Do Y
       */
      case TYPE.HUMAN: {
        // TODO: refactor to another function
        if (dayjs(date).isSame(new Date(), 'day')) {
          if (dayjs(date).isBetween(dayjs().subtract(5, 'minute'), dayjs(), 'minute', '[]')) {
            return shouldTranslate ? t('Now') : 'Now'
          }

          return shouldTranslate
            ? dayjs(date).calendar(null, {
                lastDay: t('[Yesterday at] {dateFormat}', { dateFormat: 'LT' }),
                sameDay: t('[Today at] {dateFormat}', { dateFormat: 'LT' }),
                nextDay: t('[Tomorrow at] {dateFormat}', { dateFormat: 'LT' }),
                lastWeek: t('[last] {day} [at] {dateFormat}', { day: 'dddd', dateFormat: 'LT' }),
                nextWeek: t('{day} [at] {dateFormat}', { day: 'dddd', dateFormat: 'LT' }),
                sameElse: 'L',
              })
            : dayjs(date).calendar()
        }

        if (isWeek) {
          if (isMidnight) {
            return this.moment.format('dddd')
          }

          return this.moment.format('dddd, LT')
        }

        // fallback for english
        if (dayjs.locale() === 'en') {
          // for this year or less than 6 months difference
          if (isYear) {
            return this.moment.format('MMM Do')
          }

          // default format
          return this.moment.format('MMM Do, YYYY')
        }

        // for this year or less than 6 months difference
        if (isYear) {
          return this.moment.format('Do MMMM')
        }

        // default format
        return this.moment.format('Do MMMM, YYYY')
      }

      /**
       * Returned time formats: when - example - moment format
       * all  -  12:31 AM  -  LT
       */
      case TYPE.TIME_SHORT:
        return this.moment.format('LT')

      /**
       * Returned time formats: when - example - moment format
       * all  -  12:31:38 AM  -  LTS
       */
      case TYPE.TIME_LONG:
        return this.moment.format('LTS')

      /**
       * Returned time formats: when - example - moment format
       * all  -  9/4/2017  -  l
       */
      case TYPE.DATE_SHORT:
        return this.moment.format('l')

      /**
       * Returned time formats: when - example - moment format
       * all  -  Sep 4 1987  -  ll
       */
      case TYPE.DATE_LONG:
        return this.moment.format('ll')
      /**
       * Returned time formats: when - example - moment format
       * all  -  September 4, 1987  -  MMMM DD, YYYY
       */
      case TYPE.DATE:
        return this.moment.format('MMMM DD, YYYY')

      case TYPE.CUSTOM:
        return this.moment.format(format)

      default:
    }
  }

  /* date-time (approx) */

  // returns in format 19th Jun 3:14 AM
  longFormat = (date) => this.format(date, TYPE.LONG)
  // returns in format 19th Jun 3:14 AM
  veryLongFormat = (date) => this.format(date, TYPE.VERY_LONG)
  // returns in format Tuesday
  shortFormat = (date) => this.format(date, TYPE.SHORT)
  // returns in format Tuesday
  veryShortFormat = (date) => this.format(date, TYPE.VERY_SHORT)
  // returns in format Tuesday, 3:14 AM
  humanFormat = (date, shouldTranslate = false) => this.format(date, TYPE.HUMAN, shouldTranslate)

  /* only time (exact) */

  // returns in format 3:14:07 AM
  longTimeFormat = (date) => this.format(date, TYPE.TIME_LONG)
  // returns in format 3:14 AM
  shortTimeFormat = (date) => this.format(date, TYPE.TIME_SHORT)

  /* only date (exact) */

  // returns in format 1/19/2038
  longDateFormat = (date) => this.format(date, TYPE.DATE_LONG)
  // returns in format 1/19/2038
  shortDateFormat = (date) => this.format(date, TYPE.DATE_SHORT)
  // returns in format January 19, 2038
  dateFormat = (date) => this.format(date, TYPE.DATE)
  // returns without changes
  customFormat = (date, format) => this.format(date, TYPE.CUSTOM, null, format)
}

export default new OpointDate()
