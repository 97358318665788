export var Size = {
  LARGE: 'large',
  SMALL: 'small',
  XSMALL: 'xsmall',
}

export var SIZE_MAP = {
  large: 'lg',
  medium: 'md',
  small: 'sm',
  xsmall: 'xs',
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs',
}

export var DEVICE_SIZES = ['lg', 'md', 'sm', 'xs']

export var State = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
}

export var Style = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  LINK: 'link',
  INVERSE: 'inverse',
}
