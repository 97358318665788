const WIDGET_KEY = 'WIDGET_SELECTION'

export const setWidgetSelection = (value) => (localStorage[WIDGET_KEY] = value)

export const getWidgetSelection = () => {
  return localStorage[WIDGET_KEY]
}

const CF_KEY = 'SWITCHABLE_MAIN_GRAPH'

export const setSwitchableMainGraph = (value) => (localStorage[CF_KEY] = value)

export const getSwitchableMainGraph = () => {
  return localStorage[CF_KEY]
}
