import buildAction from './buildAction'

type ActionsType = { [key: string]: string }
type OutputType = { [key: string]: (payload: any) => void }

export default (actions: ActionsType): OutputType =>
  Object.keys(actions).reduce(
    (buildedActions, action) => ({
      ...buildedActions,
      [action]: (payload) =>
        buildAction(actions[action], typeof payload === 'object' && payload.persist ? null : payload),
    }),
    {},
  )
