import assoc from 'ramda/src/assoc'
import compose from 'ramda/src/compose'

import * as Actions from '../constants/actionTypes'
import { Action, CommentType } from '../opoint/flow'

type CommentsState = {
  comments: Array<CommentType>
  commentsModalOpen: boolean
  articleDetails: { id_site: number; id_article: boolean; article_header: string } | {}
  alteredArticleComments: any
}

export const initialState: CommentsState = {
  comments: [],
  commentsModalOpen: false,
  articleDetails: {},
  alteredArticleComments: {},
}

const alteredArticleCommentsLength = {}

const commentsReducer = (state: CommentsState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.FETCH_ARTICLE_COMMENTS_SUCCESS: {
      const { comments, articleId } = payload
      alteredArticleCommentsLength[articleId] = comments.length

      return compose(assoc('comments', comments), assoc('alteredArticleComments', alteredArticleCommentsLength))(state)
    }

    case Actions.OPEN_ARTICLE_COMMENTS_MODAL: {
      return compose(assoc('articleDetails', payload), assoc('commentsModalOpen', true))(state)
    }

    case Actions.CLOSE_ARTICLE_COMMENTS_MODAL: {
      return assoc('commentsModalOpen', false)(state)
    }

    default:
      return state
  }
}

export default commentsReducer
