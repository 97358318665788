import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import type { Action, Notification } from '../opoint/flow'

type notificationState = {
  notificationCount: number
  page: number
  list: Array<Notification>
}

const initialState = {
  notificationCount: 0,
  page: 1,
  list: [],
}

const notificationsReducer = (state: notificationState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.LOGOUT: {
      return initialState
    }

    case Actions.NOTIFICATIONS_FETCH_SUCCESS: {
      const page = R.prop('page', state)
      const { results, next } = payload

      return R.evolve(
        {
          page: next ? R.inc : R.always(page),
          list: R.compose(
            /* eslint-disable-next-line no-underscore-dangle */
            R.concat(R.__, results),
            R.take(R.multiply(5, page)),
          ),
        },
        state,
      )
    }

    case Actions.NOTIFICATIONS_COUNT_FETCH_SUCCESS: {
      const { count } = payload

      return R.assoc('notificationCount', count, state)
    }

    case Actions.NOTIFICATIONS_INC_COUNT: {
      return R.evolve(
        {
          notificationCount: R.inc,
        },
        state,
      )
    }

    case Actions.NOTIFICATIONS_SOCKET_SUCCESS: {
      const notification = payload

      return R.evolve(
        {
          list: R.prepend(notification),
        },
        state,
      )
    }

    case Actions.DELETE_REPORT_NOTIFICATION_SUCCESS: {
      const { id } = payload

      return R.evolve(
        {
          list: R.reject(R.propEq('id', id)),
        },
        state,
      )
    }

    default:
      return state
  }
}

export default notificationsReducer
