import { t } from '@transifex/native'
import map from 'ramda/src/map'
import merge from 'ramda/src/merge'
import Rx from 'rx-dom'

import config from '../common/config'
import { SUPPORTED_LOCALES } from '../common/constants'
import type { Setting } from '../flow'

export const LISTING_STYLES = {
  MODERN: 0,
  SEARCH_ENGINE: 1,
  EMAIL: 2,
  ARCHIVE_RIGHT: 3,
  ARCHIVE_LEFT: 4,
  INTRO: 5,
}

// TIme offset for relative times in seconds
// BEWARN - IF YOU MODIFY THIS, YOU ALSO NEED TO MODIFY STRING IN TRANSLATIONS.JS
export const TIME_OFFSET = {
  NEVER: {
    sec: 0,
    label: t('Never'),
  },
  LESS_THAN_15_MINUTES: {
    sec: 900,
    label: t('Age of article is less than 15 minutes'),
  },
  LESS_THAN_1_HOURS: {
    sec: 3600,
    label: t('Age of article is less than 1 hour'),
  },
  LESS_THAN_6_HOURS: {
    sec: 21600,
    label: t('Age of article is less than 6 hours'),
  },
  LESS_THAN_24_HOURS: {
    sec: 86400,
    label: t('Age of article is less than 24 hours'),
  },
  LESS_THAN_A_WEEK: {
    sec: 604800,
    label: t('Age of article is less than a week'),
  },
}

const normalizeSettingValues = map(({ name, value, choices }) => {
  let settingValue = value
  if (name === 'LANGUAGE' && !SUPPORTED_LOCALES.includes(settingValue)) {
    settingValue = 'en-GB' /* eslint-disable-line no-param-reassign */
  }

  // due to original mistake in Chinese locale
  if (settingValue === 'zh_CN') {
    settingValue = 'zh-CN'
  }

  if (name === 'MAX_GT_ARTICLE_LENGTH') {
    settingValue = Number(settingValue) /* eslint-disable-line no-param-reassign */
  }

  return { name, value: settingValue, choices }
})

export async function getSettings() {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/settings/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => normalizeSettingValues(response))
}

export async function saveSettings(settings: Array<Setting>) {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/settings/'),
    method: 'POST',
    body: JSON.stringify(normalizeSettingValues(settings)),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
