// @ts-nocheck

import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { filterId } from '../opoint/search/index'

const initialState = {
  filters: {},
}

const entityRepositoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.SEARCHFILTER_ADDED:
    case Actions.SEARCHFILTER_TOGGLED:
      return R.compose(R.assoc('filters', R.__, state), R.merge(state.filters), R.indexBy(filterId))([payload])

    case Actions.FETCH_FILTER_DETAIL_SUCCESS:
      return payload.length
        ? R.compose(R.assoc('filters', R.__, state), R.merge(state.filters), R.indexBy(filterId))(payload)
        : state

    case Actions.REFRESH_FILTERS_NAME:
      return R.evolve({ filters: R.empty }, state)

    default:
      return state
  }
}

export default entityRepositoryReducer
