// @ts-nocheck

if (process.env.REACT_APP_API === 'production') {
  /* eslint-disable-next-line no-multi-assign */
  const dataLayer = (window.dataLayer = window.dataLayer || [])

  const gtag = () => {
    /* eslint-disable-next-line no-undef */
    dataLayer.push(arguments)
  }

  gtag('js', new Date())
  gtag('config', 'UA-364695-11')
}

export default {}
