import { isEmpty } from 'ramda'
import Rx from 'rxjs'

import handleToastNotification, { ToastProps } from '../../../new-components/helpers/handleToastNotification'

import alertErrorEpic from './alertErrorEpic'
import articlesErrorEpic from './articlesErrorEpic'
import commentErrorEpic from './commentErrorEpic'
import contactErrorEpic from './contactErrorEpic'
import feedErrorEpic from './feedErrorEpic'
import foldersErrorEpic from './foldersErrorEpic'
import notificationsErrorEpic from './notificationsErrorEpic'
import portalErrorEpic from './portalErrorEpic'
import profileEditorErrorEpic from './profileEditorErrorEpic'
import profilesErrorEpic from './profilesErrorEpic'
import reportErrorEpic from './reportErrorEpic'
import routerErrorEpic from './routerErrorEpic'
import searchErrorEpic from './searchErrorEpic'
import statisticsErrorEpic from './statisticsErrorEpic'
import tagErrorEpics from './tagErrorEpics'
import templateErrorEpics from './templateErrorEpics'

export type ErrorEpic = {
  [actionType: string]: ToastProps
}

export default [
  alertErrorEpic,
  articlesErrorEpic,
  commentErrorEpic,
  contactErrorEpic,
  feedErrorEpic,
  notificationsErrorEpic,
  portalErrorEpic,
  profileEditorErrorEpic,
  profilesErrorEpic,
  reportErrorEpic,
  routerErrorEpic,
  searchErrorEpic,
  statisticsErrorEpic,
  tagErrorEpics,
  templateErrorEpics,
  foldersErrorEpic,
]

export const handlerError = (errorTypes, type, payload = {}) => {
  const anotherErrorData = errorTypes[type] || {}
  const errorDataWithPayload = !isEmpty(payload) && (errorTypes(payload)[type] || {})

  const errorData = errorDataWithPayload ? errorDataWithPayload : anotherErrorData

  if (isEmpty(errorData)) {
    return Rx.Observable.of()
  }

  handleToastNotification(errorData)

  return Rx.Observable.of()
}
