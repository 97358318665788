import { createSelector } from 'reselect'

import { Modules, MODULES } from '../new-components/constants/permissions'
import { FILTERS_ORDER_DEFAULT, autoTranslateLanguages } from '../new-components/constants/settings'
import { keyValueParse } from '../new-components/helpers/common'
import type { Settings } from '../new-components/types/settings'
import { clearBit, isBitSet } from '../opoint/common'
import {
  browserLocaleToOpointLocale,
  locales,
  getFullnameLanguage,
  TRANSLATE_ARTICLE,
} from '../opoint/common/constants'
import { FiltersMap, FilterType } from '../opoint/flow'

import { getSettings as getState, getSearch as getSearchState, getReports as getReportState } from './rootSelectors'
import { getSearchFilters } from './searchSelectors'

export const getSettingsList = createSelector(getState, (settingsState) => settingsState.list)

export const getIsSavingSettings = createSelector(getState, (settingsState) => settingsState.isSaving)

export const getSettingsChoices = createSelector(getState, (settingsState) => settingsState.choices)

export const getUISetting = (uiSetting: Settings | string) =>
  createSelector(getState, (settingsState) => settingsState.list.APP_UI_SETTINGS[uiSetting])

export const getDefaultHome = createSelector(getState, (settingsState) => settingsState.list.APP_DEFAULT_HOME)

export const getUISettings = createSelector(getState, (settingsState) => settingsState.list.APP_UI_SETTINGS)

export const getLoadMore = createSelector(getState, (settingsState) => settingsState.list.NEW_PORTAL_AUTOLOAD || false)

export const getDefaultSearch = createSelector(getState, (settingsState) => settingsState.list.DEFAULT_SEARCH || null)

export const getSimilarArticlesVisible = createSelector(
  getState,
  (settingsState) => settingsState.list.SIMILAR_SETTING_VISIBLE === MODULES.SIMILAR_SETTING_VISIBLE.ON,
)

export const getStatisticsDefaultName = createSelector(
  getState,
  (settingsState) => settingsState.list.DEFAULT_FOLDER_NAME_STATISTICS,
)

/* eslint-disable-next-line max-len */
// https://trello.com/c/LbdaWuRU/1987-control-grouping-of-articles-in-article-listing-reports-and-alerts#comment-597b2d3c4af4f811fc3f70ca
export const getGroupingEnabled = createSelector(getState, (settingsState) => settingsState.list.IDENTICAL)

export const getOpointLocale = createSelector(getState, (settingsState) => {
  if (settingsState.list.LANGUAGE && settingsState.list.LANGUAGE !== 'browser') {
    return settingsState.list.LANGUAGE
  }

  // @ts-ignore
  const browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage
  const opointLocale = browserLocaleToOpointLocale(browserLanguage)
  const { isoCode } = settingsState

  // in case opoint locale is english and isoCode was fetched, we provide the language based
  // on a browser language
  if (opointLocale === 'en-GB' && isoCode) {
    switch (isoCode) {
      case 'NO':
        return 'nb-NO'
      case 'SE':
        return 'sv-SE'
      case 'DK':
        return 'da-DK'
      case 'FI':
        return 'fi-FI'
      default:
    }
  }

  return 'en-GB' // english as a default language
})

/**
 * This selector shouldn't be used anywere but in a settings modal
 * because it can return 'browser' value
 */
export const getOpointLocaleSetting = createSelector(getSettingsList, getOpointLocale, (list, opointLocaleResult) =>
  !list.LANGUAGE || list.LANGUAGE === 'browser' ? 'browser ' : opointLocaleResult,
)

export const getSuggestionLocale = createSelector(getState, getOpointLocale, (settingsState, localeString) => {
  const suggestServerSorting = settingsState.list.SUGGESTSERVER_SORTING

  const { localeName } = locales[localeString]

  const suggestionLocale = `${localeName}_${suggestServerSorting}`

  return suggestionLocale
})

export const getSuggestionsLocale = createSelector(getOpointLocale, (localeString) => locales[localeString])

export const getArticleListingStyle = createSelector(getState, (settingsState) => +settingsState.list.NEW_PORTAL_LAYOUT)

export const getFiltersOrder = createSelector(getUISetting('NEW_PORTAL_FILTERS'), (filtersOrder: string) => {
  if (!filtersOrder) {
    return FILTERS_ORDER_DEFAULT
  }

  return keyValueParse(filtersOrder)
    .filter(({ value }) => value === 'true')
    ?.map(({ key }) => key) as FilterType[]
})

export const getArticleMetadata = createSelector(
  getUISetting('NEW_PORTAL_SHOW_METADATA'),
  (state) => state,
  (metadataString?: string) =>
    keyValueParse(metadataString)
      .filter(({ value }) => value === 'true')
      ?.map(({ key }) => key),
)

export const getRelativeOffset = createSelector(
  getState,
  (settingsState) => parseInt(settingsState.list.NEW_PORTAL_RELATIVE_DATE_LIMIT, 10) || 0,
)

export const getUserPermissions = (module: Modules) =>
  createSelector(getState, (settingsState) => {
    // Forces ORGANIZATIONS_MODULE to show. Delete when we have the permission from backend
    if (module === 'ORGANIZATIONS_MODULE') {
      settingsState.list[module] = 'D'
    }

    return settingsState.list[module]
  })

export const getAutoTranslationQuota = createSelector(getState, ({ list }) =>
  Number(list.GT_QUOTA && list.GT_QUOTA.quota),
)

export const getAutoTranslationRemainingQuota = createSelector(getState, ({ list }) =>
  Number(list.GT_QUOTA && Math.max(list.GT_QUOTA.quota - list.GT_QUOTA.google_translated, 0)),
)

export const isAutoTranslationsOn = createSelector(
  getState,
  getAutoTranslationRemainingQuota,
  ({ list }, remainingQuota) => Boolean(isBitSet(list.TRANSLATE_TYPE, TRANSLATE_ARTICLE) && remainingQuota),
)

export const getAutoTranslationLanguageName = createSelector(
  getState,
  (settingsState) =>
    Object.values(
      autoTranslateLanguages.find((langObj) => Object.keys(langObj)[0] === settingsState.list.TRANSLATE_TO),
    )[0],
)

export const getArticleAgeLimit = createSelector(getState, (settingsState) => {
  const { from, to } = settingsState.list.ARTICLE_AGE_LIMIT

  return {
    // if `to` is 0, let's just not define it at all to avoid some bugs with very recent articles
    to: to === 0 ? undefined : to,
    from,
  }
})

export const getTranslateAuto = createSelector(getState, ({ list }) => list.TRANSLATE_AUTO)

export const getAutoTranslateSearchParams = createSelector(
  getState,
  getAutoTranslationRemainingQuota,
  getTranslateAuto,
  getReportState,
  (settingsState, remainingQuota, translateAuto, reportState) =>
    ['TRANSLATE_TYPE', 'TRANSLATE_TO', 'TRANSLATE_CFG', 'MAX_GT_ARTICLE_LENGTH'].reduce((settings, key) => {
      let val = settingsState.list[key]
      if (key === 'TRANSLATE_TYPE') {
        if (!remainingQuota) {
          // do not translate articles if no quota available
          val = clearBit(val, TRANSLATE_ARTICLE)
        }
        if (!translateAuto) {
          // disable search translations if not enabled in action line
          val = 0
        }
        if (reportState.autoTranslate) {
          val = 3
        }
      }
      settings[key.toLowerCase()] = val

      return settings
    }, {}),
)

export const getTranslateToLanguageInFullname = createSelector(
  getState,
  (settingsState) => getFullnameLanguage([settingsState.list.TRANSLATE_TO])[0],
)

export const getTranslateTo = createSelector(getState, (settingsState) => settingsState.list.TRANSLATE_TO)

export const getLastReportDate = createSelector(getState, (settingsState) => settingsState.list.LAST_ART_TIMESTAMP)

export const getColorbarColors = createSelector(getState, (settingsState) => settingsState.list.COLORBAR_COLORS)

export const getSettingsFetched = createSelector(getState, (settingsState) => settingsState.settingsFetched)

export const getActiveLanguage = createSelector(getState, (settingsState) => settingsState.list.LANGUAGE)

export const isUserArchiveOnly = createSelector(getState, ({ choices }) => {
  const layoutValues: Array<number> = choices.NEW_PORTAL_LAYOUT?.map(({ value }) => value)
  // Archive-only user gets 2 layout options from backend => Archive-right(value: 3) and Archive-left(value: 4)

  return layoutValues?.length === 2 && layoutValues.includes(3) && layoutValues.includes(4)
})

export const getSearchSuggest = createSelector(
  getUISetting('NEW_PORTAL_SEARCH_SUGGEST'),
  (searchSuggest) => searchSuggest,
)

export const getFilterSuggest = createSelector(
  getUISetting('NEW_PORTAL_FILTER_SUGGEST'),
  (filterSuggest) => filterSuggest,
)

export const getMaxProfiles = createSelector(getState, (settingsState) => settingsState.list.NEW_PORTAL_MAX_PROFILES)

export const getMaxAlerts = createSelector(getState, (settingsState) => settingsState.list.NEW_PORTAL_MAX_ALERTS)

export const getFilterDrilldown = createSelector(getState, (settingsState) => settingsState.list.FILTER_DRILLDOWN)

export const getMaxAlertRecipients = createSelector(
  getState,
  (settingsState) => settingsState.list.NEW_PORTAL_MAX_ALERTS_RECIPIENTS,
)

export const canShowEntitiesHightlight = createSelector(getState, ({ choices }) => {
  const entitiesValues: Array<number> = choices.ENTITIES_SELECTED?.map(({ value }) => value)

  // Only users with both choices for ENTITIES_SELECTED can see entity highlighting in settings
  return entitiesValues.length === 2 && entitiesValues.includes(1) && entitiesValues.includes(0)
})

export const getEntitiesSelected = createSelector(getState, (settingsState) => settingsState.list.ENTITIES_SELECTED)

export const hasNonPdfReportAccess = createSelector(getState, (settingsState) => {
  const hasPDFAccess = settingsState.list.TEMPLATE_ACCESS.pdf
  const templateAccessList = Object.entries(settingsState.list.TEMPLATE_ACCESS)
  const totalAccessedTemplateTypes = templateAccessList.filter(([, hasAccess]) => hasAccess)

  const PDFReportAccess = totalAccessedTemplateTypes.length === 1 && hasPDFAccess

  return !PDFReportAccess
})

export const getStatisticsExportAccess = createSelector(
  getState,
  (settingsState) => settingsState.list.NEW_PORTAL_STATISTICS_EXPORT_FORMATS,
)

export const hasDisplayPrintPdfLink = createSelector(
  getState,
  (settingsState) => settingsState.list.DISPLAY_PRINT_PDF_LINK === MODULES.DISPLAY_PRINT_PDF_LINK.ON,
)

export const hasLimitedSearch = createSelector(
  getState,
  (settingsState) => settingsState.list.LIMITED_SEARCH === MODULES.LIMITED_SEARCH.ON,
)

export const isLimitedSearch = createSelector(
  hasLimitedSearch,
  getSearchState,
  getSearchFilters,
  (hasLimitedSearch, searchState, searchFilters) => {
    const hasFilters = !Object.values(searchFilters as FiltersMap).filter(
      (x) => x.type === 'tag' || x.type === 'trash' || x.type === 'profile',
    ).length

    return hasLimitedSearch && !!searchState.nofreesearch && hasFilters
  },
)

export const hasChatEnabledSelector = createSelector(
  getState,
  (settingsState) => settingsState.list.NEW_PORTAL_SUPPORT?.chat,
)

export const getDefaultTemplateId = createSelector(
  getState,
  (settingsState) => settingsState.list.NEW_PORTAL_DEFAULT_TEMPLATE,
)

export const getCommentGroups = createSelector(getState, (settingsState) => {
  const commentGroups = settingsState.list.NEW_PORTAL_COMMENT_GROUPS

  return commentGroups.sort((a, b) => a.id - b.id)
})

export const getDomainSetting = createSelector(getState, (settingsState) => settingsState.list.NEW_PORTAL_MAIN_DOMAIN)

export const getAllowedDomains = createSelector(getState, (settingsState) => settingsState.list.ALLOWED_DOMAINS)
