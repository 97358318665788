import jwtDecode from 'jwt-decode'
import R from 'ramda'
import Rx from 'rx-dom'

import { pushLocation } from '../../epics/epicsHelper'
import config from '../common/config'

export const APPROXIMATE_CLOCK_SKEW = 8 // 8 seconds
export const SERVER_TIME_TIMER = 60 * 5 - APPROXIMATE_CLOCK_SKEW // 5 minutes - 8 seconds

export function removeToken() {
  window.localStorage.removeItem('ls.OP-PORTAL-AUTH-TOKEN')
}

export function removeImpersonatorToken() {
  window.localStorage.removeItem('ls.OP-PORTAL-AUTH-IMPERSONATER-TOKEN')
}

export function setToken(token: string) {
  window.localStorage.setItem('ls.OP-PORTAL-AUTH-TOKEN', token)
}

export function setImpersonatorToken(token) {
  window.localStorage.setItem('ls.OP-PORTAL-AUTH-IMPERSONATER-TOKEN', token)
}

function getToken() {
  return window.localStorage.getItem('ls.OP-PORTAL-AUTH-TOKEN')
}

export function getImpersonatorToken() {
  return window.localStorage.getItem('ls.OP-PORTAL-AUTH-IMPERSONATER-TOKEN')
}

export function isAuthenticated() {
  return getToken() !== null
}

export function isImpersonating() {
  return !!config.auth.impersonating
}

export const decodeToken: (token: string) => any = jwtDecode

export function getUserInfo() {
  return isAuthenticated() ? jwtDecode(getToken()) : null
}

export function getImpersonatorInfo() {
  return isAuthenticated() ? jwtDecode(getImpersonatorToken()) : null
}

/**
 * Authenticates a user using username and password
 * @param username
 * @param password
 * @param rememberMe
 * @returns Promise
 */

export async function authenticate(username: string, password: string, rememberMe = true) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/auth/get-token/'),
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      remember_me: rememberMe,
    }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function authenticateByIp(username: string, rememberMe = true) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/auth/get-token-by-ip/'),
    method: 'POST',
    body: JSON.stringify({
      username,
      remember_me: rememberMe,
    }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function impersonate(id: number, privileges_index?: number) {
  await config.auth.impersonate(id, privileges_index)

  const token = config.auth.getTokenString().then((response) => response)

  if (!id && !privileges_index) {
    const impersonatorToken = getImpersonatorToken()

    if (impersonatorToken) {
      removeImpersonatorToken()
    }
  }

  return token
}

export async function authenticateWithToken(token: string) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(false), {
    url: config.url.api(`/auth/auto-login/?hash=${token}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Builds the login route with redirect string
 * @param redirectAfterLoginPath Path to redirect the user to after success full login. Defaults to /
 * @returns The login route with redirect string
 */

export const loginRoute = (redirectAfterLoginPath = '/') => pushLocation(`/login?next=${redirectAfterLoginPath}`)
