import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const notificationsErrorTypes: ErrorEpic = {
  [ActionTypes.NOTIFICATIONS_FETCH_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to fetch notifications'),
    sentryMessage: 'We were unable to fetch notifications',
    toastId: 'NOTIFICATIONS_FETCH_FAILURE',
  },
}

const notificationsErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(notificationsErrorTypes, type))

export default notificationsErrorEpic
