import { createSelector } from 'reselect'

import { getNotifications as getState } from './rootSelectors'

export const getNotificationsCount = createSelector(
  getState,
  (notificationsState) => notificationsState.notificationCount,
)

export const getNotificationsPage = createSelector(getState, (notificationsState) => notificationsState.page)

export const getNotifications = createSelector(getState, (notificationsState) => notificationsState.list)
