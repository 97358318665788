import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const contactsErrorTypes: ErrorEpic = {
  [ActionTypes.ADD_ARTICLE_SUCCESS]: {
    isSuccess: true,
    message: t('Article was successfully saved, and it`s ID is copied to clipboard'),
    toastId: 'ADD_ARTICLE_SUCCESS',
  },
  [ActionTypes.EDIT_ECB_ARTICLE_SUCCESS]: {
    isSuccess: true,
    message: t('Article was successfully updated.'),
    toastId: 'EDIT_ECB_ARTICLE_SUCCESS',
  },
  [ActionTypes.CONTACT_SAVE_SUCCESS]: {
    isSuccess: true,
    message: t('Contact was successfully saved.'),
    toastId: 'CONTACT_SAVE_SUCCESS',
  },
  [ActionTypes.CONTACT_DELETE_SUCCESS]: {
    isSuccess: true,
    message: t('Contact was successfully deleted'),
    toastId: 'CONTACT_DELETE_SUCCESS',
  },
  [ActionTypes.GROUP_DELETE_SUCCESS]: {
    isSuccess: true,
    message: t('Group was successfully deleted'),
    toastId: 'GROUP_DELETE_SUCCESS',
  },
  [ActionTypes.GROUP_SAVE_SUCCESS]: {
    isSuccess: true,
    message: t('Group was successfully saved.'),
    toastId: 'GROUP_SAVE_SUCCESS',
  },
  [ActionTypes.CONTACT_SAVE_ERROR]: {
    isSuccess: false,
    message: t('We were unable to save this contact'),
    sentryMessage: 'We were unable to save this contact',
    toastId: 'CONTACT_SAVE_ERROR',
  },
  [ActionTypes.CONTACT_DOMAIN_NOT_ALLOWED_ERROR]: {
    isSuccess: false,
    message: t('Given email domain is not allowed'),
    sentryMessage: 'Given email domain is not allowed',
    toastId: 'CONTACT_DOMAIN_NOT_ALLOWED_ERROR',
  },
  [ActionTypes.GROUP_SAVE_ERROR]: {
    isSuccess: false,
    message: t('We were unable to save this group'),
    sentryMessage: 'We were unable to save this group',
    toastId: 'GROUP_SAVE_ERROR',
  },
  [ActionTypes.CONTACT_INACTIVE_RESET_SUCCESS]: {
    isSuccess: true,
    message: t('Contact alerts pause is now not active.'),
    toastId: 'CONTACT_INACTIVE_RESET_SUCCESS',
  },
  [ActionTypes.CONTACT_INACTIVE_RESET_ERROR]: {
    isSuccess: false,
    message: t('We were unable to reset pause of alerts for this contact'),
    sentryMessage: 'We were unable to reset pause of alerts for this contact',
    toastId: 'CONTACT_INACTIVE_RESET_ERROR',
  },
  [ActionTypes.CONTACT_DELETE_ERROR]: {
    isSuccess: false,
    message: t('We were unable to delete this contact'),
    sentryMessage: 'We were unable to delete this contact',
    toastId: 'CONTACT_DELETE_ERROR',
  },
  [ActionTypes.CONTACT_USED_DELETE_ERROR]: {
    isSuccess: false,
    message: t('We were unable to delete this contact'),
    sentryMessage: 'We were unable to delete this contact',
    toastId: 'CONTACT_USED_DELETE_ERROR',
  },
  [ActionTypes.GROUP_DELETE_ERROR]: {
    isSuccess: false,
    message: t('We were unable to delete this group'),
    sentryMessage: 'We were unable to delete this group',
    toastId: 'GROUP_DELETE_ERROR',
  },
  [ActionTypes.CONTACT_SET_ACTIVE_FAILURE]: {
    isSuccess: false,
    message: t('We are currently not able to select this contact'),
    sentryMessage: 'We are currently not able to select this contact',
    toastId: 'CONTACT_SET_ACTIVE_FAILURE',
  },
  [ActionTypes.GROUP_SET_ACTIVE_FAILURE]: {
    isSuccess: false,
    message: t('We are currently not able to select this group'),
    sentryMessage: 'We are currently not able to select this group',
    toastId: 'GROUP_SET_ACTIVE_FAILURE',
  },
}

const contactsErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(contactsErrorTypes, type))

export default contactsErrorEpic
