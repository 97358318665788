const traits = [-2, -1, 0, 1, 2, 3, 4, 10] as const

export const folderTraits = {
  [-2]: 'categorizationOnly',
  [-1]: 'categorizationAndFilters',
  0: 'regular',
  1: 'curated',
  2: 'nonCurated',
  3: 'socialMedia',
  4: 'frontPages',
  10: 'special',
}

export type FolderTrait = (typeof traits)[number]

const types = [0, 1, 2, 4, 6] as const

export const folderTypes = {
  0: 'profiles',
  1: 'tags',
  2: 'sentimentTags',
  4: 'alerts',
  6: 'statistics',
}

export type FolderType = (typeof types)[number]
