import R from 'ramda'
import Rx from 'rx-dom'

import { handleErrors } from '../common'
import config from '../common/config'
import type { Folder } from '../flow'

/**
 * Retrieves folders from API and transforms them into a Promise.
 * @returns {*}
 */
export async function getFolders(): Promise<Array<Folder>> {
  // Query on how to fetch specific type and if the folders are owned or not
  // /folders/?type=0&owner=true

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/folders/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function createNewFolder(body) {
  const method = 'POST'

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method,
    url: config.url.api('/folders/'),
    body: JSON.stringify(body),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const reorderFolderPosition: (body: { id: number; position: number }) => Promise<Response> = (body) => {
  const url = config.url.api(`/folders/sort/`)

  const request = new Request(url, {
    ...config.request.getRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request).then(handleErrors)
}

export const deleteFolder: (id: number) => Promise<Response> = (id) => {
  const url = config.url.api(`/folders/${id}/`)

  const request = new Request(url, {
    ...config.request.getRequestHeaders(),
    method: 'DELETE',
  })

  return fetch(request).then(handleErrors)
}

// TODO: Not yet in use
export async function updateFolder(existingFolder, updates) {
  const id = existingFolder.id
  const method = 'PATCH'

  const body = updates

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method,
    url: config.url.api(`/folders/${id}/`),
    body: JSON.stringify(body),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getSpecificFolder(existingFolder) {
  const id = existingFolder.id

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/folders/${id}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
