import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const alertErrorTypes = (payload): ErrorEpic => {
  return {
    [ActionTypes.ALERT_DELETE_SUCCESS]: {
      isSuccess: true,
      message: t('Alert was successfully deleted'),
      toastId: 'ALERT_DELETE_SUCCESS',
    },
    [ActionTypes.SAVE_ALERT_SUCCESS]: {
      isSuccess: true,
      message: t('Alert was successfully saved'),
      toastId: 'SAVE_ALERT_SUCCESS',
    },
    [ActionTypes.ALERT_SEND_SUCCESS]: {
      isSuccess: true,
      message: t('Alert was successfully sent'),
      toastId: 'ALERT_SEND_SUCCESS',
    },
    [ActionTypes.ALERT_HISTORY_SEND_AGAIN_SUCCESS]: {
      isSuccess: true,
      message: t('Sent alert was successfully resent'),
      toastId: 'ALERT_HISTORY_SEND_AGAIN_SUCCESS',
    },
    [ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS]: {
      isSuccess: true,
      message: t('Articles were successfully removed'),
      toastId: 'ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS',
    },
    [ActionTypes.GO_TO_ALERT_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to go to selected alert'),
      sentryMessage: 'We were unable to go to selected alert',
      toastId: 'GO_TO_ALERT_FAILURE',
    },
    [ActionTypes.ALERT_DELETE_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to delete selected alert'),
      sentryMessage: 'We were unable to delete selected alert',
      toastId: 'ALERT_DELETE_FAILURE',
    },
    [ActionTypes.CREATE_ALERT_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to go to the creation of a new alert'),
      sentryMessage: 'We were unable to go to the creation of a new alert',
      toastId: 'CREATE_ALERT_FAILURE',
    },
    [ActionTypes.SAVE_ALERT_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to save this alert'),
      sentryMessage: 'We were unable to save this alert',
      toastId: 'SAVE_ALERT_FAILURES',
    },
    [ActionTypes.ALERT_SEND_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to send this alert'),
      sentryMessage: 'We were unable to send this alert',
      toastId: 'ALERT_SEND_FAILURES',
    },
    [ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to delete selected articles'),
      sentryMessage: 'We were unable to delete selected articles',
      toastId: 'ALERT_REMOVE_TRASHED_ARTICLES_FAILURE',
    },
    [ActionTypes.ALERT_FETCH_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch this alert'),
      sentryMessage: 'We were unable to fetch this alert',
      toastId: 'ALERT_FETCH_FAILURE',
    },
    [ActionTypes.ALERT_FETCH_NEXT_PREVIEW_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch preview of next alert'),
      sentryMessage: 'We were unable to fetch preview of next alert',
      toastId: 'ALERT_FETCH_NEXT_PREVIEW_FAILURE',
    },
    // TBD
    [ActionTypes.ALERT_FETCH_NEXT_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch this alert'),
      sentryMessage: 'We were unable to fetch this alert',
      toastId: 'ALERT_FETCH_NEXT_FAILURE',
    },
    [ActionTypes.ALERT_FETCH_HISTORY_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch alert history'),
      sentryMessage: 'We were unable to fetch alert history',
      toastId: 'ALERT_FETCH_HISTORY_FAILURE',
    },
    [ActionTypes.ALERT_FETCH_HISTORY_ITEM_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch alert history details'),
      sentryMessage: 'We were unable to fetch alert history details',
      toastId: 'ALERT_FETCH_HISTORY_ITEM_FAILURE',
    },
    [ActionTypes.ALERT_TEMPLATE_FETCH_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to get this preview'),
      sentryMessage: 'We were unable to get this preview',
      toastId: 'ALERT_TEMPLATE_FETCH_FAILURE',
    },
    [ActionTypes.SAVE_ALERT_VALID_FAILURE]: {
      isSuccess: false,
      message: payload?.error,
      sentryMessage: payload?.error,
      toastId: 'SAVE_ALERT_VALID_FAILURE',
    },
    [ActionTypes.LEAVE_ALERT_FAILURE]: {
      isSuccess: false,
      message: t('Unable to leave alert correctly'),
      sentryMessage: 'Unable to leave alert correctly',
      toastId: 'LEAVE_ALERT_FAILURE',
    },
  }
}

const alertErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type, payload }) => handlerError(alertErrorTypes, type, payload))

export default alertErrorEpic
