import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const routerErrorTypes: ErrorEpic = {
  [ActionTypes.ROUTE_CHANGE_FAILURE]: {
    isSuccess: false,
    message: t('We are unable to change route, try again'),
    sentryMessage: 'We are unable to change route, try again',
    toastId: 'ROUTE_CHANGE_FAILURE',
  },
}

const routerErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(routerErrorTypes, type))

export default routerErrorEpic
