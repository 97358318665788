import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const foldersErrorTypes: ErrorEpic = {
  [ActionTypes.FETCH_FOLDERS_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to fetch folders'),
    sentryMessage: 'We were unable to fetch folders',
    toastId: ActionTypes.FETCH_FOLDERS_FAILURE,
  },
  [ActionTypes.CREATE_FOLDER_SUCCESS]: {
    isSuccess: true,
    message: t('Folder was successfully created'),
    toastId: ActionTypes.CREATE_FOLDER_SUCCESS,
  },
  [ActionTypes.CREATE_FOLDER_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to create the folder'),
    sentryMessage: 'We were unable to create the folder',
    toastId: ActionTypes.CREATE_FOLDER_FAILURE,
  },
  [ActionTypes.DELETE_FOLDER_SUCCESS]: {
    isSuccess: true,
    message: t('Folder was successfully deleted'),
    toastId: ActionTypes.CREATE_FOLDER_FAILURE,
  },
  [ActionTypes.DELETE_FOLDER_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to delete the folder'),
    sentryMessage: 'We were unable to delete the folder',
    toastId: ActionTypes.DELETE_FOLDER_FAILURE,
  },
  [ActionTypes.REORDER_FOLDER_SUCCESS]: {
    isSuccess: true,
    message: t('Folder was successfully reordered'),
    toastId: ActionTypes.REORDER_FOLDER_SUCCESS,
  },
  [ActionTypes.REORDER_FOLDER_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to reorder the folder'),
    sentryMessage: 'We were unable to reorder the folder',
    toastId: ActionTypes.REORDER_FOLDER_FAILURE,
  },
}

const foldersErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(foldersErrorTypes, type))

export default foldersErrorEpic
