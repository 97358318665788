// @ts-nocheck
import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { contactRecipientDataTransformToFilter, eqContactFilters, sortByProp } from '../opoint/contacts'
import type { Action, Contact, ContactDeleteWarningType, ContactFilter, GroupDetails } from '../opoint/flow'

type ContactState = {
  activeContactId?: number
  activeGroup?: GroupDetails
  details: {} // TODO contact detail type
  contactDeleteWarning: null | ContactDeleteWarningType
  editedAlertEmail: Array<ContactFilter>
  editedAlertSms: Array<ContactFilter>
  expandContacts: boolean
  expandGroups: boolean
  groupDetails: any // TODO group details type
  list: [] | Array<Contact>
  listGroups: [] | Array<GroupDetails>
  shareArticlesContacts: Array<ContactFilter>
  shareReportContacts: any
  errors: { [key: string]: string[] }
}

export const initialState: ContactState = {
  activeContactId: null,
  activeGroup: null,
  contactDeleteWarning: null,
  details: {},
  editedAlertEmail: [],
  editedAlertSms: [],
  expandContacts: true,
  expandGroups: false,
  groupDetails: {},
  list: [],
  listGroups: [],
  shareArticlesContacts: [],
  shareReportContacts: [],
  fetchingGroupsDetails: true,
  errors: {},
}

const contactsReducer = (state: ContactState = initialState, { type, payload }: Action) => {
  switch (type) {
    case Actions.LOGOUT:
      return initialState

    case Actions.CONTACTS_FETCH_SUCCESS: {
      return R.assoc('list', payload, state)
    }
    case Actions.CONTACTS_FETCH: {
      return R.assoc('loadContacts', true, state)
    }
    case Actions.CONTACT_SET_ACTIVE_SUCCESS: {
      return R.compose(R.assoc('contactDeleteWarning', null), R.assoc('activeContactId', payload.id))(state)
    }
    case Actions.CONTACT_REMOVE_ACTIVE:
    case Actions.CONTACT_INIT_NEW: {
      return R.compose(R.assoc('activeContactId', null), R.assoc('contactDeleteWarning', null))(state)
    }
    case Actions.CONTACTS_ACTIVE: {
      return R.compose(R.assoc('expandContacts', true), R.assoc('expandGroups', false))(state)
    }
    case Actions.CONTACTS_GROUP_ACTIVE: {
      return R.compose(R.assoc('expandGroups', true), R.assoc('expandContacts', false))(state)
    }
    case Actions.CONTACT_DETAIL_FETCH_SUCCESS: {
      const contact = payload

      return R.evolve({
        details: R.assoc(contact.id, R.assoc('groups', R.concat(contact.groups, []), contact)),
      })(state)
    }
    case Actions.CONTACT_SAVE_SUCCESS: {
      return R.evolve({
        list: R.reduce((acc, contact) => {
          if (R.equals(contact.id, payload.id)) {
            return R.append(payload, acc)
          }

          return R.append(contact, acc)
        }, []),
        errors: R.always({}),
      })(state)
    }
    case Actions.CONTACT_DELETE_SUCCESS:
      return R.evolve({
        list: R.reject(R.propEq('id', payload)),
        activeContactId: R.always(null),
        contactDeleteWarning: R.always(null),
      })(state)
    case Actions.CONTACT_USED_DELETE_ERROR: {
      const {
        message,
        data: { alerts },
      } = payload

      return R.assoc(
        'contactDeleteWarning',
        {
          message,
          alerts: R.compose(R.join(', '), R.map(R.prop('name')))(alerts),
        },
        state,
      )
    }
    case Actions.CONTACT_GROUPS_CHECKED: {
      const contactId = parseInt(payload, 10)
      const eqContact = R.eqProps('id')
      const contact = state.list.find(({ id }) => id === contactId)

      return R.evolve({
        activeGroup: {
          contacts: R.ifElse(R.path([contactId]), R.reject(eqContact({ id: contactId })), R.assoc(contactId, contact)),
        },
      })(state)
    }
    case Actions.GROUPS_FETCH_SUCCESS: {
      const groups = sortByProp('id', payload)

      return R.assoc('listGroups', groups, state)
    }

    case Actions.GROUPS_FETCH: {
      return R.assoc('loadGroups', true, state)
    }

    case Actions.GROUP_DETAIL_FETCH_SUCCESS: {
      const group = payload

      return R.evolve({
        groupDetails: R.assoc(
          group.id,
          R.evolve(
            {
              contacts: R.compose(R.indexBy(R.prop('id')), R.values),
            },
            group,
          ),
        ),
      })(state)
    }

    case Actions.GROUP_SET_ACTIVE_SUCCESS: {
      const activeGroup = R.evolve(
        {
          contacts: R.compose(R.indexBy(R.prop('id')), R.values),
        },
        payload,
      )

      return R.assoc('activeGroup', activeGroup, state)
    }

    case Actions.GROUP_REMOVE_ACTIVE: {
      return R.assoc('activeGroup', null, state)
    }

    case Actions.GROUP_SAVE_SUCCESS: {
      return R.evolve({
        listGroups: R.reduce((acc, group) => {
          if (R.equals(group.id, payload.id)) {
            return R.append(payload, acc)
          }

          return R.append(group, acc)
        }, []),
        /* eslint-disable-next-line no-underscore-dangle */
        activeGroup: R.merge(R.__, payload),
      })(state)
    }
    case Actions.GROUP_INIT_NEW: {
      return R.compose(R.assocPath(['activeGroup', 'contacts'], {}), R.assoc('activeGroup', {}))(state)
    }

    case Actions.GROUP_DELETE_SUCCESS:
      return R.evolve({
        listGroups: R.reject(R.propEq('id', payload)),
        activeGroup: R.always(null),
      })(state)

    case Actions.ON_CONTACT_FILTER_ADDED: {
      const { filter, contactsPath } = payload
      const contactsLens = R.lensPath(contactsPath.split('.'))

      return R.over(contactsLens, R.when(R.none(eqContactFilters(filter)), R.append(filter)), state)
    }

    case Actions.ON_CONTACT_FILTER_REMOVED: {
      const { filter, contactsPath } = payload
      const contactsLens = R.lensPath(contactsPath.split('.'))

      return R.over(contactsLens, R.reject(eqContactFilters(filter)), state)
    }

    case Actions.ON_CONTACTS_FILTER_EMPTIED: {
      const { contactsPath } = payload
      const contactsLens = R.lensPath(contactsPath.split('.'))

      return R.set(contactsLens, [], state)
    }

    case Actions.ON_CONTACT_FILTER_TOGGLED: {
      const { filter, contactsPath } = payload
      const contactsLens = R.lensPath(contactsPath.split('.'))

      return R.over(
        contactsLens,
        R.ifElse(R.any(eqContactFilters(filter)), R.reject(eqContactFilters(filter)), R.append(filter)),
        state,
      )
    }

    case Actions.ALERT_CLEAR_RECIPIENTS:
      return R.compose(R.assoc('editedAlertSms', []), R.assoc('editedAlertEmail', []))(state)

    case Actions.ALERT_INIT_RECIPIENTS: {
      const { smsRecipients, recipients } = payload
      const listByType = {
        person: state.list,
        group: state.listGroups,
      }
      const editedAlertEmail = contactRecipientDataTransformToFilter(recipients, listByType)
      const editedAlertSms = contactRecipientDataTransformToFilter(smsRecipients, listByType)

      return R.compose(R.assoc('editedAlertEmail', editedAlertEmail), R.assoc('editedAlertSms', editedAlertSms))(state)
    }

    case Actions.ALERT_FORM_STORE_INIT_VALUES: {
      const {
        recipients: { recipients: email, smsRecipients: sms },
        mifluzIdLists,
      } = payload
      const listByTypes = {
        person: state.list,
        group: state.listGroups,
      }

      return R.assoc(
        'alertEditorInitValues',
        {
          recipients: {
            recipients: contactRecipientDataTransformToFilter(email, listByTypes),
            smsRecipients: contactRecipientDataTransformToFilter(sms, listByTypes),
          },
          mifluzIdLists,
        },
        state,
      )
    }

    case Actions.INIT_FORM: // Init alert's form
      return state.alertEditorInitValues
        ? state
        : R.assoc(
            'alertEditorInitValues',
            {
              recipients: {
                recipients: [],
                smsRecipients: [],
              },
              mifluzIdLists: [],
            },
            state,
          )

    case Actions.SHARE_ARTICLE_MODAL_CLOSE: {
      return R.assoc('shareArticlesContacts', [], state)
    }

    case Actions.CLEAR_SHARE_REPORT_RECIPIENTS: {
      return R.assoc('shareReportContacts', [], state)
    }

    case Actions.CONTACT_FILTER_CHANGE: {
      return R.assoc('contactFilter', payload, state)
    }

    case Actions.CLEAR_CONTACT_FILTER: {
      return R.dissoc('contactFilter', state)
    }

    case Actions.CONTACT_SAVE_ERROR: {
      return R.assoc('errors', payload.responseErrors, state)
    }

    default:
      return state
  }
}

export default contactsReducer
