import { t } from '@transifex/native'
import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'
import type {
  Alert,
  NextAlert,
  AlertDetailed,
  AlertRecipients,
  AlertTag,
  AlertShortRecipient,
  AlertShortRecipientAPI,
  AlertEmailRecipient,
  Article,
} from '../flow'
import { TAG_TYPES, TAG_VISIBILITY } from '../tags'

/**
 * Constants
 */

export const WORKING_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const WEEKEND_DAYS = ['saturday', 'sunday']

export const ALL_DAYS = [...WORKING_DAYS, ...WEEKEND_DAYS, 'holidays']

export const WHEN_ALERT = {
  WEEKDAYS_AT_8: 1,
  WEEKDAYS_AT_8_AND_2: 2,
  AS_SOON_AS_POSSIBLE: 3,
  ADVANCED: 4,
}

export const MAX_AGE_OPTIONS = [
  { seconds: 60 * 60 * 24 * 1, name: t('1 day') },
  { seconds: 60 * 60 * 24 * 2, name: t('{count} days', { count: 2 }) },
  { seconds: 60 * 60 * 24 * 3, name: t('{count} days', { count: 3 }) },
  { seconds: 60 * 60 * 24 * 4, name: t('{count} days', { count: 4 }) },
  { seconds: 60 * 60 * 24 * 7, name: t('{count} days', { count: 7 }) },
  { seconds: 60 * 60 * 24 * 14, name: t('{count} days', { count: 14 }) },
  { seconds: 60 * 60 * 24 * 30, name: t('{count} days', { count: 30 }) },
  { seconds: 60 * 60 * 24 * 365 * 20, name: t('20 years') },
]
/* eslint-disable-next-line no-underscore-dangle */
export const isWorkingDay = R.contains(R.__, WORKING_DAYS)
/* eslint-disable-next-line no-underscore-dangle */
export const isWeekendDay = R.contains(R.__, WEEKEND_DAYS)

export const WhenAlert = {
  weekdays_at_8: 0,
  weekdays_at_8_and_2: 1,
  as_soon_as_possible: 3,
  advanced: 4,
}

export const recipientsApiNormalize = (
  recipients: Array<AlertShortRecipient | AlertEmailRecipient>,
  // @ts-ignore
): AlertShortRecipientAPI[] => recipients?.map(({ type, id, name, value }) => ({ type, value: `${value || id}` }))

export const recipientsEntitiesApiNormalize = (recipients): AlertShortRecipientAPI[] =>
  recipients?.map(({ type, entity: { id } }) => ({ type, value: `${id}` }))

/**
 * Functions
 */

export const getAlerts = async (): Promise<Array<Alert>> => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/alerts/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertsRecipients = async (): Promise<Array<AlertRecipients>> => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/alerts/alert-contact-details/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlert = async (id: number): Promise<AlertDetailed> => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertNext = async (id: number): Promise<NextAlert> => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/next/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertNextPreview = async (id: number): Promise<Object> => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/next/preview/`),
    method: 'POST',
    body: JSON.stringify({ id, view: 'preview' }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// TODO: Proper flowtype for history @Honza
export const getAlertHistory = async (id: number, page?: string): Promise<Object> => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/history/${page ? `?page=${page}` : ''}`),
    method: 'GET',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// TODO: Proper flowtype for history @Honza
export const getAlertHistoryItem = async (alertId: number, historyId: number, timestamp: number) => {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/history/${historyId}/${timestamp}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function saveAlert(alert: AlertDetailed): Promise<AlertDetailed> {
  const isNewAlert = alert.id === undefined

  const method = isNewAlert ? 'POST' : 'PUT'
  const url = isNewAlert ? '/alerts/' : `/alerts/${alert.id}/`
  const body = isNewAlert ? R.dissoc('id', alert) : alert

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    method,
    url: config.url.api(url),
    body: JSON.stringify(body),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteAlert(alertId: string): Promise<AlertDetailed> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/`),
    method: 'DELETE',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function sendNow(alertId: string): Promise<AlertDetailed> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/send-now/`),
    method: 'POST',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

type SendAgainParams = {
  alertHistoryItem: any
  recipients: Array<AlertShortRecipient | AlertEmailRecipient>
}
export async function sendAgain({ alertHistoryItem, recipients }: SendAgainParams): Promise<AlertDetailed> {
  const { alertId, historyId, historyTimestamp } = alertHistoryItem

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/history/${historyId}/${historyTimestamp}/resend/`),
    body: JSON.stringify({ recipients: recipientsEntitiesApiNormalize(recipients) }),
    method: 'POST',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getArticlesToRemove(alertId: string, pageId: number): Promise<Array<Article>> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/articles/?page=${pageId}`),
    method: 'GET',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function removeArticlesFromAlert(alertId: string, articles): Promise<Array<Article>> {
  const body = {
    articles: Object.values(articles),
  }
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/articles/`),
    method: 'POST',
    responseType: 'text',
    body: JSON.stringify(body),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// TODO: write tests
export function processAlertTags(alerts: Array<Alert>): Array<AlertTag> {
  // @ts-ignore
  return alerts?.map((alert) => ({
    id: alert.id,
    children: R.map((a) => R.assoc('type', TAG_TYPES.ALERT, a), alert.baskets),
    subject: alert.subject,
    type: TAG_TYPES.ALERT,
    visibility: TAG_VISIBILITY.ALWAYS,
    expanded: false,
  }))
}
