import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const templateErrorTypes: ErrorEpic = {
  [ActionTypes.TEMPLATE_SAVE_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully saved your template'),
    toastId: 'TEMPLATE_SAVE_SUCCESS',
  },
  [ActionTypes.TEMPLATE_NEW_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully created new template'),
    toastId: 'TEMPLATE_NEW_SUCCESS',
  },
  [ActionTypes.TEMPLATE_DELETE_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully deleted template'),
    toastId: 'TEMPLATE_DELETE_SUCCESS',
  },
  [ActionTypes.TEMPLATE_SAVE_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to save your template'),
    sentryMessage: 'We were not able to save your template',
    toastId: 'TEMPLATE_SAVE_FAILURE',
  },
  [ActionTypes.TEMPLATE_NEW_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to create new template'),
    sentryMessage: 'We were not able to create new template',
    toastId: 'TEMPLATE_NEW_FAILURE',
  },
  [ActionTypes.TEMPLATE_DELETE_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to delete your template'),
    sentryMessage: 'We were not able to delete your template',
    toastId: 'TEMPLATE_DELETE_FAILURE',
  },
}

const templateErrorEpics: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(templateErrorTypes, type))

export default templateErrorEpics
