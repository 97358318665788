import { OpointAuthContext, useOpointAuthState } from '@opoint/authjs-react'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import * as ActionTypes from '../../../constants/actionTypes'
import { getSettingsFetched } from '../../../selectors/settingsSelectors'
import Loader from '../../common/Loader'
import { isMobileDevice } from '../../helpers/ui'

import styles from './index.module.css'

type Props = {
  as: React.ElementType
}

const RequireAuthentication: React.FC<Props> = ({ as: Component }) => {
  const authState = useOpointAuthState()
  const authContext = useContext(OpointAuthContext)

  const settingsFetched = useSelector(getSettingsFetched)

  const dispatch = useDispatch()
  const location = useLocation()

  const { user } = authState || {}

  if (authState) {
    if (!user || !settingsFetched) {
      return (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )
    }

    const isMobilePath = location.pathname.startsWith('/mobile')

    if (
      (isMobilePath && location.search.length === 0) ||
      (!isMobilePath && document.documentElement.clientWidth <= 1100 && isMobileDevice())
    ) {
      dispatch({ type: ActionTypes.GO_TO_DEFAULT_PROFILE_MOBILE })
    } else if (location.pathname === '/') {
      dispatch({ type: ActionTypes.GO_TO_DEFAULT_PROFILE })
    } else if (location.pathname.startsWith('/frontpages')) {
      // TODO
      // no-op
    } else {
      dispatch({ type: ActionTypes.SEARCH_DATA_INIT })
    }

    return <Component />
  }

  if (authState === undefined) {
    return (
      <div className={styles.wrapper}>
        <Loader />
      </div>
    )
  } else if (authState === null) {
    authContext.login(`?redirect=${encodeURIComponent(location.pathname + location.search)}`)
  }
}

export default RequireAuthentication
