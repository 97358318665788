import { createSelector } from 'reselect'

import { getImpersonationTargets as getState } from './rootSelectors'

export const getImpersonationOrganizations = createSelector(
  getState,
  (impersonationTargetsState) => impersonationTargetsState.organizations,
)

export const getImpersonationUsers = createSelector(
  getState,
  (impersonationTargetsState) => impersonationTargetsState.users,
)

export const getIsImpersonating = createSelector(
  getState,
  (impersonationTargetsState) => impersonationTargetsState.isImpersonating,
)

export const getForUsersList = createSelector(
  getState,
  (impersonationTargetsState) => impersonationTargetsState.forUsersList,
)

export const getIsAllowedToImpersonate = createSelector(getState, (impersonationTargetsState) => {
  return impersonationTargetsState.forUsersList.length > 0
})

export const getInitialLoading = createSelector(
  getState,
  (impersonationTargetsState) => impersonationTargetsState.initialLoading,
)

export const getLoading = createSelector(getState, (impersonationTargetsState) => impersonationTargetsState.loading)
