import { useOpointAuthState } from '@opoint/authjs-react'
import React from 'react'
import { Redirect } from 'react-router-dom'

import { getCurrentSearchObj } from '../helpers/location'

const Callback = () => {
  const auth = useOpointAuthState()
  const { redirect } = getCurrentSearchObj()

  return !!auth?.user ? <Redirect to={redirect ?? '/'} /> : <></>
}

export default Callback
