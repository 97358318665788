import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { getImpersonatorInfo, isImpersonating } from '../opoint/auth'

const initialState = {
  user: null,
  impersonator: null,
  loginError: false,
  usersLoginInfo: {},
  loadingImpersonation: false,
  loginByIp: true,
  gotServerTime: false,
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.OPEN_BASE_LOG_IN_FORM:
      return R.assoc('loginByIp', false, state)

    case Actions.USERS_LOGIN_INFO_SUCCESS:
      return R.assoc('usersLoginInfo', payload, state)

    case Actions.LOG_IN_SUCCESS:
      const impersonator = isImpersonating() ? getImpersonatorInfo() : null

      return R.evolve({ user: R.always(payload), impersonator: R.always(impersonator) })(state)

    case Actions.LOG_IN_ERROR:
      return R.assoc('loginError', true, state)

    case Actions.LOG_IN_ERROR_TIMEOUT:
      return R.assoc('loginError', false, state)

    case Actions.LOGOUT:
      return R.assoc('user', null, state)

    case Actions.SERVER_TIME_INITIALIZATION:
      return R.assoc('gotServerTime', true, state)

    case Actions.IMPERSONATE:
      return R.assoc('loadingImpersonation', true, state)

    case Actions.END_IMPERSONATION:
      return R.assoc('loadingImpersonation', true, state)

    case Actions.IMPERSONATE_SUCCESS: {
      const impersonator = isImpersonating() ? getImpersonatorInfo() : null

      return R.evolve({ user: R.always(payload), impersonator: R.always(impersonator) })(state)
    }

    case Actions.IMPERSONATE_ERROR:
      return R.assoc('loadingImpersonation', false, state)

    case Actions.FETCH_ARTICLES:
      return R.assoc('loadingImpersonation', false, state)

    case Actions.GO_TO_CUSTOMER_VIEW:
      return R.assoc('loadingImpersonation', false, state)

    default:
      return state
  }
}

export default authReducer
