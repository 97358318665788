import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types'

export const setupScope = ({ email, id, username }: Sentry.User): void =>
  Sentry.configureScope((scope) => {
    scope.setUser({ email, id, username })
  })

export const clearScope = (): void =>
  Sentry.configureScope((scope) => {
    scope.setUser(null)
  })

export const captureException = (error): void => {
  const exception = error.error || error.message || error.originalError || error

  Sentry.captureException(exception)
}

export const captureMessage = (
  message: string,
  context?: Sentry.Severity | CaptureContext | Sentry.SeverityLevel,
): void => {
  Sentry.captureMessage(message, context)
}

export const withScope = (info: unknown, callback: (value: Sentry.Scope) => void): void =>
  Sentry.withScope((scope) => {
    Object.keys(info).forEach((key) => {
      scope.setExtra(key, info[key])
    })
    callback(scope)
  })

export const captureWithScope = (info: unknown, error: Error): void => withScope(info, () => captureException(error))
