import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const searchErrorTypes: ErrorEpic = {
  [ActionTypes.FETCH_ARTICLES_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to fetch articles for this search'),
    sentryMessage: 'We were not able to fetch articles for this search',
    toastId: 'FETCH_ARTICLES_FAILURE',
  },
  [ActionTypes.SEARCH_CHANGE_DATERANGE_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to change date range'),
    sentryMessage: 'We were not able to change date range',
    toastId: 'SEARCH_CHANGE_DATERANGE_FAILURE',
  },
  [ActionTypes.FETCH_FILTER_DETAIL_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to fetch filter details'),
    sentryMessage: 'We were not able to fetch filter details',
    toastId: 'FETCH_FILTER_DETAIL_FAILURE',
  },
  [ActionTypes.UPDATED_SOME_META_DATA_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to update Social Media metadata posts'),
    sentryMessage: 'We were not able to update Social Media metadata posts',
    toastId: 'UPDATED_SOME_META_DATA_FAILURE',
  },
  [ActionTypes.UPDATED_SOME_META_DATA_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully updated Social Media metadata posts'),
    toastId: 'UPDATED_SOME_META_DATA_SUCCESS',
  },
}

const searchErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(searchErrorTypes, type))

export default searchErrorEpic
