import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'

export async function getTags() {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/analysis-tags/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
