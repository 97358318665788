import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const portalErrorTypes: ErrorEpic = {
  [ActionTypes.TOKEN_EXPIRED]: {
    isSuccess: false,
    message: t('Authentication token expired, please log in again.'),
    sentryMessage: 'Authentication token expired, please log in again.',
    toastId: 'TOKEN_EXPIRED',
  },
  [ActionTypes.SERVER_NOT_RESPONDING]: {
    isSuccess: false,
    message: t('We are sorry, server is not responding, please, try again later.'),
    sentryMessage: 'We are sorry, server is not responding, please, try again later.',
    toastId: 'SERVER_NOT_RESPONDING',
  },
}

const portalErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(portalErrorTypes, type))

export default portalErrorEpic
