import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const feedErrorTypes = (payload): ErrorEpic => {
  return {
    [ActionTypes.FEED_SAVE_SUCCESS]: {
      isSuccess: true,
      message: t('Feed successfully saved'),
      toastId: 'FEED_SAVE_SUCCESS',
    },
    [ActionTypes.FEED_NEW_SUCCESS]: {
      isSuccess: true,
      message: t('New feed successfully created'),
      toastId: 'FEED_NEW_SUCCESS',
    },
    [ActionTypes.FEED_SET_ACTIVE_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to select this feed'),
      sentryMessage: 'We were unable to select this feed',
      toastId: 'FEED_SET_ACTIVE_FAILURE',
    },
    [ActionTypes.FEED_SAVE_ERROR]: {
      isSuccess: false,
      message: t('We were unable to save this feed'),
      sentryMessage: 'We were unable to save this feed',
      toastId: 'FEED_SAVE_ERROR',
    },
    [ActionTypes.FEED_NEW_ERROR]: {
      isSuccess: false,
      message: t('We were not able to create new feed'),
      sentryMessage: 'We were not able to create new feed',
      toastId: 'FEED_NEW_ERROR',
    },
    [ActionTypes.FEED_DELETE_ERROR]: {
      isSuccess: false,
      message: t('We were unable to delete this feed'),
      sentryMessage: 'We were unable to delete this feed',
      toastId: 'FEED_DELETE_ERROR',
    },
    [ActionTypes.SAVE_FEED_VALID_FAILURE]: {
      isSuccess: false,
      message: payload?.error,
      sentryMessage: payload?.error,
      toastId: 'SAVE_FEED_VALID_FAILURE',
    },
  }
}

const feedErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type, payload }) => handlerError(feedErrorTypes, type, payload))

export default feedErrorEpic
