import { t } from '@transifex/native'

import config from '../../opoint/common/config'
import { ARTICLE_METADATA_CONFIG } from '../articles/Article/common/ArticleMetadata/ArticleMetadataBox'
import { ArticleMetadataType } from '../types/article'
import { FilterType, FilterItem } from '../types/settings'

export const LISTING_STYLES = {
  MODERN: 0,
  SEARCH_ENGINE: 1,
  EMAIL: 2,
  ARCHIVE_RIGHT: 3,
  ARCHIVE_LEFT: 4,
  INTRO: 5,
}

// TIme offset for relative times in seconds
// BEWARN - IF YOU MODIFY THIS, YOU ALSO NEED TO MODIFY STRING IN TRANSLATIONS.JS
export const TIME_OFFSET = {
  NEVER: {
    sec: 0,
    label: t('Never'),
  },
  LESS_THAN_15_MINUTES: {
    sec: 900,
    label: t('Age of article is less than 15 minutes'),
  },
  LESS_THAN_1_HOURS: {
    sec: 3600,
    label: t('Age of article is less than 1 hour'),
  },
  LESS_THAN_6_HOURS: {
    sec: 21600,
    label: t('Age of article is less than 6 hours'),
  },
  LESS_THAN_24_HOURS: {
    sec: 86400,
    label: t('Age of article is less than 24 hours'),
  },
  LESS_THAN_A_WEEK: {
    sec: 604800,
    label: t('Age of article is less than a week'),
  },
}

export const IMAGE_URL_BASE = {
  old: 'http://pictures.opoint.com/',
  new: config.url.app('/pictures/'),
}

export const allMetadataOptions = (): Array<{
  name: string
  value: ArticleMetadataType
}> =>
  Object.entries(ARTICLE_METADATA_CONFIG)?.map(([value, { title }]) => ({
    value: value as ArticleMetadataType,
    name: title(),
  }))

export const allFilterOptions = (isECBUser: boolean): FilterItem[] => {
  return [
    { value: 'timeperiod', name: t('Time Period') },
    { value: 'site', name: t('Site') },
    { value: 'rank', name: t('Global Rank') },
    { value: 'geo', name: t('Geo') },
    { value: 'lang', name: t('Lang') },
    { value: 'cov', name: t('Coverage') },
    { value: 'media', name: t('Media') },
    { value: 'content', name: t('Content') },
    { value: 'profiles', name: isECBUser ? t('My Briefcase') : t('Profiles') },
    { value: 'tags', name: isECBUser ? t('My Tags') : t('Tags') },
    { value: 'ent', name: t('Entities') },
    { value: 'topic', name: t('Topic') },
    { value: 'ecb_rank', name: t('ECB Rank') },
    { value: 'ecb_voice', name: t('ECB Voices') },
    { value: 'ecb_topic', name: t('ECB Topics') },
    { value: 'ecb_sentiment', name: t('ECB Sentiment') },
    { value: 'curated', name: t('Curated') },
    { value: 'non-curated', name: t('Non-Curated') },
    { value: 'social media', name: t('Social Media') },
    { value: 'front pages', name: t('Front Pages') },
  ]
}
export const FILTERS_ORDER_DEFAULT: Array<FilterType> = [
  'timeperiod',
  'geo',
  'lang',
  'media',
  'content',
  'site',
  'rank',
  'cov',
  'profiles',
  'tags',
  'ent',
  'topic',
]

export const autoTranslateLanguages = [
  { en: t('English') },
  { de: t('German') },
  { fr: t('French') },
  { es: t('Spanish') },
  { it: t('Italian') },
  { no: t('Norwegian') },
  { sv: t('Swedish') },
  { da: t('Danish') },
  { fi: t('Finnish') },
  { zh: t('Chinese') },
  { ru: t('Russian') },
  { tr: t('Turkish') },
  { ko: t('Korean') },
  { ar: t('Arabic') },
  { pt: t('Portuguese') },
  { el: t('Greek') },
  { ja: t('Japanese') },
  { vi: t('Vietnamese') },
  { id: t('Indonesian') },
  { pl: t('Polish') },
  { nl: t('Dutch') },
  { hi: t('Hindi') },
  { ro: t('Romanian') },
  { bs: t('Bosnian') },
  { fa: t('Persian') },
  { th: t('Thai') },
  { sq: t('Albanian') },
  { hr: t('Croatian') },
  { cs: t('Czech') },
  { hu: t('Hungarian') },
  { bg: t('Bulgarian') },
  { uk: t('Ukrainian') },
  { sk: t('Slovak') },
  { mk: t('Macedonian') },
  { sl: t('Slovene') },
  { bn: t('Bengali') },
  { te: t('Telugu') },
  { lt: t('Lithuanian') },
  { ms: t('Malay') },
  { ca: t('Catalan') },
  { ml: t('Malayalam') },
  { ta: t('Tamil') },
  { lv: t('Latvian') },
  { az: t('Azerbaijani') },
  { et: t('Estonian') },
  { pa: t('Punjabi') },
  { ur: t('Urdu') },
  { ka: t('Georgian') },
  { gu: t('Gujarati') },
  { kn: t('Kannada') },
  { am: t('Amharic') },
  { gl: t('Galician') },
  { hy: t('Armenian') },
  { sr: t('Serbian') },
  { is: t('Icelandic') },
  { kk: t('Kazakh') },
  { eu: t('Basque') },
  { af: t('Afrikaans') },
  { so: t('Somali') },
  { mt: t('Maltese') },
  { sw: t('Swahili') },
  { mr: t('Marathi') },
  { uz: t('Uzbek') },
  { si: t('Sinhalese') },
  { tg: t('Tajik') },
  { cy: t('Welsh') },
  { my: t('Burmese') },
  { km: t('Khmer') },
  { tl: t('Tagalog') },
  { fy: t('Western Frisian') },
  { sm: t('Samisk') },
  { eo: t('Esperanto') },
  { mn: t('Mongolian') },
  { ha: t('Hausa') },
  { ga: t('Irish') },
  { be: t('Belarusian') },
  { ny: t('Chichewa') },
  { ps: t('Pashto') },
  { st: t('Southern Sotho') },
  { ht: t('Haitian') },
  { ky: t('Kirghiz') },
  { lb: t('Luxembourgish') },
  { lo: t('Lao') },
]
