import R from 'ramda'
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { multipleTagsHandling } from '../helpers/common'
import { getCurrentSearchObj, getSearchObj } from '../helpers/location'
import { articleId } from '../opoint/articles'
import { LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT } from '../opoint/common/config'
import { IsoToUnixTimestamp } from '../opoint/common/time'
import type { ArticleMetadataType, SearchItem, Searchline } from '../opoint/flow'
import {
  getMultipleSuggestions,
  getSimpleSuggestions,
  getSuggestionDetail,
  parseTimeFilterToTimeStamps,
  search,
  getSingleArticle,
} from '../opoint/search'
import { paramsDictToUrl, chartAndNonChartFiltersFromURL } from '../opoint/search/url'
import { getStatisticViewData } from '../opoint/statistics/index'
import { getAlertTags } from '../selectors/alertsSelectors'
import { getArticlesCount, getIdenticalArticles } from '../selectors/articlesSelectors'
import { getIsECBUser, haveServerTime } from '../selectors/authSelectors'
import { getAllFolders } from '../selectors/foldersSelectors'
import {
  getProfileById,
  isPreviewOpened,
  getEditedProfile,
  hasPreviewArticles,
  getProfiles,
} from '../selectors/profilesSelectors'
import {
  getMainSearchLine,
  getMainSearchLineWithTimePeriod,
  getSearchActuallyLoadedDaterange,
  getSearchDatepicker,
  getSearchFilters,
  getSearchMeta,
  getSearchTimePeriod,
  getSearchterm,
  isSearchNotEmpty,
  getFiltersShowMore,
  getSelectedProfilesIds,
} from '../selectors/searchSelectors'
import {
  getArticleMetadata,
  getAutoTranslateSearchParams,
  getColorbarColors,
  getDefaultSearch,
  getGroupingEnabled,
  getOpointLocale,
  getFilterSuggest,
  getSearchSuggest,
  getEntitiesSelected,
  canShowEntitiesHightlight,
  getSuggestionLocale,
  getCommentGroups,
} from '../selectors/settingsSelectors'
import { getFilteredArticles } from '../selectors/statisticsSelectors'
import { getBaskets, getTagById, getTrashTagById } from '../selectors/tagsSelectors'
import { getFiltersPanelOpen } from '../selectors/uiSelectors'
import { history } from '../store'

import { logOutOnExpiredToken, serverIsDown, pushLocation } from './epicsHelper'

export function createSearchdParams(state) {
  const autoTranslate = getAutoTranslateSearchParams(state)
  const baskets = { baskets: getBaskets(state) }
  const colorParam = { different_colors: getColorbarColors(state) }
  const groupingEnabled = getGroupingEnabled(state)
  const timeFilter = getSearchTimePeriod(state)
  // @ts-ignore
  const timePeriod = timeFilter ? parseTimeFilterToTimeStamps(timeFilter.id) : {}
  // @ts-ignore
  const requestedMetadata: Array<ArticleMetadataType> = getArticleMetadata(state)
  const canShowEntitiesSelected = canShowEntitiesHightlight(state)
  const entitiesSelected = getEntitiesSelected(state)
  const commentGroups = getCommentGroups(state)
  const commentGroupIds = commentGroups?.map(({ id }) => id)

  // If it is search from show filtered articles in statistics, include articles in API call
  return {
    articles: state.statistics.showFilteredArticles ? getFilteredArticles(state) : undefined,
    groupidentical: groupingEnabled,
    identical: { inherit: groupingEnabled },
    ...autoTranslate,
    ...baskets,
    ...colorParam,
    ...timePeriod,
    readership: requestedMetadata.length > 0,
    ...(canShowEntitiesSelected && entitiesSelected === 1 && { textrazor: 8 }),
    allmeta: true,
    commentgroups: commentGroupIds,
  }
}

/**
 * Searchd returns 200 even if the search failed.
 * That's why we have this interceptor where we check whether an error occured and throw
 * an error manually.
 * @param response
 * @return Rx.Observable
 */
export function throwErrorOnSearchdFailure(response) {
  if (response.searchresult.errors) {
    return Rx.Observable.throw(response)
  }

  return Rx.Observable.of(response)
}

/**
 * If more than one tag is selected, run this function to convert the filters into a search query.
 *
 * The backend does not support multiple tags, in the form of a filter.
 * @param filters Array of filters
 * @returns {string} Returns a search query
 */
const convertToSearchQuery = (filters: any[]) => {
  const convertToQuery = filters
    ?.map((filter) => {
      return `${filter.type}:${filter.id}`
    })
    .join(' OR ')

  return convertToQuery
}

const searchEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.filter(({ type }) => [ActionTypes.FETCH_ARTICLES, ActionTypes.FETCH_MORE_ARTICLES].includes(type)),
    action$.ofType(ActionTypes.TRASH_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.ALERTS_FETCH_SUCCESS).take(1),
  )
    .debounceTime(500)
    // @ts-ignore
    .switchMap(([{ type, payload }]) => {
      const state = getState()
      const isPreview = isPreviewOpened(state)
      const hasPreviewArticlesInState = hasPreviewArticles(state)
      let searchline = getMainSearchLine(state)
      const editedProfile = getEditedProfile(state)
      const alertTags = getAlertTags(state)

      // Group profile filters
      const priority = ['profile']
      searchline.filters.sort((a, b) => {
        // @ts-ignore
        const firstPrio = priority.indexOf(a.type)
        // @ts-ignore
        const secondPrio = priority.indexOf(b.type)

        return secondPrio - firstPrio
      })

      if (
        (!isPreview || !hasPreviewArticlesInState) &&
        searchline.filters.length === 0 &&
        searchline.searchterm === ''
      ) {
        return Rx.Observable.of(buildAction(ActionTypes.SEARCH_IS_EMPTY))
      }

      // --- Multiple Tags Handling ---
      // @ts-ignore
      if (searchline.filters.filter((filter) => filter.type === 'tag').length > 1) {
        searchline = multipleTagsHandling(searchline)
      }
      // --- Multiple Tags Handling ---

      // --- Handle alert tag with a single and wrong basket
      // Sometimes the id of the tag and the basket, isn't the same, causing the editable tag to be unsearchable.
      // The code below handles that and puts in that single basket as filter.
      if (searchline.filters) {
        searchline.filters.forEach((filter: { id: string; type: string }) => {
          const alertTag = alertTags.find((tag) => tag.id === parseInt(filter.id))

          if (alertTag && alertTag.children.length === 1 && filter.id !== alertTag.children[0].id.toString()) {
            const alertTagBasket = alertTag.children?.map((child) => {
              return { id: `${child.id}`, type: 'tag' }
            })
            searchline.filters = [...alertTagBasket]
          }
        })
      }
      // --- Handle alert tag with a single and wrong basket

      const loadMore = type === ActionTypes.FETCH_MORE_ARTICLES
      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        // @ts-ignore
        searchline,
      }
      const { context } = getSearchMeta(state)
      const searchdParams = createSearchdParams(state)

      // if context is in a store
      if (loadMore) {
        // @ts-ignore
        searchdParams.context = context

        // we're supposed to load .5 more than is currently loaded, but with top value of 100 in order to provide fast response time
        const requestedarticles = R.clamp(20, 100, Math.round(getArticlesCount(state) * 0.5))

        return Rx.Observable.fromPromise(
          search(
            isPreview || hasPreviewArticlesInState ? editedProfile?.items : [requiredSearchItem],
            {
              ...searchdParams,
              requestedarticles,
            },
            {},
            getOpointLocale(state),
          ),
        )
          .switchMap(throwErrorOnSearchdFailure)
          ?.map((response) =>
            isPreview || hasPreviewArticlesInState
              ? buildAction(ActionTypes.FETCH_MORE_PREVIEW_ARTICLES_SUCCESS, { response })
              : buildAction(ActionTypes.FETCH_MORE_ARTICLES_SUCCESS, { response }),
          )
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_MORE_ARTICLES_FAILURE)))
      }

      const requestedarticles = payload?.articleCount

      const search$ = Rx.Observable.fromPromise(
        search(
          [requiredSearchItem],
          {
            ...searchdParams,
            ...(requestedarticles ? { requestedarticles } : {}),
          },
          {},
          getOpointLocale(state),
        ),
      )
        .switchMap(throwErrorOnSearchdFailure)
        .switchMap((response) =>
          Rx.Observable.of(
            buildAction(ActionTypes.FETCH_ARTICLES_SUCCESS, { response, searchItem: requiredSearchItem }),
            buildAction(ActionTypes.SCROLL_TO_TOP),
            buildAction(ActionTypes.RESET_WATCH_INDEX_FOR_PROFILE, { watchId: response.searchresult.watch_id }),
          ),
        )
        // for filtered articles & returning to statistics
        .takeUntil(action$.ofType(ActionTypes.FETCH_STATISTICS))
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES_FAILURE)))

      const searchTakingTooLong$ = Rx.Observable.of(buildAction(ActionTypes.SEARCH_IS_TAKING_TOO_LONG))
        .delay(10000) // After 10 seconds, show search is taking too long message
        .takeUntil(search$)
        // for filtered articles & returning to statistics
        .takeUntil(action$.ofType(ActionTypes.FETCH_STATISTICS))

      return (
        search$
          .merge(searchTakingTooLong$)
          .takeUntil(action$.ofType(ActionTypes.SEARCH)) // cancel if a new search comes
          // for filtered articles & returning to statistics
          .takeUntil(action$.ofType(ActionTypes.FETCH_STATISTICS))
          .takeUntil(action$.ofType(ActionTypes.CANCEL_SEARCH))
      )
    })

export const fetchSingleArticle = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FETCH_SINGLE_ARTICLE),
    action$.ofType(ActionTypes.TRASH_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.ALERTS_FETCH_SUCCESS).take(1),
  )
    .delay(50)
    .switchMap(
      ([
        {
          // @ts-ignore
          payload: {
            originalArticle,
            activeArticle,
            translate = false,
            forArticleView = false,
            clearSearchterm = false,
          },
        },
      ]) => {
        const { id_site, id_article, id_profile } = activeArticle
        const state = getState()
        const autoTranslate: any = getAutoTranslateSearchParams(state)
        const baskets = { baskets: getBaskets(state) }
        const canShowEntitiesSelected = canShowEntitiesHightlight(state)
        const entitiesSelected = getEntitiesSelected(state)
        const identicalArticles = getIdenticalArticles(state)
        const searchline = getMainSearchLine(state)
        const isECBUser = getIsECBUser(state)

        if (translate) {
          autoTranslate.max_gt_article_length = 100000
        }

        const searchdParams = {
          ...baskets,
          ...autoTranslate,
          requestedarticles: 1,
          translate_type: translate ? 3 : 0,
          ...(canShowEntitiesSelected && entitiesSelected === 1 && { textrazor: 8 }),
          readership: true,
        }

        const selectedProfiles = getSelectedProfilesIds(state)

        const isIdentical =
          originalArticle && Object.keys(identicalArticles).some((item) => item === articleId(originalArticle))

        const filters: { id: string; type: string }[] = []
        if (!!id_site && !!id_article) {
          filters.push({ id: `${id_site}_${id_article}`, type: 'id' })
        }

        if (!!id_profile) {
          filters.push({ id: id_profile, type: 'profile' })
        } else {
          selectedProfiles.forEach((id) => {
            filters.push({ id: id.toString(), type: 'profile' })
          })
        }

        // In cases where the article id format is different, we're interested in overwriting the searchterm (if below conditions is true).
        // Otherwise, we're unable to translate the article.
        if (isECBUser && searchline.searchterm.includes('article:')) {
          searchline.searchterm = `id:${filters[0].id}`
        }

        // Article view variables
        const requiredSearchItem: SearchItem = {
          linemode: 'R',
          searchline: {
            searchterm: clearSearchterm ? '' : !!searchline.searchterm ? searchline.searchterm : '',
            filters,
          },
        }

        return Rx.Observable.fromPromise(getSingleArticle(searchdParams, getOpointLocale(state), [requiredSearchItem]))
          .switchMap(throwErrorOnSearchdFailure)
          ?.map((response) => {
            return forArticleView
              ? buildAction(ActionTypes.FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS, {
                  response,
                })
              : buildAction(ActionTypes.FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS, {
                  response,
                  translate,
                  isIdentical,
                  originalArticle,
                })
          })
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_SINGLE_ARTICLE_FAILURE)))
      },
    )

export const fetchArticlesWithWatchId = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID).switchMap(({ payload: { watchId, count } }) => {
    const state = getState()
    const baskets = { baskets: getBaskets(state) }
    const autoTranslate = getAutoTranslateSearchParams(state)
    const searchdParams = {
      ...baskets,
      ...autoTranslate,
      requestedarticles: count,
    }

    const searchline: Searchline = {
      filters: [{ id: watchId, type: 'watch' }],
    }
    const requiredSearchItem: SearchItem = {
      linemode: 'R',
      searchline,
    }

    const { pathname, search: qs } = history.location
    const query = getSearchObj(qs)
    // @ts-ignore
    query.time = Date.now()

    if (count > LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT) {
      return Rx.Observable.concat(
        // @ts-ignore
        pushLocation(`${pathname}?${paramsDictToUrl(query)}`),
        Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID_CANCEL, { watchId })),
        // scroll to the top Observable
      )
    }

    return Rx.Observable.concat(
      Rx.Observable.fromPromise(search([requiredSearchItem], searchdParams, {}, getOpointLocale(state)))
        .switchMap((response) =>
          Rx.Observable.of(
            buildAction(ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS, response),
            buildAction(ActionTypes.SCROLL_TO_TOP),
          ),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID_FAILURE))),
      // scroll to the top Observable
    )
  })

/**
 * This epic handles change of data range for which search should return results.
 * Changed in toolbar.
 * Epic adds filter to search line, fetch new articles and close modal.
 */
const changeSearchDateRangeEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_CHANGE_DATERANGE).switchMap(({ payload: { startDate, endDate } }) => {
    const state = getState()
    const id = `${IsoToUnixTimestamp(startDate)}-${IsoToUnixTimestamp(endDate)}`

    const suggestionLocale = getSuggestionLocale(state)

    const timePeriod$ = Rx.Observable.fromPromise(getSuggestionDetail(id, 'timePeriod', suggestionLocale))

    const createActionSequence = (data) => {
      const initialActions = [Rx.Observable.of(buildAction(ActionTypes.SEARCHFILTER_ADDED, data[0]))]
      // this action doesn't have to be called from datepicker only and also from statistics
      const datePickerCloser = (actions) =>
        getSearchDatepicker(state)
          ? R.append(Rx.Observable.of(buildAction(ActionTypes.DATEPICKER_MODAL_CLOSE, actions)), actions)
          : actions

      return R.pipe(datePickerCloser)(initialActions)
    }

    return timePeriod$
      .switchMap((data) => Rx.Observable.concat(...createActionSequence(data)))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.SEARCH_CHANGE_DATERANGE_FAILURE)))
  })

/**
 * This epic sets date range for which search should return results.
 * Range from lastTimestamp to firstTimestamp is used.
 * Changed in toolbar.
 * Epic adds filter to search line.
 */
const changeSearchDateRangeToCurrentEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_CHANGE_DATERANGE_TO_CURRENT).switchMap(() => {
    const state = getState()
    const { startDate, endDate } = getSearchActuallyLoadedDaterange(state)

    const id = `${IsoToUnixTimestamp(startDate)}-${IsoToUnixTimestamp(endDate)}`

    const suggestionLocale = getSuggestionLocale(state)

    const timePeriod$ = Rx.Observable.fromPromise(getSuggestionDetail(id, 'timePeriod', suggestionLocale))

    return timePeriod$
      .switchMap((data) => Rx.Observable.of(buildAction(ActionTypes.SEARCHFILTER_ADDED, data[0])))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.SEARCH_CHANGE_DATERANGE_FAILURE)))
  })

const dateRangeChangedEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DATEPICKER_MODAL_CLOSE).switchMap(() => {
    const state = getState()
    const searchData = {
      searchline: getMainSearchLineWithTimePeriod(state),
      pathname: history.location.pathname,
    }

    return Rx.Observable.of(searchData)
      ?.map((data) => buildAction(ActionTypes.SEARCH, data))
      .catch(() => Rx.Observable.of())
  })

/**
 * Epic to fetch general filter details - ignoring profiles, tags and trash tags
 */
const fetchFilterDetailEpic = (action$: any, { getState }: any) => {
  const ACTIONS_TO_PROCESS = ['profile', '-profile', 'tag', '-tag', 'trash']

  return Rx.Observable.combineLatest(
    action$
      .ofType(ActionTypes.FETCH_FILTER_DETAIL)
      .filter((action) => !ACTIONS_TO_PROCESS.includes(action.payload.type)),
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
  ).concatMap(([action, settings]) => {
    const {
      // @ts-ignore
      payload: { id, type },
    } = action

    if (type === 'list') {
      return Rx.Observable.of([{ id, type, name: `${type}:${id}` }])
    }

    const state = getState()
    const suggestionLocale = getSuggestionLocale(state)

    return Rx.Observable.fromPromise(getSuggestionDetail(id, type, suggestionLocale))
      ?.map((response) => buildAction(ActionTypes.FETCH_FILTER_DETAIL_SUCCESS, response))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_FILTER_DETAIL_FAILURE)))
  })
}

/**
 * Epic to fetch profile filter detail
 */
const fetchProfileFilterDetailEpic = (action$: any, { getState }: any) => {
  const ACTIONS_TO_PROCESS = ['profile', '-profile']

  return Rx.Observable.combineLatest(
    action$
      .ofType(ActionTypes.FETCH_FILTER_DETAIL)
      .filter((action) => ACTIONS_TO_PROCESS.includes(action.payload.type)),
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS),
  ).switchMap(([action, profiles]) => {
    const {
      // @ts-ignore
      payload: { id, type },
    } = action
    const profile = getProfileById(parseInt(id, 10))(getState())

    return profile
      ? Rx.Observable.of([{ id: profile.id, type, name: profile.name }])?.map((response) =>
          buildAction(ActionTypes.FETCH_FILTER_DETAIL_SUCCESS, response),
        )
      : Rx.Observable.of(buildAction(ActionTypes.FETCH_FILTER_DETAIL_UNKNOWN, { id, type }))
  })
}

/**
 * Epic to fetch tag filter detail
 */
const fetchTagFilterDetailEpic = (action$: any, { getState }: any) => {
  const ACTIONS_TO_PROCESS = ['tag', '-tag']

  return Rx.Observable.combineLatest(
    action$
      .ofType(ActionTypes.FETCH_FILTER_DETAIL)
      .filter((action) => ACTIONS_TO_PROCESS.includes(action.payload.type)),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.ALERTS_FETCH_SUCCESS).take(1),
    // @ts-ignore
  ).switchMap(([action, tags, alerts]) => {
    const {
      // @ts-ignore
      payload: { id, type },
    } = action
    const tag = getTagById(parseInt(id, 10))(getState())

    return tag
      ? Rx.Observable.of([{ id: tag.id, type, name: tag.name || tag.subject }])?.map((response) =>
          buildAction(ActionTypes.FETCH_FILTER_DETAIL_SUCCESS, response),
        )
      : Rx.Observable.of()
  })
}

/**
 * Epic to fetch trash filter detail
 */
const fetchTrashFilterDetailEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FETCH_FILTER_DETAIL).filter((action) => action.payload.type === 'trash'),
    action$.ofType(ActionTypes.TRASH_FETCH_SUCCESS).take(1),
  ).switchMap(([action, trashTags]) => {
    const {
      // @ts-ignore
      payload: { id, type },
    } = action
    const trash = getTrashTagById(parseInt(id, 10))(getState())

    return trash
      ? Rx.Observable.of([{ id: trash.id, type, name: trash.name }])?.map((response) =>
          buildAction(ActionTypes.FETCH_FILTER_DETAIL_SUCCESS, response),
        )
      : Rx.Observable.of()
  })

/**
 * Epic to remove unknown filter from search line (eg. after login of different user)
 */
// const removeUnknownFilterEpic = (action$: any, { getState }: any) =>
//   action$.ofType(ActionTypes.FETCH_FILTER_DETAIL_UNKNOWN).switchMap((action) => {
//     const profile = action.payload
//     return Rx.Observable.of(buildAction(ActionTypes.SEARCHFILTER_REMOVED, profile))
//   })

/**
 * Fetch new multiple suggestions if we open suggestions filter.
 * This is needed so that we show some filters once it's open.
 * TODO we should also prefetch it when we're not busy to improve user experience.
 */
const onFiltersPanelToggle = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FILTERS_PANEL_TOGGLE).switchMap(({ payload }) => {
    const state = getState()
    const isOpen = getFiltersPanelOpen(state)

    if (isOpen) {
      const { searchterm, refreshOnly } = payload
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getFilterSuggest(state)

      return Rx.Observable.fromPromise(
        getMultipleSuggestions(
          searchterm,
          searchFilters,
          { suggestionLocale },
          defaultSearchScope,
          undefined,
          filterSuggestParameter,
        ),
      )
        .switchMap((response: any) => {
          if (refreshOnly) {
            const {
              site: { typeName: header, action },
            } = response

            return Rx.Observable.merge(
              Rx.Observable.of(buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS, response)),
              Rx.Observable.of(
                buildAction(ActionTypes.FETCH_MORE_FILTERS, {
                  header,
                  action,
                  clearDrilldown: true,
                }),
              ),
            )
          }

          return Rx.Observable.of(buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS, response))
        })
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of())
    }

    return Rx.Observable.empty()
  })

const onFiltersFetch = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FILTERS_FETCH).switchMap(({ payload }) => {
    const state = getState()

    const searchFilters = getSearchFilters(state)
    const suggestionLocale = getSuggestionLocale(state)
    const defaultSearchScope = getDefaultSearch(state)
    const filterSuggestParameter = getFilterSuggest(state)

    return Rx.Observable.fromPromise(
      getMultipleSuggestions(
        '',
        searchFilters,
        { suggestionLocale },
        defaultSearchScope,
        undefined,
        filterSuggestParameter,
      ),
    )
      ?.map((response) => buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS, response))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of())
  })

const onSearchDataChange = (action$: any, { getState }: any) =>
  action$
    .ofType(
      ActionTypes.SEARCHTERM_CHANGED,
      ActionTypes.SEARCHFILTER_REMOVED,
      ActionTypes.REFRESH_FILTERS_NAME,
      ActionTypes.SEARCHDATA_CLEAR,
    )
    .switchMap(({ payload }) => {
      const state = getState()
      const isOpen = getFiltersPanelOpen(state)
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const filterSuggestParameter = getFilterSuggest(state)
      const searchSuggestParameter = getSearchSuggest(state)

      const defaultSearchScope = getDefaultSearch(state)

      if (isOpen) {
        return Rx.Observable.fromPromise(
          getMultipleSuggestions(
            payload?.searchterm || getSearchterm(state),
            searchFilters,
            { suggestionLocale },
            defaultSearchScope,
            undefined,
            filterSuggestParameter,
          ),
        )
          ?.map((response) => buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS, response))
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES_FAILURE)))
      }

      return Rx.Observable.fromPromise(
        getSimpleSuggestions(
          payload?.searchterm || getSearchterm(state),
          searchFilters,
          { suggestionLocale },
          defaultSearchScope,
          undefined,
          searchSuggestParameter,
        ),
      )
        ?.map(({ results }: any) => buildAction(ActionTypes.FILTERS_FETCH_SUCCESS, results))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES_FAILURE)))
    })

const onFiltersFetchMore = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FETCH_MORE_FILTERS).switchMap(({ payload }) => {
    const state = getState()
    const isOpen = getFiltersPanelOpen(state)

    if (isOpen) {
      const { action, header, width } = payload
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const searchterm = getSearchterm(state)
      const filtersShowMore = getFiltersShowMore(state)
      const isSingleColumn = action < 0
      let responseCount = 10
      if (isSingleColumn) {
        switch (width) {
          case 2:
            responseCount = 60
            break
          case 3:
            responseCount = 40
            break
          default:
            responseCount = 140
        }
      }

      return Rx.Observable.fromPromise(
        getMultipleSuggestions(
          searchterm,
          searchFilters,
          { suggestionLocale },
          defaultSearchScope,
          responseCount,
          action,
        ),
      )
        ?.map((response) =>
          buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS, [
            ...(payload.clearDrilldown ? [] : filtersShowMore),
            {
              // @ts-ignore
              ...response,
              header,
              isSingleColumn,
              action,
            },
          ]),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of())
    }

    return Rx.Observable.empty()
  })

const onSearchDataChangeFiltersMore = (action$: any, { getState }: any) =>
  action$
    .ofType(
      ActionTypes.SEARCHTERM_CHANGED,
      ActionTypes.SEARCHFILTER_REMOVED,
      ActionTypes.FILTERS_POP,
      ActionTypes.REFRESH_FILTERS_NAME,
    )
    .switchMap(({ payload }) => {
      const state = getState()
      const isOpen = getFiltersPanelOpen(state)
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const filtersShowMore = getFiltersShowMore(state)
      const lastFilter = filtersShowMore.slice(-1)[0]

      const defaultSearchScope = getDefaultSearch(state)

      if (isOpen && lastFilter?.action) {
        const { action, header, width } = lastFilter
        const isSingleColumn = action < 0
        let responseCount = 10
        if (isSingleColumn) {
          switch (width) {
            case 2:
              responseCount = 60
              break
            case 3:
              responseCount = 40
              break
            default:
              responseCount = 140
          }
        }

        return Rx.Observable.fromPromise(
          getMultipleSuggestions(
            payload?.searchterm || getSearchterm(state),
            searchFilters,
            { suggestionLocale },
            defaultSearchScope,
            responseCount,
            action,
          ),
        )
          ?.map((response) =>
            buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS, [
              ...filtersShowMore.slice(0, filtersShowMore.length - 1),
              {
                // @ts-ignore
                ...response,
                header,
                isSingleColumn,
                action,
              },
            ]),
          )
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch(() => Rx.Observable.of())
      }

      return Rx.Observable.empty()
    })

const onSearchFiltersChange = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.SEARCHFILTER_REMOVED, ActionTypes.SEARCHFILTER_TOGGLED, ActionTypes.INVERT_FILTER)
    .switchMap(() => {
      const state = getState()
      const searchData = {
        searchline: getMainSearchLineWithTimePeriod(state),
        pathname: history.location.pathname,
      }

      return Rx.Observable.of(searchData)
        ?.map((data) => buildAction(ActionTypes.SEARCH, data))
        .catch(() => Rx.Observable.of())
    })

const onSearchFiltersSet = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCHFILTERS_SET).switchMap(({ payload: { filters } }) => {
    const searchData = {
      searchline: {
        searchterm: '',
        filters,
      },
      pathname: history.location.pathname,
    }

    return Rx.Observable.of(searchData)
      ?.map((data) => buildAction(ActionTypes.SEARCH, data))
      .catch(() => Rx.Observable.of())
  })

const updateSearchtermEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.UPDATE_SEARCHTERM)
    .switchMap(({ payload: { searchterm } }) =>
      Rx.Observable.of(buildAction(ActionTypes.UPDATE_SEARCHTERM_SUCCESS, { searchterm })),
    )

const onFilterToggle = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.SEARCHFILTER_TOGGLED)
    .debounceTime(100)
    .switchMap(({ payload }) => {
      const state = getState()
      const searchterm = getSearchterm(state)
      const searchFilters = getSearchFilters(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getFilterSuggest(state)

      return Rx.Observable.fromPromise(
        getMultipleSuggestions(
          searchterm,
          searchFilters,
          { suggestionLocale },
          defaultSearchScope,
          undefined,
          filterSuggestParameter,
        ),
      )
        ?.map((response) => buildAction(ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS, response))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES_FAILURE)))
    })

const onLocationChangeEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_DATA_INIT, ActionTypes.ROUTER_LOCATION_CHANGE).switchMap(() => {
    const state = getState()
    const { expression, filters } = getCurrentSearchObj()
    const { nonChartFilters, chartFilter } = chartAndNonChartFiltersFromURL(filters)
    // If there is only a chart - set it active in stat view
    if (chartFilter && nonChartFilters.length < 1) {
      return Rx.Observable.concat(
        // @ts-ignore
        Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEWS_SET_ACTIVE, { id: parseInt(chartFilter.id, 10) })),
        // @ts-ignore
        Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEWS_OPEN, { id: parseInt(chartFilter.id, 10) })),
        Rx.Observable.of(buildAction(ActionTypes.STATISTICS_FILTER_RESET_ALL)),
      ).delay(haveServerTime(state) ? 1 : 1000)
    }

    return chartFilter
      ? Rx.Observable.fromPromise(getStatisticViewData(chartFilter.id)) // If there is a chart, we want to transform it
          .switchMap(({ dashboard: { search } }) =>
            Rx.Observable.concat(
              Rx.Observable.of(
                buildAction(ActionTypes.ROUTER_SEARCH_DATA_CHANGE, {
                  // In case chart has expression - pick it
                  // In case we manualy do search - there will no chart filret
                  //  but regular filter(s) which belonged to chart
                  // so it will be just an expession so we pick it
                  expression: search.expression || expression,
                  // Chart filter transforms to regular filters which belonged to it
                  parsedFilters: [...search.filters, ...nonChartFilters],
                }),
              ),
              Rx.Observable.of(buildAction(ActionTypes.STATISTICS_FILTER_RESET_ALL)),
            ),
          )
      : Rx.Observable.concat(
          Rx.Observable.of(
            buildAction(ActionTypes.ROUTER_SEARCH_DATA_CHANGE, { expression, parsedFilters: nonChartFilters }),
          ),
          Rx.Observable.of(buildAction(ActionTypes.RESET_LAST_USED_TAG_ID)),
          Rx.Observable.of(buildAction(ActionTypes.STATISTICS_FILTER_RESET_ALL)),
        )
  })

const loadFrontpages = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.LOAD_FRONTPAGES).switchMap(({ payload }) => {
    const state = getState()
    const frontpageFolder = getAllFolders(state).find((folder) => folder?.traits === 4)
    const frontpageProfile = getProfiles(state).find((profile) => profile.folder === frontpageFolder?.id)
    const searchline = getMainSearchLineWithTimePeriod(state)

    if (!frontpageProfile) {
      return Rx.Observable.empty()
    }

    let parsedFilters = [{ id: frontpageProfile?.id, type: 'profile' }]

    if (payload && payload.timePeriod) {
      parsedFilters = [...parsedFilters, payload.timePeriod]
    }

    searchline.filters = parsedFilters

    const searchData = {
      searchline,
      pathname: history.location.pathname,
    }

    return Rx.Observable.merge(
      Rx.Observable.of(buildAction(ActionTypes.SEARCH, searchData)),
      Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES, { articleCount: 100 })),
    )
  })

const onSearchToggleTranslateAuto = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_TOGGLE_TRANSLATE_AUTO).switchMap(() => {
    const translateAuto = !getState().settings.list.TRANSLATE_AUTO

    return Rx.Observable.concat(
      Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings: { TRANSLATE_AUTO: translateAuto } })),
    )
  })

const refetchArticlesAfterSettingsChange = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.SETTINGS_SAVE_SUCCESS)
    // only refetch articles when it makes sense
    .switchMap(({ payload }) => {
      const state = getState()
      const searchIsEmpty = isSearchNotEmpty(state)
      const pathname = history?.location?.pathname
      const fetchType = pathname.includes('statistics') ? ActionTypes.FETCH_STATISTICS : ActionTypes.FETCH_ARTICLES

      return searchIsEmpty && !payload.toggleSetting
        ? Rx.Observable.merge(
            Rx.Observable.of(buildAction(ActionTypes.REFRESH_FILTERS_NAME)),
            Rx.Observable.of(buildAction(fetchType)),
          )
        : Rx.Observable.of()
    })

const searchFetch = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_FETCH).switchMap(() => {
    const state = getState()
    const searchData = {
      searchline: getMainSearchLineWithTimePeriod(state),
      pathname: history.location.pathname,
    }

    return Rx.Observable.of(searchData)
      ?.map((data) => buildAction(ActionTypes.SEARCH, data))
      .catch(() => Rx.Observable.of())
  })

export default [
  changeSearchDateRangeEpic,
  changeSearchDateRangeToCurrentEpic,
  dateRangeChangedEpic,
  fetchArticlesWithWatchId,
  fetchFilterDetailEpic,
  fetchProfileFilterDetailEpic,
  fetchTagFilterDetailEpic,
  fetchTrashFilterDetailEpic,
  onFiltersFetchMore,
  onFiltersPanelToggle,
  onFilterToggle,
  onLocationChangeEpic,
  onSearchDataChange,
  onSearchDataChangeFiltersMore,
  onSearchToggleTranslateAuto,
  refetchArticlesAfterSettingsChange,
  // removeUnknownFilterEpic,
  searchEpic,
  searchFetch,
  updateSearchtermEpic,
  onSearchFiltersChange,
  fetchSingleArticle,
  loadFrontpages,
  onFiltersFetch,
  onSearchFiltersSet,
]
