import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'
import { cannotDeleteEntityReason, cannotDeleteEntityReasonSentry } from '../../../opoint/common'

import { ErrorEpic, handlerError } from '.'

const profileEditorErrorTypes = (payload): ErrorEpic => {
  const data = payload || {}

  return {
    [ActionTypes.SAVE_AS_PROFILE_SUCCESS]: {
      isSuccess: true,
      message: t('Profile was successfully saved'),
      toastId: 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
    },
    [ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS]: {
      isSuccess: true,
      message: t('Profile was successfully saved'),
      toastId: 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
    },
    [ActionTypes.PROFILE_DELETE_SUCCESS]: {
      isSuccess: true,
      message: t('Profile was successfully deleted'),
      toastId: 'PROFILE_DELETE_SUCCESS',
    },
    [ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to save this profile'),
      sentryMessage: 'We were unable to save this profile',
      toastId: 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE',
    },
    [ActionTypes.LOAD_EDITED_PROFILE_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to load this profile'),
      sentryMessage: 'We were unable to load this profile',
      toastId: 'LOAD_EDITED_PROFILE_FAILURE',
    },
    [ActionTypes.PROFILE_DELETE_FAILURE]: {
      isSuccess: false,
      message: cannotDeleteEntityReason(data),
      sentryMessage: cannotDeleteEntityReasonSentry(data),
      toastId: 'PROFILE_DELETE_FAILURE',
    },
    [ActionTypes.PROFILE_HISTORY_FETCH_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch this profile history'),
      sentryMessage: 'We were unable to fetch this profile history',
      toastId: 'PROFILE_HISTORY_FETCH_FAILURE',
    },
    [ActionTypes.DELETED_PROFILES_HISTORY_FETCH_FAILURE]: {
      isSuccess: false,
      message: t('We were unable to fetch deleted profiles history'),
      sentryMessage: 'We were unable to fetch deleted profiles history',
      toastId: 'DELETED_PROFILES_HISTORY_FETCH_FAILURE',
    },
    [ActionTypes.PROFILE_EDITOR_INVALID_SEARCHLINE]: {
      isSuccess: false,
      message: t('We were unable to save this profile - search line is in wrong format'),
      sentryMessage: 'We were unable to save this profile - search line is in wrong format',
      toastId: 'PROFILE_EDITOR_INVALID_SEARCHLINE',
    },
  }
}

const profileEditorErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type, payload }) => handlerError(profileEditorErrorTypes, type, payload))

export default profileEditorErrorEpic
