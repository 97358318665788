import { captureMessage } from '../../helpers/sentry'
import { error, success } from '../../new-components/common/Toasts'

export type ToastProps =
  | {
      isSuccess: false
      message: string
      sentryMessage: string
      toastId: string
    }
  | {
      isSuccess: true
      message: string
      sentryMessage?: null
      toastId: string
    }

const handleToastNotification = ({ message, sentryMessage, toastId, isSuccess }: ToastProps): void => {
  if (isSuccess) {
    success(message, { toastId })
  } else {
    error(message, { toastId })
    captureMessage(`Toast error: ${sentryMessage}`, { level: 'info' })
  }
}

export default handleToastNotification
