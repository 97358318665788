// @ts-nocheck
import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { indexArray } from '../opoint/common'
import { toggleTagVisibility } from '../opoint/tags'

export const initialState = {
  list: [],
  lastUsedTagId: null,
  folderId: null,
}

const tagsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.TAGS_FETCH_SUCCESS: {
      return R.assoc('list', indexArray(payload), state)
    }

    case Actions.TAG_VISIBILITY_TOGGLE: {
      const { tagId } = payload
      const lens = R.lensPath(['list', R.findIndex(R.propEq('id', tagId), state.list)])

      return R.over(lens, toggleTagVisibility, state)
    }

    // TODO write tests for this
    case Actions.SET_LAST_USED_TAG_ID:
    case Actions.TAG_SINGLE_ARTICLE:
    case Actions.TAG_ARTICLES: {
      const {
        tag: { id },
      } = payload

      return R.assoc('lastUsedTagId', id, state)
    }

    case Actions.RESET_LAST_USED_TAG_ID: {
      return R.assoc('lastUsedTagId', null, state)
    }

    case Actions.EDIT_TAG_SUCCESS: {
      const { tag } = payload

      return R.evolve({
        list: R.converge(R.update, [R.findIndex(R.propEq('id', tag.id)), R.always(tag), R.identity]),
      })(state)
    }

    case Actions.ADD_TAG_SUCCESS: {
      const { tag } = payload

      return R.evolve(
        {
          list: R.compose(indexArray, R.append(tag)),
        },
        state,
      )
    }

    case Actions.DELETE_TAG_SUCCESS: {
      const { tagId } = payload

      return R.evolve({
        list: R.compose(
          indexArray,
          R.converge(R.remove, [R.findIndex(R.propEq('id', tagId)), R.always(1), R.identity]),
        ),
      })(state)
    }

    case Actions.TAGS_REORDER: {
      const { newIndex, oldIndex } = payload

      const list = [...state.list]
      const oldElement = list[oldIndex]

      list.splice(oldIndex, 1)
      list.splice(newIndex, 0, oldElement)

      return R.evolve({
        list: R.always(indexArray(list)),
      })(state)
    }

    case Actions.ADD_TAG_FOLDER_ID: {
      const { folderId } = payload

      return R.assoc('folderId', folderId, state)
    }

    default:
      return state
  }
}

export default tagsReducer
