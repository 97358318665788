import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'
import { cannotDeleteEntityReason, cannotDeleteEntityReasonSentry } from '../../../opoint/common'

import { ErrorEpic, handlerError } from '.'

const tagErrorTypes = (payload): ErrorEpic => {
  const data = payload || {}

  return {
    [ActionTypes.ADD_TAG_SUCCESS]: {
      isSuccess: true,
      message: t('We successfully added new tag'),
      toastId: 'ADD_TAG_SUCCESS',
    },
    [ActionTypes.DELETE_TAG_SUCCESS]: {
      isSuccess: true,
      message: t('We successfully deleted selected tag'),
      toastId: 'DELETE_TAG_SUCCESS',
    },
    [ActionTypes.TAG_ARTICLES_FAILURE]: {
      isSuccess: false,
      message: t('We were not able to tag this article'),
      sentryMessage: 'We were not able to tag this article',
      toastId: 'TAG_ARTICLES_FAILURE',
    },
    [ActionTypes.UNTAG_ARTICLES_FAILURE]: {
      isSuccess: false,
      message: t('We were not able to untag this article'),
      sentryMessage: 'We were not able to untag this article',
      toastId: 'UNTAG_ARTICLES_FAILURE',
    },
    [ActionTypes.DELETE_ARTICLES_FAILURE]: {
      isSuccess: false,
      message: t('We were not able to delete this article'),
      sentryMessage: 'We were not able to delete this article',
      toastId: 'DELETE_ARTICLES_FAILURE',
    },
    [ActionTypes.UNDELETE_ARTICLES_FAILURE]: {
      isSuccess: false,
      message: t('We were not able to restore this article'),
      sentryMessage: 'We were not able to restore this article',
      toastId: 'UNDELETE_ARTICLES_FAILURE',
    },
    [ActionTypes.ADD_TAG_FAILURE]: {
      isSuccess: false,
      message: t('We were not able to add this tag'),
      sentryMessage: 'We were not able to add this tag',
      toastId: 'ADD_TAG_FAILURE',
    },
    [ActionTypes.EDIT_TAG_FAILURE]: {
      isSuccess: false,
      message: t('We were not able to edit this tag'),
      sentryMessage: 'We were not able to edit this tag',
      toastId: 'EDIT_TAG_FAILURE',
    },
    [ActionTypes.DELETE_TAG_FAILURE]: {
      isSuccess: false,
      message: cannotDeleteEntityReason(data),
      sentryMessage: cannotDeleteEntityReasonSentry(data),
      toastId: 'DELETE_TAG_FAILURE',
    },
  }
}

const tagErrorEpics: (action) => void = (action$) =>
  action$.switchMap(({ type, payload }) => handlerError(tagErrorTypes, type, payload))

export default tagErrorEpics
