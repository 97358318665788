export const isMobileDevice = () => {
  return (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}

// Check if the user is using a true iOS device. This will exclude the responsive design view in any browser, but include iOS emulators
export const isIOSDevice = () => {
  return (
    (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) &&
    (navigator.platform?.match(/iPhone/i) ||
      navigator.platform?.match(/iPad/i) || //@ts-ignore
      navigator.userAgentData?.platform?.match(/iPhone/i) || //@ts-ignore
      navigator.userAgentData?.platform?.match(/iPad/i))
  )
}
