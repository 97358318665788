import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const commentErrorTypes: ErrorEpic = {
  [ActionTypes.ADD_ARTICLE_COMMENT_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully added a new comment'),
    toastId: 'ADD_ARTICLE_COMMENT_SUCCESS',
  },
  [ActionTypes.ADD_ARTICLE_COMMENT_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to add a comment on this article'),
    sentryMessage: 'We were not able to add a comment on this article',
    toastId: 'ADD_ARTICLE_COMMENT_FAILURE',
  },
  [ActionTypes.DELETE_ARTICLE_COMMENT_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully deleted a comment'),
    toastId: 'DELETE_ARTICLE_COMMENT_SUCCESS',
  },
  [ActionTypes.DELETE_ARTICLE_COMMENT_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to delete your comment'),
    sentryMessage: 'We were not able to delete your comment',
    toastId: 'DELETE_ARTICLE_COMMENT_FAILURE',
  },
  [ActionTypes.EDIT_ARTICLE_COMMENT_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully edited the comment'),
    toastId: 'EDIT_ARTICLE_COMMENT_SUCCESS',
  },
  [ActionTypes.EDIT_ARTICLE_COMMENT_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to edit this comment'),
    sentryMessage: 'We were not able to edit this comment',
    toastId: 'EDIT_ARTICLE_COMMENT_FAILURE',
  },
}

const commentErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(commentErrorTypes, type))

export default commentErrorEpic
