import { createSelector } from 'reselect'
import R from 'ramda'

import { getWatchIndexes as getState } from './rootSelectors'

export const getProfilesMapping = createSelector(getState, (watchIndexState) => watchIndexState.profilesToWatchIds)

export const getWatchQueries = createSelector(getState, (watchIndexState) => watchIndexState.watchQueries)

export const getSearchWatchId = createSelector(getState, (watchIndexState) => watchIndexState.searchWatchId)

export const getFetchArticlesWithWatchIdInProgress = createSelector(
  getState,
  (watchIndexState) => watchIndexState.fetchArticlesWithWatchIdInProgress,
)

export const getNewArticlesCount = createSelector(getState, (watchIndexState) =>
  R.view(R.lensPath(['watchQueries', watchIndexState.searchWatchId, 'cnt']), watchIndexState),
)
