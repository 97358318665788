import { useT } from '@transifex/react'
import React, { useEffect, useState } from 'react'

import { IMAGES } from '../../constants'
import Loader from '../../new-components/common/Loader'
import config from '../../opoint/common/config'

import styles from './index.module.scss'

const LoggedOut = () => {
  const t = useT()
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    config.auth.logout().then(() => setComplete(true))
  }, [])

  if (!complete) {
    return (
      <div className={styles.wrapper}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <img src={IMAGES.infomedia} alt="Infomedia" />
      <h3>{t('You have been logged out.')}</h3>
      <a href="/" title="Infomedia">
        {t('Log back in')}
      </a>
    </div>
  )
}

export default LoggedOut
