// @ts-nocheck
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  getTemplatePreview,
  getTemplates,
  saveTemplate,
} from '../opoint/templates/index'
import { getOpointLocale } from '../selectors/settingsSelectors'
import { getActiveTemplate, getTemplateEditorData, getTemplateFormData } from '../selectors/templatesSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const fetchTemplatePreviewEpic = (action$: any) =>
  action$.ofType(ActionTypes.TEMPLATE_PREVIEW_MODAL_OPEN).switchMap(({ payload }) =>
    Rx.Observable.from(getTemplatePreview(payload))
      ?.map((templatePreview) => buildAction(ActionTypes.TEMPLATE_PREVIEW_FETCH_SUCCESS, templatePreview))
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.TEMPLATE_PREVIEW_FETCH_FAILURE))),
  )

export const fetchPreviewEpic = (action$) =>
  action$.ofType(ActionTypes.TEMPLATE_PREVIEW_FETCH).switchMap(({ payload: { template } }) =>
    template
      ? Rx.Observable.from(getTemplatePreview(template.id))
          ?.map((data) =>
            buildAction(ActionTypes.ALERT_TEMPLATE_FETCH_SUCCESS, {
              id: template.id,
              preview: data,
            }),
          )
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_TEMPLATE_FETCH_FAILURE)))
      : Rx.Observable.of(),
  )

export const fetchTemplates = (action$, { getState }) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS),
  )
    .merge(action$.ofType(ActionTypes.TEMPLATES_FETCH))
    .switchMap(() => {
      const locale = getOpointLocale(getState())

      return Rx.Observable.from(getTemplates(locale))
        ?.map((data) => buildAction(ActionTypes.TEMPLATES_FETCH_SUCCESS, data))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.TEMPLATES_FETCH_FAILURE)))
    })

export const fetchTemplateDetail = (action$) =>
  action$.ofType(ActionTypes.FETCH_TEMPLATE_DETAIL).switchMap(({ payload }) => {
    const { id } = payload

    return Rx.Observable.from(getTemplate(id))
      ?.map(({ meta: { idTemplate, maxLevel }, modules }) =>
        buildAction(ActionTypes.FETCH_TEMPLATE_DETAIL_SUCCESS, {
          id: idTemplate,
          maxLevel,
          modules,
        }),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_TEMPLATE_DETAIL_FAILURE)))
  })

export const saveTemplateEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.TEMPLATE_SAVE).switchMap(({ payload: { isNewTemplate } }) => {
    const state = getState()
    const activeTemplate = getActiveTemplate(state)
    const settings = getTemplateEditorData(isNewTemplate)(state)

    if (!isNewTemplate) {
      return Rx.Observable.fromPromise(saveTemplate(settings, activeTemplate.id))
        .switchMap(({ meta: { idTemplate, maxLevel }, modules }) => {
          return Rx.Observable.of(
            buildAction(ActionTypes.TEMPLATE_SAVE_SUCCESS),
            buildAction(ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_CLOSE),
            buildAction(ActionTypes.TEMPLATES_FETCH),
            buildAction(ActionTypes.FETCH_TEMPLATE_DETAIL_SUCCESS, {
              id: idTemplate,
              maxLevel,
              modules,
            }),
          )
        })
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() =>
          Rx.Observable.of(
            buildAction(ActionTypes.TEMPLATE_SAVE_FAILURE),
            buildAction(ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_CLOSE),
          ),
        )
    }

    const template = {
      name: getTemplateFormData(state).REPORT_LIST_NAME,
      type: activeTemplate.type,
      settings,
    }

    return Rx.Observable.fromPromise(createTemplate(template))
      .switchMap(() =>
        Rx.Observable.of(
          buildAction(ActionTypes.TEMPLATE_NEW_SUCCESS),
          buildAction(ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_CLOSE),
          buildAction(ActionTypes.TEMPLATES_FETCH),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() =>
        Rx.Observable.of(
          buildAction(ActionTypes.TEMPLATE_NEW_FAILURE),
          buildAction(ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_CLOSE),
        ),
      )
  })

export const deleteTemplateEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.TEMPLATE_DELETE).switchMap(({ payload }) => {
    const templateIdToBeDeleted = payload || getActiveTemplate(getState()).id

    return Rx.Observable.fromPromise(deleteTemplate(templateIdToBeDeleted))
      .switchMap(() =>
        Rx.Observable.of(
          buildAction(ActionTypes.TEMPLATE_DELETE_SUCCESS),
          buildAction(ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_CLOSE),
          buildAction(ActionTypes.TEMPLATES_FETCH),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.TEMPLATE_DELETE_FAILURE)))
  })

export default [
  deleteTemplateEpic,
  fetchPreviewEpic,
  fetchTemplateDetail,
  fetchTemplatePreviewEpic,
  fetchTemplates,
  saveTemplateEpic,
]
