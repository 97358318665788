import React, { ReactNode, useState, useEffect } from 'react'

import { Tooltip as BootstrapTooltip, OverlayTrigger } from '../BootstrapElements'

import styles from './index.module.scss'

export interface Props {
  placement?: 'top' | 'right' | 'bottom' | 'left'
  content: ReactNode
  children: ReactNode
  className?: string
  absolutePosition?: boolean
  hide?: boolean
}

export default function Tooltip({
  placement = 'top',
  children,
  content,
  className,
  hide,
  absolutePosition = false,
}: Props): JSX.Element {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    setHidden(hide)
  }, [hide])

  const tooltip = hidden ? (
    <React.Fragment />
  ) : (
    <BootstrapTooltip id="editTooltip" className={className}>
      {content}
    </BootstrapTooltip>
  )

  if (absolutePosition) {
    return (
      <>
        <OverlayTrigger placement={placement} overlay={tooltip}>
          <div className={styles.absolute} />
        </OverlayTrigger>
        {children}
      </>
    )
  }

  return (
    <OverlayTrigger placement={placement} overlay={tooltip}>
      {children}
    </OverlayTrigger>
  )
}
