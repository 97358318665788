// @ts-nocheck

// Todo we should type actions too

import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import type { Action } from '../opoint/flow'

type ReportsHistoryState = {
  loading: boolean
  reportObject?: Object | null // report object from API todo
  modalReportsHistory?: Array<Object>
  totalCount: number
}

export const initialState: ReportsHistoryState = {
  loading: false,
  reportObject: null,
  modalReportsHistory: [],
  totalCount: 0,
}
const reportsHistoryReducer = (state: ReportsHistoryState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.REPORTHISTORY_FETCH_SUCCESS: {
      const { reports, totalCount } = payload
      return R.evolve(
        {
          /* eslint-disable-next-line no-underscore-dangle */
          modalReportsHistory: R.concat(R.__, reports),
          loading: R.always(false),
          totalCount: R.always(totalCount),
        },
        state,
      )
    }
    case Actions.REPORTHISTORY_SET_ACTIVE: {
      const { reportObject } = payload
      return R.assoc('reportObject', reportObject, state)
    }
    case Actions.REPORTHISTORY_FETCH: {
      return R.assoc('loading', true, state)
    }
    case Actions.REPORTHISTORY_CLEAR: {
      return R.evolve(
        {
          modalReportsHistory: R.always([]),
          loading: R.always(false),
          reportObject: R.always(null),
        },
        state,
      )
    }
    case Actions.DELETE_REPORT_SUCCESS: {
      const { id } = payload
      return R.evolve(
        {
          modalReportsHistory: R.reject(R.propEq('id', id)),
          totalCount: R.dec,
        },
        state,
      )
    }
    default:
      return state
  }
}

export default reportsHistoryReducer
