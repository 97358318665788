import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import {
  addArticleComment,
  deleteArticleComment,
  editArticleComment,
  fetchArticleComments,
} from '../opoint/common/comments'
import { getArticleDetails } from '../selectors/commentsSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchCommentsEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(
      ActionTypes.OPEN_ARTICLE_COMMENTS_MODAL,
      ActionTypes.ADD_ARTICLE_COMMENT_SUCCESS,
      ActionTypes.DELETE_ARTICLE_COMMENT_SUCCESS,
      ActionTypes.EDIT_ARTICLE_COMMENT_SUCCESS,
    )
    .switchMap(() => {
      const state = getState()
      const { id_site, id_article } = getArticleDetails(state)
      const articleId = `${id_site}_${id_article}`

      return Rx.Observable.fromPromise(fetchArticleComments(id_site, id_article))?.map((comments) =>
        buildAction(ActionTypes.FETCH_ARTICLE_COMMENTS_SUCCESS, { comments, articleId }),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLE_COMMENTS_FAILURE)))

const addCommentEpic = (action$) =>
  action$
    .ofType(ActionTypes.ADD_ARTICLE_COMMENT)
    .switchMap(({ payload }) => {
      return Rx.Observable.fromPromise(addArticleComment(payload))?.map(() =>
        buildAction(ActionTypes.ADD_ARTICLE_COMMENT_SUCCESS),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.ADD_ARTICLE_COMMENT_FAILURE)))

const deleteCommentEpic = (action$) =>
  action$
    .ofType(ActionTypes.DELETE_ARTICLE_COMMENT)
    .switchMap(({ payload }) => {
      return Rx.Observable.fromPromise(deleteArticleComment(payload))?.map(() =>
        buildAction(ActionTypes.DELETE_ARTICLE_COMMENT_SUCCESS),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.DELETE_ARTICLE_COMMENT_FAILURE)))

const editCommentEpic = (action$) =>
  action$
    .ofType(ActionTypes.EDIT_ARTICLE_COMMENT)
    .switchMap(({ payload }) => {
      return Rx.Observable.fromPromise(editArticleComment(payload))?.map(() =>
        buildAction(ActionTypes.EDIT_ARTICLE_COMMENT_SUCCESS),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.EDIT_ARTICLE_COMMENT_FAILURE)))

export default [fetchCommentsEpic, addCommentEpic, deleteCommentEpic, editCommentEpic]
