export const SPECIAL_ASPECTS_IDS = {
  TAG: 13,
  PROFILE: 41,
  SENTIMENT: 42,
  ANALYSIS: 43,
}

export const ASPECT_OVERLAP_OPTIONS_DEFAULT = [0, 1, 2, 3, 4, 5, 10]

// group of aspect which are computed by default
export const DEFAULT_ASPECT_GROUPS_TO_COMPUTE = 1

export const getSelectedTagLikeEntities = ({ tagLikeEntities }) =>
  (tagLikeEntities || []).filter(({ selected }) => selected)
