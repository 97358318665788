// @ts-nocheck
import { tx } from '@transifex/native'
import dayjs from 'dayjs'
import * as R from 'ramda'
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { locales } from '../opoint/common/constants'
import { getSettings, saveSettings } from '../opoint/settings/index'
import { hasNumberOfProfilesAndTagsReachedThreshold } from '../selectors/profilesSelectors'
import { getGroupingEnabled, getUISettings } from '../selectors/settingsSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const handleLanguageChange = (language: string): void => {
  const locale = locales[language]?.alertsLocale

  const currentLocale = locale === 'gb_EN' ? 'en' : locale

  tx.setCurrentLocale(currentLocale)

  dayjs.locale(language)
}

export const fetchSettingsEpic: (action$) => void = (action$) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS).switchMap(() =>
    Rx.Observable.fromPromise(getSettings())?.map((settings) => {
      const language = settings.find((setting) => setting.name === 'LANGUAGE').value

      handleLanguageChange(language)

      return buildAction(ActionTypes.SETTINGS_FETCH_SUCCESS, settings)
    }),
  )

const onToggleUIPrepEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$.ofType(ActionTypes.TOGGLE_UI_SETTING_PREP).switchMap(({ payload }) => {
    const hasReachedThreshold = hasNumberOfProfilesAndTagsReachedThreshold(getState())

    return Rx.Observable.of(buildAction(ActionTypes.TOGGLE_UI_SETTING, { setting: payload, hasReachedThreshold }))
  })

export const toggleUIEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$
    .filter(({ type }) => [ActionTypes.TOGGLE_UI_SETTING, ActionTypes.TRASH_TOGGLE_VISIBILITY].includes(type))
    .mergeMap(({ payload }) => {
      const rawSettings = getUISettings(getState())
      if (rawSettings[payload.setting] === undefined) {
        rawSettings[payload.setting] = true
      }
      const settings = {}
      settings.APP_UI_SETTINGS = rawSettings

      return Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings, toggleSetting: true }))
    })

export const settingsSaveEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$.ofType(ActionTypes.SETTINGS_SAVE).switchMap(({ payload }) => {
    const { settings, toggleSetting, fromFilters } = payload

    if (settings?.LANGUAGE) {
      handleLanguageChange(settings?.LANGUAGE)
    }

    if (settings.COLORBAR_COLORS) {
      settings.COLORBAR_COLORS = +settings.COLORBAR_COLORS
    }

    // since we are storing one settings value in APP_UI_SETTINGS we have to merge it with old
    // APP_UI_SETTINGS in order to avoid overriding them and thus deleting by accident
    const uiSettings = getUISettings(getState())
    const fullSettings = R.mergeDeepRight({ APP_UI_SETTINGS: uiSettings }, settings)

    const settingsEntries = Object.entries(fullSettings)?.map(([name, value]) => ({ name, value }))

    if (!settingsEntries.length) {
      return Rx.Observable.of()
    }

    const successAction = (savedSettings) =>
      buildAction(ActionTypes.SETTINGS_SAVE_SUCCESS, {
        // This needs to be done here, because in saving case, backend returns us same format
        // used for saving which results in stringified data in store => crashes application
        settings: R.map((savedSetting) => {
          if (savedSetting.name === 'APP_DEFAULT_HOME') {
            return {
              name: savedSetting.name,
              value: JSON.parse(savedSetting.value),
            }
          }

          return savedSetting
        })(savedSettings),
        toggleSetting,
      })

    return Rx.Observable.fromPromise(saveSettings(settingsEntries))
      .switchMap((savedSettings) => {
        if (fromFilters) {
          return Rx.Observable.merge(
            Rx.Observable.of(successAction(savedSettings)),
            Rx.Observable.of(buildAction(ActionTypes.FILTERS_PANEL_TOGGLE, { refreshOnly: true })),
          )
        }

        return Rx.Observable.of(successAction(savedSettings))
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE_FAILURE)))
  })

export const filtersOrderEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$.ofType(ActionTypes.FILTERS_REORDER).mergeMap(() => {
    const rawSettings = getUISettings(getState())
    const settings = {}
    settings.APP_UI_SETTINGS = rawSettings

    return Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings }))
  })

const onGroupingToggleEpic: (action$, { getState }) => void = (action$, { getState }) =>
  action$.ofType(ActionTypes.SETTINGS_TOGGLE_GROUPING).switchMap(() => {
    const enableGrouping = !getGroupingEnabled(getState())

    return Rx.Observable.concat(
      Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings: { IDENTICAL: enableGrouping } })),
    )
  })

const onSourceFiltersByTopic: (action$) => void = (action$) =>
  action$.ofType(ActionTypes.SET_SITE_FILTER_TOPIC).switchMap(() =>
    Rx.Observable.of(
      buildAction(ActionTypes.SETTINGS_SAVE, {
        settings: { APP_UI_SETTINGS: { NEW_PORTAL_SITE_DRILLDOWN_OPTION: 'topic' } },
        fromFilters: true,
        toggleSetting: true,
      }),
    ),
  )

const onSourceFiltersByLocation: (action$) => void = (action$) =>
  action$.ofType(ActionTypes.SET_SITE_FILTER_LOCATION).switchMap(() =>
    Rx.Observable.of(
      buildAction(ActionTypes.SETTINGS_SAVE, {
        settings: { APP_UI_SETTINGS: { NEW_PORTAL_SITE_DRILLDOWN_OPTION: 'location' } },
        fromFilters: true,
        toggleSetting: true,
      }),
    ),
  )

export default [
  fetchSettingsEpic,
  filtersOrderEpic,
  onGroupingToggleEpic,
  settingsSaveEpic,
  toggleUIEpic,
  onToggleUIPrepEpic,
  onSourceFiltersByTopic,
  onSourceFiltersByLocation,
]
