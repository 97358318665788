import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { processTrashTags } from '../opoint/tags/trash'
import type { Action, Trash } from '../opoint/flow'

type trashState = {
  list: Array<Trash>
}

const initialState = {
  list: [],
}

const trashReducer = (state: trashState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.TRASH_FETCH_SUCCESS: {
      const { trashTags } = payload
      return R.assoc('list', processTrashTags(trashTags), state)
    }

    default:
      return state
  }
}

export default trashReducer
