import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const profilesErrorTypes: ErrorEpic = {
  [ActionTypes.PROFILES_FETCH_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to fetch profiles'),
    sentryMessage: 'We were unable to fetch profiles',
    toastId: 'PROFILES_FETCH_FAILURE',
  },
  [ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to provide suggestions'),
    sentryMessage: 'We were unable to provide suggestions',
    toastId: 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE',
  },
  [ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE]: {
    isSuccess: false,
    message: t('We were unable to provide suggestions'),
    sentryMessage: 'We were unable to provide suggestions',
    toastId: 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE',
  },
}

const profilesErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(profilesErrorTypes, type))

export default profilesErrorEpic
