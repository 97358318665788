import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { getTags } from '../opoint/analytics'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const fetchEpic = (action$) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS).switchMap(({ payload }) =>
    Rx.Observable.from(getTags())
      ?.map((data) => buildAction(ActionTypes.ANALYSIS_TAGS_FETCH_SUCCESS, data))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ANALYSIS_TAGS_FETCH_FAILURE))),
  )

export default [fetchEpic]
