import { Auth } from '@opoint/authjs-react'

type Params = {
  headers: {
    accept: string
    'accept-language': string
    'content-type': string
    authorization?: string
  }
  responseType: 'json'
}

export const isDev =
  process.env.REACT_APP_API === 'development' ||
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_VERCEL_ENV === 'preview'

export const LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT = 20

const urls = {
  api: { dev: 'https://api.staging.opoint.com', prod: 'https://api.opoint.com' },
  app: { dev: 'https://m360-beta.opoint.com', prod: 'https://m360.opoint.com' },
  auth: {
    infomedia: (tld: string) => ({
      dev: `https://id.dev.infomedia.${tld}`,
      prod: `https://id.infomedia.${tld}`,
    }),
    opoint: { dev: 'https://auth.dev.opoint.com', prod: 'https://auth.opoint.com' },
  },
  callback: `https://${location.host}/callback`,
}

const api = (path: string) =>
  !!process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}${path}`
    : `${isDev ? urls.api.dev : urls.api.prod}${path}`

const app = (path: string) =>
  !!process.env.REACT_APP_APP_URL
    ? `${process.env.REACT_APP_APP_URL}${path}`
    : `${isDev ? urls.app.dev : urls.app.prod}${path}`

const imageproxy = (path: string) =>
  !!process.env.REACT_APP_APP_URL
    ? `${process.env.REACT_APP_APP_URL}/imageproxy${path}`
    : `${isDev ? urls.app.dev : urls.app.prod}/imageproxy${path}`

const buildAuthUrl = () => {
  if (!!process.env.REACT_APP_AUTH_URL) {
    return process.env.REACT_APP_AUTH_URL
  }
  if (window.location.hostname.includes('infomedia')) {
    const [tld] = window.location.hostname.split('.').splice(-1)
    const { dev, prod } = urls.auth.infomedia(tld)

    return isDev ? dev : prod
  }

  return isDev ? urls.auth.opoint.dev : urls.auth.opoint.prod
}

const buildCallbackUrl = (redirect = '') =>
  `https://${location.host}/callback${redirect ? `?redirect=${redirect}` : ''}`

export const getRequestHeaders = async (requireAuth = true) => {
  const params: Params = {
    headers: {
      accept: 'application/json',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
    },
    responseType: 'json',
  }
  if (requireAuth) {
    // NEW AUTH
    params.headers.authorization = `JWT ${await config.auth.getTokenString()}`
  }

  return params
}

const config: {
  auth?: Auth
  request: {
    getRequestHeaders: (requireAuth?: boolean) => Promise<Params>
  }
  url: {
    api: (path: string) => string
    imageproxy: (path: string) => string
    app: (path: string) => string
    auth: () => string
    callback: (redirect?: string) => string
  }
} = {
  // This value is initialised at the root node of the app,
  // so no code that needs it should actually hit this undefined
  auth: undefined,
  request: {
    getRequestHeaders,
  },
  url: {
    api,
    imageproxy,
    app,
    auth: () => buildAuthUrl(),
    callback: buildCallbackUrl,
  },
}

export default config
