import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useSearchContext() {
  const location = useLocation()

  const [searchContect, setSearchContext] = useState('search')

  useEffect(() => setSearchContext(searchContextLogic(location.pathname)), [location])

  return searchContect
}

export function searchContextLogic(pathname: string) {
  if (pathname.startsWith('/mobile/')) {
    return 'mobile'
  } else if (pathname.startsWith('/statistics/compare/')) {
    return 'statistics/compare'
  } else if (pathname.startsWith('/statistics/')) {
    return 'statistics'
  } else {
    return 'search'
  }
}
