import R from 'ramda'
import { createSelector } from 'reselect'

import { TEMPLATE_TYPES, objectDiff, EXCLUDED_TEMPLATES } from '../opoint/templates/index'
import { getForm, getTemplates as getState } from './rootSelectors'

export const getTemplates = createSelector(getState, (templatesState) => templatesState.list)

export const isPreviewModalOpen = createSelector(getState, (templatesState) => templatesState.previewModalIsOpen)

export const getTemplatePreview = createSelector(
  getState,
  (templatesState) => templatesState.lastFetchedTemplate && templatesState.lastFetchedTemplate.preview,
)

//@ts-ignore
export const getGroupedTemplates = createSelector(getTemplates, R.groupBy(R.prop('type')))

export const getGroupedTemplatesWithoutExcluded = createSelector(
  getTemplates,
  R.compose(
    //@ts-ignore
    R.groupBy(R.prop('type')),
    R.reject((template: any) => R.contains(template.id, EXCLUDED_TEMPLATES)),
  ),
)

export const getDefaultTemplate = createSelector(getTemplates, (templates) => templates[0])

export const getTemplateModules = (templateId: number) =>
  createSelector(getState, (templateState) => R.dissoc('maxLevel', templateState.templateDetails[templateId]))

export const getTemplateMaxLevel = (templateId: number) =>
  createSelector(getState, (templateState) => {
    const templateDetails = templateState.templateDetails[templateId]
    return templateDetails ? templateDetails.maxLevel : 0
  })

const childBitPairs = (childrenCount, value) => {
  const pairs = {}
  let tmp = value

  for (let i = 0; i < childrenCount; i++) {
    pairs[Math.pow(2, i)] = tmp % 2
    tmp = Math.floor(tmp / 2)
  }

  return pairs
}

const updateChildren = (children, value) => {
  const pairs = childBitPairs(children.length, value)
  return R.map((child: any) => R.assoc('value', pairs[child.dataValue], child))(children)
}

const mapInitialValues = (widgets, groupName) =>
  R.map((widget: any) => {
    if (!!widget) {
      const { name, value, type } = widget
      switch (type) {
        case 'WIDGET_INPUT_BIG':
          return { [name]: value }
        case 'WIDGET_VALUE_RADIO':
        case 'WIDGET_DROPDOWN': {
          let selectedOption = {}
          R.forEach((option: any) => {
            if (option.selected) {
              selectedOption = { [name]: option.value }
            }
          })(value)
          return selectedOption
        }
        case 'WIDGET_GROUP': {
          const { parent, children, childSetting, name: groupName } = widget
          const intValue = parseInt(value, 10)
          // If there is value greater than 0 and childSetting is false,
          // than update children values according to group value
          const kids = intValue && !childSetting ? updateChildren(children, intValue) : children
          if (parent) {
            return [{ [parent.name]: !!parseInt(parent.value, 10) }, ...mapInitialValues(kids, groupName)]
          }
          return [...mapInitialValues(kids, groupName)]
        }
        case 'WIDGET_RADIO': {
          if (parseInt(value, 10)) {
            return { [groupName]: name }
          }
          return null
        }
        case 'WIDGET_SLIDER':
          return { [name]: parseInt(value.selected, 10) }
        case 'WIDGET_TEXTAREA_SMALL':
          return { [name]: value }
        case 'WIDGET_CHECKBOX':
          return { [name]: !!parseInt(value, 10) }
        default:
          return { [name]: value }
      }
    }
  })(widgets)

//@ts-ignore
const getWidgets = R.compose(
  R.mergeAll,
  R.flatten,
  mapInitialValues,
  R.unnest,
  R.map((module: any) => module.widgets),
)

const getTemplateValues = ({ modules }) => getWidgets(Object.values(modules))

export const getActiveTemplate = createSelector(getState, (templatesState) => templatesState.activeTemplate)

const getInitialTemplateData = createSelector(getForm, (formState) => formState.templateEditor.initial)

export const getTemplateFormData = createSelector(getForm, (formState) => formState.templateEditor.values)

export const getTemplateSortableModules = createSelector(getState, (templatesState) => templatesState.sortableModules)

export const getTemplateEditorData = (isNewTemplate: boolean) =>
  createSelector(
    getInitialTemplateData,
    getTemplateFormData,
    getTemplateSortableModules,
    (initialData, formData, sortableModules) => {
      const formDataTransformed = []
      //@ts-ignore
      const sortableWidgetsSortIndexes = R.compose(
        R.map(R.compose(R.prop('sortIndex'), R.head)),
        R.groupBy(R.prop('name')),
        R.flatten,
        R.values,
      )(sortableModules)

      const settings = isNewTemplate ? formData : objectDiff(initialData, formData, sortableWidgetsSortIndexes)

      let includeQrValue = 0
      R.forEachObjIndexed((value, key) => {
        switch (key) {
          case 'REPORT_LIST_HEADER':
            value ? formDataTransformed.push({ name: key, value }) : formDataTransformed.push({ name: key, value: '' })
            break
          case 'RT_INC_QR_0':
            break
          case 'RT_INC_QR_1':
            value && (includeQrValue += 1)
            break
          case 'RT_INC_QR_2':
            value && (includeQrValue += 2)
            break
          case 'RT_INC_IDENTICAL':
            if (value !== initialData[key]) {
              if (value === 'RT_INC_IDENTICAL_0') {
                formDataTransformed.push({ name: 'RT_INC_IDENTICAL', value: 0 })
              }
              if (value === 'RT_INC_IDENTICAL_1') {
                formDataTransformed.push({ name: 'RT_INC_IDENTICAL', value: 1 })
              }
              if (value === 'RT_INC_IDENTICAL_2') {
                formDataTransformed.push({ name: 'RT_INC_IDENTICAL', value: 2 })
              }
            }
            break
          case 'RT_INC_PDF_GROUP':
          case 'RT_INC_TOC': {
            let boolValue
            // RT_INC_TOC checked but there are no any selected radio
            if (value === true) {
              // We always have to send `RT_INC_TOC` state
              formDataTransformed.push({ name: key, value: +value })
              boolValue = +value
              // Set RT_INC_TOC_ORIG_LINK as a default if no other radio was selected
              /* eslint-disable-next-line no-param-reassign */
              value = 'RT_INC_TOC_ORIG_LINK'
              // Unchecking RT_INC_TOC unchecks itself and radios
            } else if (value === false) {
              boolValue = +value
              /* eslint-disable-next-line no-param-reassign */
              value = key
              // Turn some radio on
            } else {
              // Always here send RT_INC_TOC: 1
              formDataTransformed.push({ name: key, value: 1 })
              boolValue = 1
            }

            // Setting which changes
            formDataTransformed.push({ name: value, value: boolValue })

            if (initialData[key]) {
              formDataTransformed.push({ name: initialData[key], value: 0 }) // Turn off selected settings
            }
            break
          }
          default: {
            const sortIndex = sortableWidgetsSortIndexes[key]
            const widget = { name: key, value: R.is(Boolean, value) ? +value : value }
            sortIndex !== undefined
              ? formDataTransformed.push(R.assoc('sortIndex', sortIndex, widget))
              : formDataTransformed.push(widget)
          }
        }
      }, settings)

      if (
        initialData.RT_INC_QR_0 !== undefined &&
        (initialData.RT_INC_QR_1 !== formData.RT_INC_QR_1 || initialData.RT_INC_QR_2 !== formData.RT_INC_QR_2)
      ) {
        formDataTransformed.push({ name: 'RT_INC_QR', value: includeQrValue })
      }

      return formDataTransformed
    },
  )

// In the end this cannot be memoized
// because if we save new template old values are persisted until page is refreshed
export const getTemplateInitialValues = (templateId: number) =>
  createSelector(getTemplateModules(templateId), (modules) => getTemplateValues({ modules }))

export const getXlsTemplates = createSelector(getTemplates, (templatesList) =>
  R.filter((template: any) => template.type === TEMPLATE_TYPES.XLS, templatesList),
)

export const getTemplateEditName = createSelector(getForm, (formState) =>
  R.path(['templateEditor', 'initial', 'REPORT_LIST_NAME'], formState),
)

export const getActiveModuleName = createSelector(getState, (templatesState) => templatesState.activeSortableModule)

export const getTemplateActiveModuleWidgets = createSelector(
  getActiveModuleName,
  getTemplateSortableModules,
  (moduleName, modules) => modules[moduleName],
)
