import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'

import alertsEpics from './alertsEpics'
import analyticsEpics from './analyticsEpics'
import articlesEpics from './articlesEpics'
import authEpics from './authEpics'
import commentsEpics from './commentsEpics'
import contactsEpics from './contactsEpics'
import feedsEpics from './feedsEpics'
import foldersEpic from './foldersEpic'
import impersonationEpics from './impersonationEpics'
import notificationsEpics from './notificationsEpics'
import profilesEpics from './profilesEpics'
import reportsEpics from './reportsEpics'
import reportsHistoryEpics from './reportsHistoryEpics'
import searchEpics from './searchEpics'
import settingsEpics from './settingsEpics'
import statisticsEpics from './statisticsEpics'
import tagsEpics from './tagsEpics'
import templatesEpics from './templatesEpics'
import watchIndexEpics from './watchIndexEpics'

const epics = [
  ...alertsEpics,
  ...analyticsEpics,
  ...articlesEpics,
  ...authEpics,
  ...contactsEpics,
  ...feedsEpics,
  ...impersonationEpics,
  ...notificationsEpics,
  ...profilesEpics,
  ...reportsEpics,
  ...reportsHistoryEpics,
  ...searchEpics,
  ...settingsEpics,
  ...statisticsEpics,
  ...tagsEpics,
  ...templatesEpics,
  ...watchIndexEpics,
  ...foldersEpic,
  ...commentsEpics,
]

const configureEpics =
  (deps: Object, platformEpics: Array<any>) =>
  (action$, { getState }) =>
    // @ts-ignore
    combineEpics(...epics, ...platformEpics)(action$, { ...deps, getState })

export const logOutOnExpiredToken = (val) =>
  val.status === 401
    ? Observable.of(buildAction(ActionTypes.LOGOUT), buildAction(ActionTypes.TOKEN_EXPIRED))
    : Observable.throw(val)

export const serverIsDown = (val) =>
  val.status === 0 || val.status >= 500
    ? Observable.of(buildAction(ActionTypes.SERVER_NOT_RESPONDING))
    : Observable.throw(val)

export default configureEpics
