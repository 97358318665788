import { t } from '@transifex/native'
import classNames from 'classnames'
import * as React from 'react'

import { ArticleMetadataType, ArticleSimilarweb } from '../../../../../types/article'
import { LocaleKey } from '../../../../../types/locale'
import { Tag } from '../../../../../types/tag'
import SentimentTooltip from '../../../SentimentTooltip'

import styles from './ArticleMetadataBox.module.scss'

export type ArticleMetadataBoxProps = {
  countrycode: string
  countryname: string
  locale: LocaleKey
  reach: number
  similarweb: ArticleSimilarweb
  site_rank: {
    country?: string
    rank_country: number
    rank_global: number
  }
  type: ArticleMetadataType
  word_count: number
  sentimentTag?: Tag
}

export const ARTICLE_METADATA_CONFIG: {
  [key in ArticleMetadataType]: {
    title: (similarweb?: ArticleSimilarweb) => string
    value: (
      article: {
        countryname: string
        reach: number
        similarweb: ArticleSimilarweb
        site_rank: {
          country?: string
          rank_country: number
          rank_global: number
        }
        word_count: number
        sentimentTag?: Tag
      },
      locale?: LocaleKey,
    ) => React.ReactNode
  }
} = {
  country: {
    title: () => t('Country'),
    value: ({ countryname }) => countryname,
  },
  rank_global: {
    title: () => t('Global rank'),
    value: ({ site_rank }, locale) => site_rank?.rank_global?.toLocaleString(locale),
  },
  rank_country: {
    title: () => t('Country rank'),
    value: ({ site_rank }, locale) => site_rank?.rank_country?.toLocaleString(locale),
  },
  page_per_visit: {
    title: () => t('Pages per visit'),
    value: ({ similarweb }) => similarweb?.page_per_visit?.toFixed(2),
  },
  bounce_rate: {
    title: () => t('Bounce rate'),
    value: ({ similarweb }) => {
      if (!similarweb?.bounce_rate) {
        return null
      }

      return `${(similarweb.bounce_rate * 100).toFixed(1)}%`
    },
  },
  words: {
    title: () => t('Words'),
    value: ({ word_count }, locale) => word_count?.toLocaleString(locale),
  },
  // TODO: Not implemented by the backend yet. Uncomment whenever it's been done.
  // reach: {
  //   title: () => t('Reach'),
  //   value: ({ reach }: any, locale: any) => reach?.toLocaleString(locale),
  // },
  readers_so_far: {
    title: (similarweb) => {
      const stdExpectedPValue = similarweb?.article_readership?.StdExpectedPValue

      if (!stdExpectedPValue) {
        return t('Readers')
      }

      return t('Readers so far')
    },
    value: ({ similarweb }, locale) => similarweb?.article_readership?.value?.toLocaleString(locale),
  },
  new_readers_p_day: {
    title: () => t('New readers/hour'),
    value: ({ similarweb }, locale) => similarweb?.article_readership?.DeltaPValue?.toLocaleString(locale),
  },
  average_readership: {
    title: () => t('Average readership'),
    value: ({ similarweb }, locale) => similarweb?.readership?.value?.toLocaleString(locale),
  },
  mvisit: {
    title: () => t('Monthly Visits'),
    value: ({ similarweb }, locale) => similarweb?.mvisit?.toLocaleString(locale),
  },
  unique_mobile_visit: {
    title: () => t('Unique Mobile Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_mobile_visit?.toLocaleString(locale),
  },
  unique_desktop_visit: {
    title: () => t('Unique Desktop Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_desktop_visit?.toLocaleString(locale),
  },
  unique_daily_visit: {
    title: () => t('Unique Daily Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_daily_visit?.toLocaleString(locale),
  },
  unique_visit: {
    title: () => t('Unique Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_visit?.toLocaleString(locale),
  },
  time_on_site: {
    title: () => t('Time on Site'),
    value: ({ similarweb }, locale) =>
      similarweb?.time_on_site
        ? t('{ timeOnSite } sec', {
            timeOnSite: similarweb?.time_on_site?.toLocaleString(locale),
          })
        : '',
  },
  final_readers: {
    title: () => t('Final readers'),
    value: ({ similarweb }, locale) => {
      const stdExpectedPValue = similarweb?.article_readership?.StdExpectedPValue

      const expectedValue = similarweb?.article_readership?.ExpectedPValue

      const suffix = !!stdExpectedPValue ? ` ± ${stdExpectedPValue?.toLocaleString(locale)}` : ''

      if (!expectedValue) {
        return null
      }

      return `${expectedValue?.toLocaleString(locale)}${suffix}`
    },
  },
  ecb_sentiment: {
    title: () => t('Sentiment'),
    value: ({ sentimentTag }) => {
      return sentimentTag && <SentimentTooltip name={sentimentTag.name} id={sentimentTag.id} />
    },
  },
}

const ArticleMetadataBox = ({
  countryname,
  locale,
  reach,
  similarweb,
  site_rank,
  type,
  word_count,
  sentimentTag,
}: ArticleMetadataBoxProps): JSX.Element => {
  const data = ARTICLE_METADATA_CONFIG[type]
  const value = data?.value({ countryname, reach, similarweb, site_rank, word_count, sentimentTag }, locale)
  if (!value) {
    return null
  }

  return (
    <li className={styles.listItem}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{data.title(similarweb)}</div>
        <div className={classNames(styles.content, { [styles.sentimentMarkerWrapper]: type === 'ecb_sentiment' })}>
          {value}
        </div>
      </div>
    </li>
  )
}

export default ArticleMetadataBox
