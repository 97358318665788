import R from 'ramda'
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import {
  deleteProfile,
  getDeletedProfiles,
  getFilterMetasDetail,
  getProfileDetail,
  getProfileHistory,
  getProfiles,
  saveProfile,
  updateProfilesOrder,
  getProfileDependencies,
} from '../opoint/profiles/index'
import { getMultipleSuggestions, getSimpleSuggestions, search, cutFiltersTypeMinus } from '../opoint/search/index'
import {
  getEditedProfile,
  getSaveAsProfile,
  getActiveProfileEditorLine,
  getSearchDataForSearchline,
  isProfileEditorFiltersPanelOpen,
} from '../selectors/profilesSelectors'
import { getFiltersShowMore, getMainSearchLine } from '../selectors/searchSelectors'
import {
  getDefaultSearch,
  getOpointLocale,
  getFilterSuggest,
  getSearchSuggest,
  getSuggestionLocale,
} from '../selectors/settingsSelectors'
import { getBaskets } from '../selectors/tagsSelectors'

import { logOutOnExpiredToken, serverIsDown, pushLocation } from './epicsHelper'

const fetchProfilesOnLogIn = (action$: any) => {
  return action$
    .ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS)
    ?.mapTo(buildAction(ActionTypes.PROFILES_FETCH))
}

const profilesFetchEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.PROFILES_FETCH)
    .switchMap(() =>
      Rx.Observable.fromPromise(getProfiles())?.map((profiles) =>
        buildAction(ActionTypes.PROFILES_FETCH_SUCCESS, profiles),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.PROFILES_FETCH_FAILURE)))

const filterMetasOpenDetailModal = (action$: any) =>
  action$
    .ofType(ActionTypes.FILTER_METAS_SHOW_DETAIL)
    ?.map((action) => buildAction(ActionTypes.FILTER_METAS_FETCH, action.payload))

const filterMetasFetchDetailEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FILTER_METAS_FETCH).mergeMap(
    ({
      payload: {
        filter: { id, type: unprocessedType, name },
      },
    }) => {
      const locale = getOpointLocale(getState())
      // @ts-ignore
      const type = cutFiltersTypeMinus(unprocessedType)

      return Rx.Observable.fromPromise(getFilterMetasDetail(id, type, locale))
        ?.map((filterMetadataDetail) =>
          buildAction(ActionTypes.FILTER_METAS_FETCH_SUCCESS, { filterMetadata: filterMetadataDetail, name, type }),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch((e) => Rx.Observable.of(buildAction(ActionTypes.FILTER_METAS_FETCH_FAILURE, { status: e.status })))
    },
  )

function getSuggestionsObservable(
  searchterm,
  filters,
  suggestionsLocale,
  fetchMultiple,
  id,
  defaultSearchScope,
  filterSuggestParameter,
  searchSuggestParameter,
) {
  return fetchMultiple
    ? Rx.Observable.fromPromise(
        getMultipleSuggestions(
          searchterm,
          filters,
          suggestionsLocale,
          defaultSearchScope,
          undefined,
          filterSuggestParameter,
        ),
      )
        ?.map((results) => ({
          type: ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS,
          payload: {
            id,
            results,
          },
        }))
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE)))
    : Rx.Observable.fromPromise(
        getSimpleSuggestions(
          searchterm,
          filters,
          suggestionsLocale,
          defaultSearchScope,
          undefined,
          searchSuggestParameter,
        ),
      )
        ?.map(({ results }) => ({
          type: ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS,
          payload: {
            id,
            results,
          },
        }))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE)))
}

const profileEditorSearchChangedEpic = (action$: any, { getState }: any) =>
  action$
    .filter(
      R.compose(
        /* eslint-disable-next-line no-underscore-dangle */
        R.contains(R.__, [
          ActionTypes.SEARCHTERM_CHANGED_PROFILE_EDITOR,
          ActionTypes.PROFILE_EDITOR_FOCUSED_LINE_CHANGED,
        ]),
        R.prop('type'),
      ),
    )
    .throttle(({ type }) =>
      type === ActionTypes.SEARCHTERM_CHANGED_PROFILE_EDITOR ? Rx.Observable.interval(100) : Rx.Observable.interval(0),
    )
    .switchMap(({ payload: { id } }) => {
      const state = getState()
      const searchData = getSearchDataForSearchline(id)(state)
      if (!searchData) {
        return Rx.Observable.empty()
      }
      const { searchterm, filters } = searchData
      const isFiltersOpen = isProfileEditorFiltersPanelOpen(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getFilterSuggest(state)
      const searchSuggestParameter = getSearchSuggest(state)

      return getSuggestionsObservable(
        searchterm,
        filters,
        { suggestionLocale },
        isFiltersOpen,
        id,
        defaultSearchScope,
        filterSuggestParameter,
        searchSuggestParameter,
      )
    })

const profileEditorInitialFiltersFetchEpic = (action$: any, { getState }: any) =>
  action$
    .filter(
      R.compose(
        /* eslint-disable-next-line no-underscore-dangle */
        R.contains(R.__, [ActionTypes.PROFILE_EDITOR_FILTERS_TOGGLE, ActionTypes.LOAD_EDITED_PROFILE_SUCCESS]),
        R.prop('type'),
      ),
    )
    .switchMap(() => {
      const state = getState()
      const id = getActiveProfileEditorLine(state)
      const { searchterm, filters } = getSearchDataForSearchline(id)(state) || {}
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getFilterSuggest(state)
      const searchSuggestParameter = getSearchSuggest(state)

      return getSuggestionsObservable(
        searchterm,
        filters,
        { suggestionLocale },
        true,
        id,
        defaultSearchScope,
        filterSuggestParameter,
        searchSuggestParameter,
      )
    })

const onProflieEditorFiltersFetchMore = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FETCH_PROFILE_EDITOR_MORE_FILTERS).switchMap(({ payload }) => {
    const state = getState()
    const id = getActiveProfileEditorLine(state)
    const { searchterm, filters } = getSearchDataForSearchline(id)(state) || {}

    const { action, header, width } = payload
    const suggestionLocale = getSuggestionLocale(state)
    const defaultSearchScope = getDefaultSearch(state)
    const filtersShowMore = getFiltersShowMore(state)
    const isSingleColumn = action < 0
    let responseCount = 10
    if (isSingleColumn) {
      switch (width) {
        case 2:
          responseCount = 60
          break
        case 3:
          responseCount = 40
          break
        default:
          responseCount = 140
      }
    }

    return Rx.Observable.fromPromise(
      getMultipleSuggestions(searchterm, filters, { suggestionLocale }, defaultSearchScope, responseCount, action),
    )
      ?.map((response) =>
        buildAction(ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS, [
          ...filtersShowMore,
          {
            // @ts-ignore
            ...response,
            header,
            isSingleColumn,
            action,
          },
        ]),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of())
  })

const onProflieEditorSearchDataChangeFiltersMore = (action$: any, { getState }: any) =>
  action$
    .ofType(
      ActionTypes.SEARCHTERM_CHANGED_PROFILE_EDITOR,
      ActionTypes.PROFILE_EDITOR_FOCUSED_LINE_CHANGED,
      ActionTypes.PROFILE_EDITOR_FILTERS_POP,
    )
    .switchMap(({ payload }) => {
      const state = getState()
      const id = getActiveProfileEditorLine(state)
      const searchData = getSearchDataForSearchline(id)(state)
      if (!searchData) {
        return Rx.Observable.empty()
      }
      const { searchterm, filters } = searchData

      const suggestionLocale = getSuggestionLocale(state)
      const filtersShowMore = getFiltersShowMore(state)
      const lastFilter = filtersShowMore.slice(-1)[0]

      const defaultSearchScope = getDefaultSearch(state)

      if (lastFilter?.action) {
        const { action, header, width } = lastFilter
        const isSingleColumn = action < 0
        let responseCount = 10
        if (isSingleColumn) {
          switch (width) {
            case 2:
              responseCount = 60
              break
            case 3:
              responseCount = 40
              break
            default:
              responseCount = 140
          }
        }

        return Rx.Observable.fromPromise(
          getMultipleSuggestions(searchterm, filters, { suggestionLocale }, defaultSearchScope, responseCount, action),
        )
          ?.map((response) =>
            buildAction(ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS, [
              ...filtersShowMore.slice(0, filtersShowMore.length - 1),
              {
                // @ts-ignore
                ...response,
                header,
                isSingleColumn,
                action,
              },
            ]),
          )
          .catch(logOutOnExpiredToken)
          .catch(serverIsDown)
          .catch(() => Rx.Observable.of())
      }

      return Rx.Observable.empty()
    })

const deleteProfilesEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILE_DELETE).mergeMap(({ payload: { profileIds, force } }) => {
    const profilesToDelete$ = Rx.Observable.from(profileIds)
    const deletedProfiles$ = profilesToDelete$.mergeMap((id) =>
      // @ts-ignore
      Rx.Observable.fromPromise(deleteProfile({ id, locale: getOpointLocale(getState()), force }))
        .switchMap(() =>
          Rx.Observable.of(
            buildAction(ActionTypes.PROFILE_DELETE_SUCCESS, id),
            buildAction(ActionTypes.SEARCHFILTER_REMOVED, { id, type: 'profile' }),
          ),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch((e) => {
          let error
          try {
            error = JSON.parse(e.originalEvent.target.response)
          } catch (innerError) {
            error = {
              message: 'We were unable to delete this contact',
              data: {},
            }
          }

          return Rx.Observable.of(buildAction(ActionTypes.PROFILE_DELETE_FAILURE, error))
        }),
    )

    // Return status of each profile delete and then cancel delete mode
    return deletedProfiles$.concat(Rx.Observable.of(buildAction(ActionTypes.PROFILES_FETCH)))
  })

const profileEditorSaveEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILE_EDITOR_SAVE_PROFILE).switchMap(() => {
    const state = getState()
    // @ts-ignore
    const name = getSaveAsProfile(state) && getSaveAsProfile(state).name

    const editedProfile = name
      ? R.evolve({
          id: R.always(null),
          name: R.always(name),
        })(getEditedProfile(state))
      : getEditedProfile(state)

    const fetchProfiles$ = Rx.Observable.defer(getProfiles)?.map((profiles) =>
      buildAction(ActionTypes.PROFILES_FETCH_SUCCESS, profiles),
    )

    const saveProfile$ = Rx.Observable.fromPromise(saveProfile(editedProfile))?.map((profile) =>
      // @ts-ignore
      profile.debug
        ? // @ts-ignore
          buildAction(ActionTypes.PROFILE_EDITOR_INVALID_SEARCHLINE, { debug: profile.debug })
        : buildAction(ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS, { profile }),
    )

    return saveProfile$
      .switchMap((saveProfileAction) => {
        const {
          payload: { debug, profile },
        } = saveProfileAction

        return debug
          ? Rx.Observable.of(saveProfileAction)
          : Rx.Observable.concat(
              Rx.Observable.of(saveProfileAction),
              fetchProfiles$,
              pushLocation(`/search/?filters=profile:${profile.id}`),
            )
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_FAILURE)))
  })

const profileEditorDeleteEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILE_EDITOR_DELETE_CONFIRM).switchMap(() => {
    const editedProfile = getEditedProfile(getState())
    const fetchProfiles$ = Rx.Observable.defer(getProfiles)?.map((profiles) =>
      buildAction(ActionTypes.PROFILES_FETCH_SUCCESS, profiles),
    )

    return Rx.Observable.fromPromise(
      deleteProfile({ id: editedProfile.id, locale: getOpointLocale(getState()), force: true }),
    )
      .switchMap(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.PROFILE_DELETE_SUCCESS, editedProfile.id)),
          fetchProfiles$,
        ),
      )
      .concat(pushLocation('/'))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => {
        let message
        try {
          message = JSON.parse(e.originalEvent.target.response)
        } catch (innerError) {
          message = {
            message: 'We were unable to delete this contact',
          }
        }

        return Rx.Observable.of(buildAction(ActionTypes.PROFILE_DELETE_FAILURE, message))
      })
  })

const profileEditorPreviewEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILE_EDITOR_PREVIEW).switchMap(() => {
    const editedProfile = getEditedProfile(getState())
    const baskets = { baskets: getBaskets(getState()) }

    return Rx.Observable.fromPromise(search(editedProfile.items, baskets))
      ?.map((response) => buildAction(ActionTypes.PROFILE_EDITOR_PREVIEW_SUCCESS, response))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PROFILE_EDITOR_PREVIEW_FAILURE)))
  })

const profileEditorLoadProfileEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.LOAD_EDITED_PROFILE).switchMap(({ payload: { profileId } }) => {
    if (profileId === null) {
      return Rx.Observable.of(
        buildAction(ActionTypes.LOAD_EDITED_PROFILE_SUCCESS, {
          id: null,
          items: [
            {
              searchline: {
                searchterm: '',
                filters: [],
              },
              linemode: 'R',
            },
          ],
        }),
      )
    }

    const locale = getOpointLocale(getState())
    // #TODO check if works correctly

    return Rx.Observable.fromPromise(getProfileDetail(profileId, locale))
      .switchMap((response) =>
        Rx.Observable.of(
          buildAction(ActionTypes.LOAD_EDITED_PROFILE_SUCCESS, response),
          buildAction(ActionTypes.SCROLL_TO_TOP),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOAD_EDITED_PROFILE_FAILURE)))
  })

const reloadArticles = (action$: any) =>
  action$
    .ofType(ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS, ActionTypes.SEARCHDATA_CLEAR)
    .switchMap(() =>
      Rx.Observable.concat(
        Rx.Observable.of(buildAction(ActionTypes.CLEAR_ARTICLES)),
        Rx.Observable.of(buildAction(ActionTypes.FETCH_ARTICLES)),
      ),
    )

export const reorderProfilesEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILE_REORDER).switchMap(({ payload }) => {
    const { parent, position, profileId } = payload

    const fetchProfiles$ = Rx.Observable.defer(getProfiles)
      ?.map((profiles) => buildAction(ActionTypes.PROFILES_FETCH_SUCCESS, profiles))
      .catch(() => Rx.Observable.of())

    // @ts-ignore
    return Rx.Observable.from(updateProfilesOrder(position, profileId, parent))
      .switchMap(() =>
        Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.PROFILES_REORDER_SUCCESS)), fetchProfiles$),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, e)))
  })

const saveAsProfileEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SAVE_AS_PROFILE).switchMap(() => {
    const state = getState()
    // @ts-ignore
    const { name } = getSaveAsProfile(state) || {}
    const searchline = getMainSearchLine(state)
    const editedProfile = getEditedProfile(state)
    const profile = editedProfile
      ? R.evolve({
          id: R.always(null),
          name: R.always(name),
        })(editedProfile)
      : {
          id: null,
          items: [
            {
              linemode: 'R',
              searchline,
            },
          ],
          name,
        }

    const fetchProfiles$ = Rx.Observable.defer(getProfiles)?.map((profiles) =>
      buildAction(ActionTypes.PROFILES_FETCH_SUCCESS, profiles),
    )

    // @ts-ignore
    return Rx.Observable.fromPromise(saveProfile(profile))
      .switchMap((response) => {
        /* @ts-ignore*/
        if (response.debug) {
          /* @ts-ignore*/
          return Rx.Observable.of(buildAction(ActionTypes.PROFILE_EDITOR_INVALID_SEARCHLINE, { debug: response.debug }))
        } else {
          return Rx.Observable.concat(
            fetchProfiles$,
            Rx.Observable.of(buildAction(ActionTypes.SAVE_AS_PROFILE_SUCCESS)),
            pushLocation(`/search/?filters=profile:${response.id}`),
          )
        }
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.SAVE_AS_PROFILE_FAILURE)))
  })

const profileHistoryFetchEpic = (action$: any) =>
  action$.ofType(ActionTypes.PROFILE_HISTORY_FETCH).switchMap(({ payload: { profileId: id } }) =>
    Rx.Observable.fromPromise(getProfileHistory(id))
      ?.map((history) => buildAction(ActionTypes.PROFILE_HISTORY_FETCH_SUCCESS, { history }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((error) => Rx.Observable.of(buildAction(ActionTypes.PROFILE_HISTORY_FETCH_FAILURE, error))),
  )

const deletedProfilesHistoryFetchEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETED_PROFILES_HISTORY_FETCH).switchMap(() =>
    Rx.Observable.fromPromise(getDeletedProfiles())
      ?.map((history) => buildAction(ActionTypes.DELETED_PROFILES_HISTORY_FETCH_SUCCESS, { history }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.DELETED_PROFILES_HISTORY_FETCH_FAILURE))),
  )

const getProfileDependenciesEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILES_GET_DEPENDENCIES).switchMap(({ payload: { profiles } }) =>
    Rx.Observable.forkJoin(
      profiles?.map(async ({ id }) => ({
        id,
        deps: await getProfileDependencies(id),
      })),
    ).switchMap((deps) => Rx.Observable.of(buildAction(ActionTypes.PROFILES_GET_DEPENDENCIES_SUCCESS, { deps }))),
  )

export default [
  deletedProfilesHistoryFetchEpic,
  deleteProfilesEpic,
  fetchProfilesOnLogIn,
  filterMetasFetchDetailEpic,
  filterMetasOpenDetailModal,
  getProfileDependenciesEpic,
  profileEditorDeleteEpic,
  profileEditorInitialFiltersFetchEpic,
  profileEditorLoadProfileEpic,
  profileEditorPreviewEpic,
  profileEditorSaveEpic,
  profileEditorSearchChangedEpic,
  profileHistoryFetchEpic,
  profilesFetchEpic,
  reloadArticles,
  reorderProfilesEpic,
  saveAsProfileEpic,
  onProflieEditorFiltersFetchMore,
  onProflieEditorSearchDataChangeFiltersMore,
]
