import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { getImpersonationOrganizations, getImpersonationUsers } from '../opoint/impersonation'

import { logOutOnExpiredToken, serverIsDown } from './configureEpics'

const impersonationOrganizationsFetchEpic: (action$) => void = (action$) =>
  action$
    .ofType(ActionTypes.IMPERSONATION_ORGANIZATIONS_FETCH)
    .switchMap(() =>
      Rx.Observable.fromPromise(getImpersonationOrganizations())?.map((organizations) =>
        buildAction(ActionTypes.IMPERSONATION_ORGANIZATIONS_FETCH_SUCCESS, organizations),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.IMPERSONATION_ORGANIZATIONS_FETCH_FAILURE)))

const impersonationUsersFetchEpic: (action$) => void = (action$) =>
  action$
    .ofType(ActionTypes.IMPERSONATION_USERS_FETCH)
    .debounceTime(250)
    .switchMap(({ payload: { userSearch } }) =>
      Rx.Observable.fromPromise(getImpersonationUsers(userSearch))?.map((users) =>
        buildAction(ActionTypes.IMPERSONATION_USERS_FETCH_SUCCESS, users),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.IMPERSONATION_USERS_FETCH_FAILURE)))

// This fetches all impersonation users if the user only has 20 or below.
const impersonationUsersListFetchEpic: (action$) => void = (action$) =>
  action$
    .ofType(ActionTypes.GO_TO_CUSTOMER_VIEW, ActionTypes.SETTINGS_FETCH_SUCCESS)
    .switchMap(() =>
      Rx.Observable.fromPromise(getImpersonationUsers(''))?.map((users) =>
        buildAction(ActionTypes.FETCH_USERS_SUCCESS, users),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.IMPERSONATION_USERS_FETCH_FAILURE)))

export default [impersonationOrganizationsFetchEpic, impersonationUsersFetchEpic, impersonationUsersListFetchEpic]
