import R from 'ramda'
import Rx from 'rx-dom'

import { getAllIdenticalArticles } from '../articles/index'
import config from '../common/config'
import { OpointTimestampToTimestamp } from '../common/time'
import type { Trash, Article, Tag } from '../flow'

import { TAG_TYPES } from './index'

/**
 * Functions
 */
// Fetch trash tags from server
export async function getTrashTags(): Promise<Array<Trash>> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/trash/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// Delete article via trash tag
export async function deleteArticles(articles: Array<Article>, { id, type }: Tag): Promise<any> {
  const stimestamp = OpointTimestampToTimestamp()
  const allArticles = R.flatten(R.map(getAllIdenticalArticles, articles))
  // @ts-ignore
  const articlesIds = R.chain(({ id_site, id_article }) => ({ id_site, id_article, stimestamp }), allArticles)
  const profile = type === TAG_TYPES.GLOBAL_TRASH ? null : { profile: id }

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/articles/delete/'),
    method: 'post',
    body: JSON.stringify({
      articles: articlesIds,
      ...profile,
    }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
    .catch(({ status }) => {
      // @ts-ignore
      return Promise.reject(new Error({ articles, status }))
    })
}

// Undelete article via trash tags
export async function undeleteArticles(articles: Array<Article>, { id, type }: Tag): Promise<any> {
  const stimestamp = OpointTimestampToTimestamp()
  // @ts-ignore
  const articlesIds = R.chain(({ id_site, id_article }) => ({ id_site, id_article, stimestamp }), articles)
  const profile = type === TAG_TYPES.GLOBAL_TRASH ? null : { profile: id }

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/articles/undelete/'),
    method: 'post',
    body: JSON.stringify({
      articles: articlesIds,
      ...profile,
    }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
    .catch(({ status }) => {
      // @ts-ignore
      return Promise.reject(new Error({ articles, status }))
    })
}

// Returns array of visible trash tags in entity listing based on active profiles
// or on selected trash tag (in search line).
// Array is sorted in a way that global trash tags are always first
export function getVisibleTrashTags(
  trashTags: Array<Trash>,
  profileIds: Array<number>,
  trashTagFilterIds: Array<number>,
): Array<Trash> {
  return trashTags
    .sort((a, b) => {
      // @ts-ignore
      return a.globalTrash - b.globalTrash
    })
    .reverse()
    .filter((tag) => profileIds.includes(tag?.profileId) || tag.globalTrash || trashTagFilterIds.includes(tag.id))
}

// Return trash tags displayed in article footer
export function getTrashTagsVisibleOnArticle(
  trashTags: Array<Trash>,
  trashInListing: Array<Trash>,
  visibleTrashTags: Array<number> = [],
  trashTagFilterIds: Array<number> = [],
  articleTrashTags: Array<number> = [],
): Array<Trash> {
  return R.uniq([
    ...trashInListing.filter((tag) => visibleTrashTags.includes(tag.id) || trashTagFilterIds.includes(tag.id)),
    ...trashTags.filter((tag) => articleTrashTags.includes(tag.id)),
  ])
}

// Return ids with correct symbol as a string for search
// in order to get all relevant tag data and articles
export function generateBaskets(tags: Array<Tag>, trashTags: Array<Trash>, alertTags: Array<Tag>): string {
  return R.uniq([...tags, ...trashTags, ...alertTags].filter(({ id }) => id)?.map(({ id }) => +id)).join(',')
}

// change trash tag ids to positive values, rename General trash tag
export function processTrashTags(tags: Array<Trash>): Array<Trash> {
  // @ts-ignore
  return tags?.map((tag) =>
    R.compose(
      R.over(R.lensPath(['id']), (t) => Math.abs(t)),
      R.when(R.propEq('globalTrash', true), R.assoc('name', 'General trash')),
    )(tag),
  )
}
