import { handleErrors } from '../common'
import config from '../common/config'

export async function updateWatchIndexes(watchIds: Array<number>): Promise<any> {
  return fetch(config.url.api(`/watchqueries/${watchIds.join(',')}/`), {
    ...(await config.request.getRequestHeaders()),
  })
    .then(handleErrors)
    .then((response) => response.json())
}
