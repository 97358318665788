import { OpointAuthContext, useOpointAuth, useOpointAuthState } from '@opoint/authjs-react'
import React, { useState } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'
import { Provider, useDispatch } from 'react-redux'

import * as ActionTypes from './constants/actionTypes'
import buildAction from './helpers/buildAction'
import { listen } from './helpers/location'
import ErrorBoundary from './new-components/common/ErrorBoundary'
// @ts-ignore
import { ReactComponent as SvgIcons } from './new-components/common/Icon/sprites/symbol-defs.svg'
import Toasts from './new-components/common/Toasts'
import config from './opoint/common/config'
// @ts-ignore
import Routes from './routes'
import store from './store'
import './new-components/old-styles.css'
import './new-components/new-styles.scss'
import './ga'
import './sentry'
import './transifex'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/nn'
import 'dayjs/locale/nb'
import 'dayjs/locale/da'
import 'dayjs/locale/sv'
import 'dayjs/locale/et'
import 'dayjs/locale/fi'

Modal.setAppElement('#root')

listen(store)

const oReq = new XMLHttpRequest()
oReq.addEventListener('load', async () => {
  let response
  try {
    // @ts-ignore
    response = JSON.parse(this.responseText)
  } catch (e) {
    response = { isoCode: 'en-GB' }
  }
  store.dispatch(buildAction(ActionTypes.COUNTRY_CODE_FETCH_SUCCESS, response))

  bootstrapApp()
})
oReq.addEventListener('error', bootstrapApp)
oReq.addEventListener('timeout', bootstrapApp)
oReq.open('GET', config.url.api('/users/location/?format=json'))
oReq.timeout = 400
oReq.send()

function AuthWrapper({ children }: { children: React.ReactNode }) {
  const authState = useOpointAuthState()
  const [dispatched, setDispatched] = useState(false)
  const dispatch = useDispatch()

  if (authState && !dispatched) {
    dispatch({
      type: ActionTypes.SIGN_IN,
      payload: { token: authState.token.payload, user: authState.user },
    })
    setDispatched(true)
  }

  return <>{children}</>
}

// This calls a hook, so it needs to be a regular function component
function AppRoot() {
  const auth = useOpointAuth(config.url.auth(), config.url.callback(), false, true)
  config.auth = auth

  return (
    <Provider store={store}>
      <OpointAuthContext.Provider value={auth}>
        <AuthWrapper>
          <React.StrictMode>
            <ErrorBoundary reloadButton={true} errorImg={true}>
              <SvgIcons />
              <Routes />
            </ErrorBoundary>
          </React.StrictMode>
        </AuthWrapper>
      </OpointAuthContext.Provider>
    </Provider>
  )
}

function bootstrapApp() {
  // Check IE - should be removed
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') !== -1 // IE 10 or older
  const trident = ua.indexOf('Trident/') !== -1 //IE 11

  if (msie || trident) {
    const now = new Date().getTime()

    if (parseInt(window.localStorage.IE_LAST_CHECK ?? 0) + 86400 < now) {
      store.dispatch(buildAction(ActionTypes.SHOW_IE_WARNING))
      window.localStorage.IE_LAST_CHECK = now
    }
  }

  render(<AppRoot />, document.getElementById('root'))

  store.dispatch(buildAction(ActionTypes.BOOTSTRAP))

  render(<Toasts />, document.getElementById('alert'))
}
