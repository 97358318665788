import { t } from '@transifex/native'

import * as ActionTypes from '../../../constants/actionTypes'

import { ErrorEpic, handlerError } from '.'

const reportErrorTypes: ErrorEpic = {
  [ActionTypes.REPORTS_SHARE_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully shared this report'),
    toastId: 'REPORTS_SHARE_SUCCESS',
  },
  [ActionTypes.DELETE_REPORT_NOTIFICATION_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully deleted selected report notification'),
    toastId: 'DELETE_REPORT_NOTIFICATION_SUCCESS',
  },
  [ActionTypes.DELETE_REPORT_SUCCESS]: {
    isSuccess: true,
    message: t('We successfully deleted selected report'),
    toastId: 'DELETE_REPORT_SUCCESS',
  },
  [ActionTypes.REPORTS_SHARE_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to share this report'),
    sentryMessage: 'We were not able to share this report',
    toastId: 'REPORTS_SHARE_FAILURE',
  },
  [ActionTypes.DELETE_REPORT_NOTIFICATION_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to delete selected report notification'),
    sentryMessage: 'We were not able to delete selected report notification',
    toastId: 'DELETE_REPORT_NOTIFICATION_FAILURE',
  },
  [ActionTypes.DELETE_REPORT_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to delete selected report'),
    sentryMessage: 'We were not able to delete selected report',
    toastId: 'DELETE_REPORT_FAILURE',
  },
  [ActionTypes.REPORTS_ARTICLES_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to fetch articles for report'),
    sentryMessage: 'We were not able to fetch articles for report',
    toastId: 'REPORTS_ARTICLES_FAILURE',
  },
  [ActionTypes.REPORTS_HISTORY_FETCH_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to fetch report history'),
    sentryMessage: 'We were not able to fetch report history',
    toastId: 'REPORTS_HISTORY_FETCH_FAILURE',
  },
  [ActionTypes.REPORTHISTORY_FETCH_FAILURE]: {
    isSuccess: false,
    message: t('We were not able to fetch report history'),
    sentryMessage: 'We were not able to fetch report history',
    toastId: 'REPORTHISTORY_FETCH_FAILURE',
  },
  [ActionTypes.REPORTS_VALID_SHARE_FAILURE]: {
    isSuccess: false,
    message: t('You have to enter at least one valid recipient'),
    sentryMessage: 'You have to enter at least one valid recipient',
    toastId: 'REPORTS_VALID_SHARE_FAILURE',
  },
}

const reportErrorEpic: (action) => void = (action$) =>
  action$.switchMap(({ type }) => handlerError(reportErrorTypes, type))

export default reportErrorEpic
