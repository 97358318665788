import dayjs from 'dayjs'

import type { Article, TagsInResponse, AspectsInArticle } from '../new-components/types/article'
import type { Tag } from '../new-components/types/tag'
import { articleId } from '../opoint/articles'
import { StatisticAspect } from '../opoint/flow'

const getExportArticleHeader = (aspects: StatisticAspect[]): string[] => [
  'Date',
  'Sitename',
  'Header',
  'Source',
  'Source depth',
  'Language',
  'Country code',
  'Caption',
  'Picture url',
  'Similarity',
  'Summary',
  'Article text',
  'Article url',
  'Redir url',
  'Id of site',
  'Id of source',
  'Id of article',
  'Media type',
  'Circulation',
  'Reach',
  'Ad price',
  'Word count',
  'Author',
  'Matches',
  'Tags',
  'SOME timestamps',
  'SOME engagement',
  'Twitter Favorites',
  'SOME shares',
  'Facebook comment count',
  'Facebook reaction count',
  ...aspects.filter(({ selected }) => selected)?.map(({ name }) => name),
]

export const getExportArticleData = (
  articles: (Article & { tags: TagsInResponse })[],
  tags: Tag[],
  aspects: StatisticAspect[],
  articlesWithAspectData: Array<Article & AspectsInArticle>,
): Array<Array<string | number>> => {
  const header = getExportArticleHeader(aspects)
  const selectedAspects = aspects.filter(({ selected }) => selected)
  const emptyValue = '-'
  const articleData = articles.reduce((acc, item) => {
    const articleWithAspects = articlesWithAspectData.find((article) => articleId(article) === articleId(item))
    if (!articleWithAspects) {
      return acc
    }
    const aspectColumns = selectedAspects?.map(({ id: aspectId, aspectpart }) => {
      if (aspectId in articleWithAspects && articleWithAspects[aspectId].length > 0) {
        const [aspectpartId] = articleWithAspects[aspectId]
        if (aspectpartId in aspectpart) {
          return aspectpart[aspectpartId].names.join(', ')
        }

        return emptyValue
      }

      return emptyValue
    })

    return [
      ...acc,
      [
        (item.unix_timestamp && dayjs.unix(item.unix_timestamp).format('DD.MM.YY HH:mm')) || emptyValue,
        item.first_source?.sitename || emptyValue,
        item.header?.text || emptyValue,
        item.first_source?.name || emptyValue,
        item.position || emptyValue,
        item.language?.text || emptyValue,
        item.countrycode || emptyValue,
        item.caption?.text || emptyValue,
        (item.articleimages && item.articleimages.count > 0 && item.articleimages.articleimage[0].url) || emptyValue,
        item.equalgroup || emptyValue,
        item.summary?.text || emptyValue,
        item.body?.text || emptyValue,
        item.orig_url || emptyValue,
        item.url || emptyValue,
        item.id_site || emptyValue,
        item.first_source?.id || emptyValue,
        item.id_article || emptyValue,
        item.mediatype?.text || emptyValue,
        item.circulation || emptyValue,
        item.reach || emptyValue,
        item.adprice || emptyValue,
        item.word_count || emptyValue,
        item.author || emptyValue,
        item.matches?.map(({ text }) => text).join(', ') || emptyValue,
        item.tags
          ?.reduce((prev, { id: tagId }) => {
            const tag = tags.find(({ id }) => id === tagId)
            if (tag) {
              return [...prev, tag.name]
            }

            return prev
          }, [])
          .join(', ') || emptyValue,
        (item.meta_data && 'sm_fb_stimestamp' in item.meta_data && item.meta_data.sm_fb_stimestamp?.value) ||
          ('sm_tw_stimestamp' in item.meta_data && item.meta_data.sm_tw_stimestamp?.value) ||
          ('sm_ig_stimestamp' in item.meta_data && item.meta_data.sm_ig_stimestamp?.value) ||
          emptyValue,
        (item.meta_data && 'sm_fb_share_count' in item.meta_data && item.meta_data.sm_fb_share_count?.value) ||
          ('sm_tw_engagement_count' in item.meta_data && item.meta_data.sm_tw_engagement_count?.value) ||
          ('sm_ig_engagement_count' in item.meta_data && item.meta_data.sm_ig_engagement_count?.value) ||
          emptyValue,
        (item.meta_data && 'sm_tw_favorite_count' in item.meta_data && item.meta_data.sm_tw_favorite_count?.value) ||
          emptyValue,
        (item.meta_data &&
          'sm_fb_engagement_count' in item.meta_data &&
          item.meta_data.sm_fb_engagement_count?.value) ||
          ('sm_tw_share_count' in item.meta_data && item.meta_data.sm_tw_share_count?.value) ||
          emptyValue,
        (item.meta_data && 'sm_fb_comment_count' in item.meta_data && item.meta_data.sm_fb_comment_count?.value) ||
          emptyValue,
        (item.meta_data && 'sm_fb_reaction_count' in item.meta_data && item.meta_data.sm_fb_reaction_count?.value) ||
          emptyValue,
        ...aspectColumns,
      ]?.map((item) => `${item}`),
    ]
  }, [])

  return [header, ...articleData]
}
