import classNames from 'classnames'
import React from 'react'

import { IconName, InfomediaColor } from '../../types/theme'

import styles from './Icon.module.scss'

export interface Props {
  id?: string
  isDecorative?: boolean
  name: IconName
  title?: string
  verticalCenter?: boolean
  color?: InfomediaColor
}

const Icon = ({ color, id, isDecorative, name, title, verticalCenter = false }: Props): JSX.Element => (
  <span
    aria-hidden={isDecorative}
    className={classNames(styles.wrapper, { [styles.verticalCenter]: verticalCenter })}
    id={id}
    title={title}
  >
    <svg className={classNames(styles.icon, { [styles[color]]: !!color })} pointerEvents="none">
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  </span>
)

export default Icon
