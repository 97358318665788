import { t } from '@transifex/native'
import R from 'ramda'
import { reset } from 'redux-form'
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { scrollElementToTop } from '../helpers/common'
import { createFeed, deleteFeed, getFeeds, updateFeed } from '../opoint/feeds'
import { getEditedFeed, getFeedById, getFeedFormData } from '../selectors/feedsSelector'
import { getSelectedProfilesAndTagsIds, getAbandonedSearchLine } from '../selectors/searchSelectors'

import { logOutOnExpiredToken, serverIsDown, pushLocation } from './epicsHelper'

const fetchFeedsOnLogIn = (action$: any) =>
  action$
    .ofType(ActionTypes.LOG_IN_SUCCESS, ActionTypes.IMPERSONATE_SUCCESS)
    ?.mapTo(buildAction(ActionTypes.FEEDS_FETCH))

const cleanFormOnCancelChanges = (action$: any) =>
  action$.ofType(ActionTypes.FEED_EDITOR_CANCEL_CHANGES)?.mapTo(reset('feedEditor'))

export const fetchFeeds = (action$: any) =>
  action$.ofType(ActionTypes.FEEDS_FETCH).switchMap(() =>
    Rx.Observable.from(getFeeds())
      ?.map((feeds) => buildAction(ActionTypes.FEEDS_FETCH_SUCCESS, feeds))
      .catch((err) =>
        err.status === 401
          ? Rx.Observable.of(buildAction(ActionTypes.LOGOUT))
          : Rx.Observable.of(buildAction(ActionTypes.FEEDS_FETCH_FAILURE)),
      ),
  )

export const saveFeedEpic = (action$: any, { getState }) =>
  action$.ofType(ActionTypes.FEED_SAVE).switchMap(() => {
    const state = getState()

    const feed = getEditedFeed(state)
    const updates = getFeedFormData(state)

    const data = R.mergeAll([feed, updates, { xsltFile: null }])

    if (data.name.length === 0) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_FEED_VALID_FAILURE, { error: t('You have to enter feed name') }),
      )
    }
    if (data.mifluzIdLists.length === 0) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_FEED_VALID_FAILURE, {
          error: t('You have to enter at least one profile or tag'),
        }),
      )
    }

    return Rx.Observable.from(updateFeed(feed.id, data))

      ?.map((feed) => buildAction(ActionTypes.FEED_SAVE_SUCCESS, feed))
      .catch(() => buildAction(ActionTypes.FEED_SAVE_ERROR))
  })

export const saveNewFeedEpic = (action$: any, { getState }) =>
  action$.ofType(ActionTypes.FEED_NEW).switchMap(() => {
    const state = getState()

    const feed = getEditedFeed(state)
    const updates = getFeedFormData(state)

    const data = R.mergeAll([feed, updates, { xsltFile: null }])

    if (data.name.length === 0) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_FEED_VALID_FAILURE, { error: t('You have to enter feed name') }),
      )
    }
    if (data.mifluzIdLists.length === 0) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_FEED_VALID_FAILURE, {
          error: t('You have to enter at least one profile or tag'),
        }),
      )
    }

    return Rx.Observable.from(createFeed(data))

      .switchMap((feed) =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.FEED_NEW_SUCCESS, feed)),
          // @ts-ignore
          pushLocation(`/feeds/${feed.id}`),
        ),
      )
      .catch(() => buildAction(ActionTypes.FEED_NEW_ERROR))
  })

export const deleteFeedEpic = (action$: any, { getState }) =>
  action$.ofType(ActionTypes.FEED_DELETE_CONFIRM).switchMap(() => {
    const state = getState()
    const { id } = getEditedFeed(state)

    return Rx.Observable.from(deleteFeed(id))
      .switchMap(() =>
        Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.FEED_DELETE_SUCCESS, id)), pushLocation(`/`)),
      )
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.FEED_DELETE_ERROR)))
  })

export const scrollTopOnFeedCreated = (action$: any) =>
  action$.ofType(ActionTypes.FEED_NEW_SUCCESS).switchMap(() => {
    scrollElementToTop('.mod-full-height')

    return Rx.Observable.empty()
  })

// epic waits for all necessary data to be fetched from server and then assign active feed
const feedsEpic = (action$, { getState }) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FEED_SET_ACTIVE),
    action$.ofType(ActionTypes.FEEDS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.CONTACTS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.GROUPS_FETCH_SUCCESS).take(1),
  ).switchMap(
    ([
      {
        // @ts-ignore
        payload: { id },
      },
      feedsFetchSuccessAction,
      tagsFetchSuccessAction,
      profilesFetchSuccessAction,
      contactsFetchSuccessAction,
      // @ts-ignore
      groupsFetchSuccessAction,
    ]) => {
      const state = getState()
      const feed = getFeedById(id)(state)
      const searchLine = getAbandonedSearchLine(state) || []

      return Rx.Observable.of(buildAction(ActionTypes.FEED_SET_ACTIVE_SUCCESS, { feed, searchLine }))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FEED_SET_ACTIVE_FAILURE)))
    },
  )

const goToNewFeedActionLine = (action$, { getState }) =>
  action$.ofType(ActionTypes.GO_TO_NEW_FEED_ACTIONLINE).switchMap(() => {
    const state = getState()
    const searchLine = getSelectedProfilesAndTagsIds(state)

    return Rx.Observable.of(
      buildAction(ActionTypes.STORE_CURRENT_SEARCHLINE, { searchLine }),
      buildAction(ActionTypes.GO_TO_NEW_FEED),
    )
  })

export default [
  deleteFeedEpic,
  feedsEpic,
  fetchFeeds,
  fetchFeedsOnLogIn,
  goToNewFeedActionLine,
  saveFeedEpic,
  saveNewFeedEpic,
  scrollTopOnFeedCreated,
  cleanFormOnCancelChanges,
]
