import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { getReportsHistory } from '../opoint/reportsHistory/index'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchReportsHistoryEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTHISTORY_FETCH).switchMap(({ payload }) => {
    const { page } = payload

    return Rx.Observable.fromPromise(getReportsHistory(page))
      ?.map((data: string) => {
        const parsedData = JSON.parse(data)

        return buildAction(ActionTypes.REPORTHISTORY_FETCH_SUCCESS, {
          reports: parsedData.results,
          totalCount: parsedData.count,
        })
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.REPORTHISTORY_FETCH_FAILURE)))
  })

export default [fetchReportsHistoryEpic]
